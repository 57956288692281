.education-stepper-wrapper
{
    position: absolute;
    z-index: 1;
    margin-top: 120px;
    margin-left: 100px;
    /* display: inline-block;
    width: 100px;
    height: 100px; */
}

.vertical-stepper-wrapper
{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.vertical-stepper-box-wrapper
{
    vertical-align: top;
    /* overflow: hidden; */
    height: 52px;
    /* text-overflow: inherit; */
    transition: 1s all;
    white-space: nowrap;
  overflow: hidden;
  background: white;
}

.vertical-stepper-box
{
    width: 20px;
    height: 50px;
    border: 1px solid rgb(194, 194, 194);
    border-right: 0px;
    display: inline-block;
}

.vertical-stepper-menu
{
    height: 50px;
    border: 1px solid rgb(194, 194, 194);
    border-left: 0px;

    width: 100%;
    display: inline-block;
    /* background: tomato; */
    vertical-align: top;
    line-height: 50px;
}

.vertical-stepper-menu-wrapper
{
    transition: all 1s;
}

.vertical-stepper-toggler-wrapper
{
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 52px;
    background: rgb(194, 194, 194);
    cursor: pointer;
}

.vertical-stepper-toggler-icon
{
    width: 50px;
    transition: all 1s;
}