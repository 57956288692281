body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  margin: 0;
  box-sizing: unset;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}




/*                GENERAL CLASSES                       */


.small-paragraph
{
    font-size: 20px;
    margin-bottom: 0px;
    line-height: 28px;
    margin-left: 0px;
    color: #fff;
    font-family: 'Lato', sans-serif;
}

.title-small-2
{
  font-size: 16px;
  margin-bottom: 5px;
  line-height: 24px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

html{
  overflow-y: auto !important;
}

.MuiButtonBase-root.MuiButton-root.MuiButton-contained
{
  white-space: initial !important;
}

#tutorial_main_wrapper>div>div {
  padding-left: 150px;
}
#Evaluation_Main_Div
{
    /* background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    height:100vh;
    width: 100vw; */
    box-sizing: unset !important;
    line-height: 1;
}

#Evaluation_Main_Div_Top_Name
{
    position: absolute;
    top:0px;
    right:20px;
    width:118px;
    min-height:14px;
    color:#333333;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    padding: 12px;
    background: #fff;
    border: 1px solid #000;
    z-index: 4;
    text-align: center;
}

@media only screen and (max-width:1630px)  and (max-height: 950px)
{
    #Evaluation_Main_Div_Top_Name
    {
        right:0px;
    }
}
#Evaluaion_Welcome_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  
  position: relative;
  overflow: hidden;
    
    
}

#Evaluaion_Welcome_Image_div
{
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height:100vh;
  width:300px;
  max-width:45%;

}

#Evaluaion_Welcome_Image_Bone
{
  /* max-height:calc(100vh - 40px);
  min-height: 100px;
  width:auto;
  height:auto; */
  width: 300px;
  margin-top: 50px;
}



#Evaluaion_Welcome_Text_Wrapper
{
  float:left;
  margin-left:15%;
  width:70%;
  min-width: 400px;
  max-width: 700px;
  height:100vh;
  line-height: 100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Welcome_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Evaluaion_Welcome_Neon_Line
{
  width: 100px;
  height: 3px;
  margin-top: 0px;
  margin-bottom: 50px;
  background-color:#b4ec51;
  box-sizing: border-box;
 
}

#Evaluaion_Welcome_Heading2_Div
{
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_Welcome_Text_Div
{
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
  
  
}
#Evaluaion_Welcome_Next_Button_Div
{
  display: block;
  float: right;
  margin-top: 40px;
}

@media only screen and (min-height:700px)
{
  #Evaluaion_Welcome_Next_Button_Div
  {
    margin-top:60px;
  }
}

@media only screen and (min-height:750px)
{
  #Evaluaion_Welcome_Next_Button_Div
  {
    margin-top: 80px;
  }
}


@media only screen and (min-height:800px)
{
  #Evaluaion_Welcome_Next_Button_Div
  {
    margin-top: 100px;
  }
}

#Evaluaion_Welcome_Next_Button
{
  display: block;
  width:auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none !important;
}

.Welcome_listing
{
  width: 60%;
  color: white;
  font-size: 20px;
  font-family: Lato,sans-serif;
  padding-bottom:0px;
  margin-top: 0px;
  margin-bottom: 0px;
}

.Welcome_listing li
{
  padding-bottom:0px
}



#Evaluaion_PatientDemoGraphics_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
    box-sizing: unset;

}

#Evaluaion_PatientDemoGraphics_Text_Wrapper
{
  
  width:75%;
  min-width: 400px;
  max-width: 900px;
  min-height:100vh;

  float:left;
  margin-left:15%;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}


#Evaluaion_PatientDemoGraphics_Heading2_Div
{
  margin-bottom: 60px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #fff;
  box-sizing: border-box;
  

  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_PatientDemoGraphics_Form_Div
{
  font-size: 22px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;

  
  /* vertical-align: middle; 
  display: table-row;  */
}

#Evaluaion_PatientDemoGraphics_Form_Div Input
{
  color:white;
}

#Evaluaion_PatientDemoGraphics_Form_Div .MuiSelect-root
{
  color:white;
}

#Evaluaion_PatientDemoGraphics_Form_Div .MuiSelect-icon
{
  color:white;
}



#Evaluaion_PatientDemoGraphics_Next_Button_Div
{
  display: block;
  margin-top: 10%;
}

#Evaluaion_PatientDemoGraphics_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

.Evaluaion_PatientDemoGraphics_Form_Span1
{
    display:inline-block;
    width:calc((100% / 3) * 2);
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .Evaluaion_PatientDemoGraphics_Form_Span1 {
    width: 100%;
  }
}



.Evaluaion_PatientDemoGraphics_Form_Span2
{
    display:inline-block;
    width:calc(100% / 3);
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .Evaluaion_PatientDemoGraphics_Form_Span2 {
    width: 100%;
  }
}

.Evaluaion_PatientDemoGraphics_Form_Span3
{
    display:inline-block;
    width:100%;
    margin-bottom: 10px;
    margin-top: 10px;
}



#Evaluaion_PatientDemoGraphics_name
{
    margin-bottom: 5px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-color:#fff !important;
    
   
}

.MuiInput-underline::before
{
    
    border-width: 1px 1px 2px !important;
    border-color: #000 #000 #fff !important;
}

.MuiInput-underline::after
{
    
    border-width: 1px 1px 2px !important;
    border-color: #000 #000 #3898EC !important;
}



.Evaluaion_PatientDemoGraphics_Form_Div_Text
{
    padding-left: 5px;
    width: 100% !important;
}

.MuiInputBase-input
{
    padding-top: 0px !important;
    padding-bottom: 3px !important;
}




#Evaluaion_NewEvaluation_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
}

.Evaluaion_NewEvaluation_Disabled_TextBox
{
  width:180px;
  opacity: 0.5;
}

#Evaluaion_NewEvaluation_Content_Wrapper
{
  float:left;
  margin-left:15%;
  width:75%;
  min-width: 400px;
  max-width: 100vw;
  min-height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  position: relative;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_NewEvaluation_Text_Div
{
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;

    margin-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    width:910px;
    margin-left: auto;
    margin-right: auto;
  
  /* vertical-align: middle; 
  display: table-row;  */
}

#Evaluaion_NewEvaluation_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  width:910px;
  margin-left: auto;
  margin-right: auto;
}

#Evaluaion_NewEvaluation_Heading2_Div
{
    font-size: 32px;
   
    font-family: 'Lato', sans-serif;
    color: #fff;
    box-sizing: border-box;

    margin-top: 0px;
    margin-bottom: 5px;
    line-height: 48px;
    font-weight: 700;
    width:910px;
    margin-left: auto;
    margin-right: auto;
  

  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_NewEvaluation_Image_div
{
  display: block;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  /* height:100vh; */
  width:100%;
  max-width:100%;
  margin-top: 10px;
  text-align: center;
}

.Evaluaion_NewEvaluation_MenuItem
{
  color:white !important;
}


  #Evaluaion_NewEvaluation_Image_Bone
  {
    /* max-height:calc(100vh - 40px);
    min-height: 100px;
    width:auto;
    height:auto; */
    width: 250px;
    
  }

  #Evaluaion_NewEvaluation_Image_Wrapper
  {
   display: inline-block;
  }

  .Evaluaion_NewEvaluation_Image_Left_Div
  {
    display: inline-block;
    vertical-align: top;
    margin-top:28px;
    margin-right:40px;
  }

  .Evaluaion_NewEvaluation_Image_Right_Div
  {
    display: inline-block;
    vertical-align: top;
    margin-top:28px;
    margin-left:40px;
  }

  .Evaluaion_NewEvaluation_Image_Left_Inner_Up
  {
    display:block;
    
  }

  .Evaluaion_NewEvaluation_Image_Left_Inner_Down
  {
    display:block;
    margin-top:53px;
  }

  .Evaluaion_NewEvaluation_Image_Right_Inner_Up
  {
    display:block;
  }

  .Evaluaion_NewEvaluation_Image_Right_Inner_Down
  {
    display:block;
    margin-top:53px;
  }


  .Evaluaion_NewEvaluation_Image_Left_Inner_Up_Content1
  {
    transform: translateY(-20px);
    display: inline-block;
    vertical-align: top;
    margin-right: 34px;

  }

  .Evaluaion_NewEvaluation_Image_Left_Inner_Down_Content1
  {
    display: inline-block;
    vertical-align: top;
    margin-right: 20px;
    transform: translateY(-20px);
  }

  /* .input-class-height
  {
    height:60px;
  } */

  .Evaluaion_NewEvaluation_Image_Left_Inner_Up_Content2
  {
    display:inline-block;
    vertical-align: top;
    transform: translateY(-30px);
  }

  .Evaluaion_NewEvaluation_Image_Left_Inner_Down_Content2
  {
    display:inline-block;
    vertical-align: top;
    transform: translateY(-30px);
  }

   

  

  
  .Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content1
  {
    transform: translateY(-20px);
    display: inline-block;
    vertical-align: top;
    margin-left: 34px;

  }

  .Evaluaion_NewEvaluation_Image_Right_Inner_Down_Content1
  {
    transform: translateY(-20px);
    display: inline-block;
    vertical-align: top;
    margin-left: 20px;

  }

  .Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content2
  {
    display:inline-block;
    vertical-align: top;
    transform: translateY(-30px);
    
  }

  

  /* .MuiMenu-paper
  {
    background: rgba(0,0,0,0.8) !important;
    border-radius: 0px !important;
    margin-top:16px;
  } */

  .new-evaluation-select-box > .MuiMenu-paper
  {
    background: rgba(0,0,0,0.8) !important;
    border-radius: 0px !important;
    margin-top:16px;
  }

  .new-evaluation-select-box > .MuiMenu-paper > ul
  {
    border:1px solid white;
    width:calc(100% - 2px);
    /* margin:auto; */
  }

  #Evaluaion_NewEvaluation_Next_Button_Div
{
  display: block;
  position: absolute;
  bottom:20px;
  right:10px;
}

#Evaluaion_NewEvaluation_Back_Button_Div{
  display: block;
  position: absolute;
  bottom:20px;
  left:10px; 
}


#Evaluaion_NewEvaluation_Next_Button
{
  display: block;
  width:auto;
  min-width: 300px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}


  /* Responsive Css Down */

  @media only screen and (max-width:1215px)
  {
    #Evaluaion_NewEvaluation_Content_Wrapper
    {
      width:90%;
      margin-left:5%;
    }
  }

  @media only screen and (max-width:1015px)
  {
    #Evaluaion_NewEvaluation_Content_Wrapper
    {
      width:calc(100% - 10px);
      margin-left:5px;
    }
    

  }

  @media only screen and (max-width:925px)
  {
    .Evaluaion_NewEvaluation_Image_Right_Div
    {
      margin-left:5px;
    }
    .Evaluaion_NewEvaluation_Image_Left_Div
    {
      margin-right:5px;
    }
    #Evaluaion_NewEvaluation_Text_Div
    {
      width:840px;
      padding-bottom: 10px;
    }

    #Evaluaion_NewEvaluation_Heading1_Div
    {
      width:840px;
    }

    #Evaluaion_NewEvaluation_Heading2_Div
    {
      width:840px;
    }
  }

  @media only screen and (max-width:850px)
  {
    #Evaluaion_NewEvaluation_Image_Bone
    {
      width:200px
    }
    .Evaluaion_NewEvaluation_Image_Right_Inner_Down
    {
      margin-top: 25px
    }

    .Evaluaion_NewEvaluation_Image_Left_Inner_Down
    {
      margin-top: 25px
    }
    #Evaluaion_NewEvaluation_Text_Div
    {
      width:790px;
    }

    #Evaluaion_NewEvaluation_Heading1_Div
    {
      width:790px;
    }

    #Evaluaion_NewEvaluation_Heading2_Div
    {
      width:790px;
    }

  }

  @media only screen and (max-width:805px)
  {
    .Evaluaion_NewEvaluation_Image_Left_Inner_Up_Content1
    {
      margin-right:13px;
    }

    .Evaluaion_NewEvaluation_Image_Left_Inner_Down_Content1
    {
      margin-right:3px;
    }

    .Evaluaion_NewEvaluation_Image_Right_Inner_Up_Content1
    {
      margin-left:13px;
    }

    .Evaluaion_NewEvaluation_Image_Right_Inner_Down_Content1
    {
      margin-left:3px;
    }

    #Evaluaion_NewEvaluation_Text_Div
    {
      width:740px;
    }

    #Evaluaion_NewEvaluation_Heading1_Div
    {
      width:740px;
    }

    #Evaluaion_NewEvaluation_Heading2_Div
    {
      width:740px;
    }
  }

#Evaluaion_PatientProfile_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

#Evaluaion_PatientProfile_Content_Wrapper
{
  margin-left:10%;
  width:90%;
  /* min-width: 400px; */
  max-width: 100vw;
  min-height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  /* justify-content: center;
  align-items: center; */
}


#Evaluaion_PatientProfile_Content1_Wrapper
{
  /* float:left; */
  width:45%;
  margin-right: 20px;
  /* min-width: 400px;
  max-width: 700px; */
  /* height:100vh; */
  display: inline-block;
  /* justify-content: center;
  flex-direction: column;
  justify-content: center; */
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_PatientProfile_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: inline-block;
}

#Evaluaion_PatientProfile_Profile_Div
{
    font-weight: 400;
    display: block;
    margin-bottom: 20px;
    height:77px;
}
#Evaluaion_PatientProfile_Profile_Avatar_Div
{
    display: inline-block;
}

.Evaluaion_PatientProfile_Profile_Avatar
{
    width:75px !important;
    height:75px !important;
}

#Evaluaion_PatientProfile_Profile_Text_Div
{
    width:116px;
    min-height:65px;
    border: 2px solid #fff;
    color: #fff;
    font-family: 'Lato', sans-serif;
    display: inline-block;
    vertical-align: top;
    margin-left:15px;
    padding:10px 10px 0px;
    text-align: center;
}



#Evaluaion_PatientProfile_Heading2_Div
{
  margin-bottom: 15px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_PatientProfile_Box1_Div
{
    width:calc(100% - 22px);
    padding:10px 10px;
    border-radius: 5px;
    border:1px solid #fff;
    height:330px;
}

.Evaluaion_PatientProfile_Box1_Content1
{
    width:20%;
    background:#BBBBBB;
    height:100px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
     
}
.Evaluaion_PatientProfile_Box1_Content1_Image_Wrapper
{
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.Evaluaion_PatientProfile_Box1_Content1_Image
{
  display: block;
  width: 50px;
}

.Evaluaion_PatientProfile_Box1_Content1_Image_Custom
{
  width: auto;
  max-width: 100px;
  max-height: calc(100% - 25px);
  height: auto;
}

.Evaluaion_PatientProfile_Box1_Content2
{
    width:calc(80% - 20px);
    height:100px;
    /* line-height: 100px; */
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;
}

.Evaluaion_PatientProfile_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 32px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.Evaluaion_PatientProfile_Disable_Box
{

}

.Evaluaion_PatientProfile_Box_DisbaleText3
{
  /* width:100%;
  line-height: 100%;
  height:100%; */
  position: relative;
  display: block;
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 40px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_PatientProfile_Box_DisbaleButton
{
  opacity: 0.7 !important;
  height: 50px !important;
  margin-bottom: 10px !important;
}


.Evaluaion_PatientProfile_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%;
  height:100%; */
  display: block;
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_Forms_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 11px;
  margin-top:10px;
}


#Evaluaion_PatientProfile_Content2_Wrapper
{
  /* float: left; */
  display: inline-block;
  /* justify-content: center;
  flex-direction: column;
  justify-content: center;
  height:100vh; */
  width:calc(55% - 20px);
  /* margin-left: 5%; */
  margin-top: 150px;
  
  /* justify-content: center;
  align-items: center; */
}

.Evaluaion_PatientProfile_Image_Left_Div
{
  display: inline-block;
  vertical-align: top;
  margin-top:140px;
  text-align: center;
  transform: translateX(10px);
}

.Evaluaion_PatientProfile_Image_Right_Div
{
  display: inline-block;
  vertical-align: top;
  margin-top:140px;
  text-align: center;
  transform: translateX(30px);
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down
{
  display:block;
  margin-top:53px;
}


.Evaluaion_PatientProfile_Image_Left_Inner_Up
{
  display:block;
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down
{
  display:block;
  margin-top:53px;
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1
{
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  transform: translateY(-20px);
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content2
{
  display:inline-block;
  vertical-align: top;
  transform: translateY(-30px);
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down_Content1
{
  transform: translateY(50);
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

}

.Evaluaion_PatientProfile_Image_Right_Inner_Up
{
  display: block;
}

  .Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2
  {
    display:inline-block;
    vertical-align: top;
  }


.textbox-height
{
  height: 60px !important;
  font-size:20px !important;
  color:white !important;
  border-radius:0px !important;
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1 fieldset {
  border-radius: 0;
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down_Content1 fieldset {
  border-radius: 0;
}

#Evaluaion_PatientProfile_Image_Bone_Div
{
  width:260px;
  display: inline-block;
  height: calc(100vh - 160px);
}


#Evaluaion_PatientProfile_Image_Bone
{
  max-width: 100%;
  max-height: 100%;
}

.patient-demographics-select-box > .MuiMenu-paper
  {
    background: rgba(0,0,0,0.8) !important;
    border-radius: 0px !important;
    margin-top:0px;
  }

  .patient-demographics-select-box > .MuiMenu-paper > ul
  {
    border:1px solid white;
    width:calc(100% - 2px);
    /* margin:auto; */
  }


/* Responsive css */

@media only screen and (max-height:660px)
{
  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 150px;
  }
  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 150px;
  }
}

@media only screen and (max-width:1417px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    width:40%
  }
  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% - 20px);
  }
}


@media only screen and (max-width:1350px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:0px;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% - 0px);
  }
  
}

@media only screen and (max-width:1260px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:-10px;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% + 10px);
  }
}

@media only screen and (max-width:1260px)
{
  #Evaluaion_PatientProfile_Content_Wrapper
  {
    width:93%;
    margin-left:7%;
  }
  
}

@media only screen and (max-width:1050px)
{
  #Evaluaion_PatientProfile_Image_Bone_Div
  {
    width:250px
  }

  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 140px;
  }

  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 140px;
  }

}

@media only screen and (max-width:950px)
{
  #Evaluaion_PatientProfile_Content_Wrapper
  {
    width:95%;
    margin-left:5%;
  }
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right: 1%;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width: 59%;
  }
  
}

@media only screen and (max-width:877px)
{
  #Evaluaion_PatientProfile_Image_Bone_Div
  {
    width:220px
  }

  #Evaluaion_PatientProfile_Content_Wrapper
  {
    white-space: nowrap;
    margin-left:4%;
    width: 96%;
  }

  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 120px;
  }

  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 120px;
  }


  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    /* transform: translateX(30px); */
    width:calc(51%)
  }
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:10px;
    width: calc(42% + 20px);
    white-space: pre-wrap;
  }

  .Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1
  {
    margin-right:5px
  }
  
}


#Evaluaion_UploadXray_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_UploadXray_Content1_Wrapper
{
 
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_UploadXray_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 30px;
}

.Evaluation_Acknowledge_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
}

.Evaluation_Acknowledge_Content_Wrapper
{
  /* width:70%; */
  /* min-width: 400px; */
  max-width: 1100px;
  position: relative;
  /* line-height: 100vh; */
  /* vertical-align: bottom; */
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center; */
  /* justify-content: center;
  align-items: center; */
}


.Evaluation_Acknowledge_Heading2_Div
{
    font-size: 35px;
   
    font-family: 'Lato', sans-serif;
    color: #fff;
    box-sizing: border-box;

    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 48px;
    font-weight: 700;
    text-align: left;
}

.Evaluation_Acknowledge_Text_Div
{
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;

    margin-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    text-align: left;
  
  /* vertical-align: middle; 
  display: table-row;  */
}

.Evaluation_Acknowledge_Button_Div_Left
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    /* position: absolute;
    left:0px; */
    margin-right: 40px;
    margin-top:30px
}


.Evaluation_Acknowledge_Button_Div_Right
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    /* position: absolute;
    right:0px; */
    margin-top:30px
}

.Evaluation_Acknowledge_Button_Div_Bottom
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    position: absolute;
    bottom:20px;
    left:80px;
    max-width: 500px !important;
}

.Evaluation_Acknowledge_Button
{
    display: inline-block !important;
    width:100% !important;
    min-width: 150px !important;
    max-width: 300px !important;

    padding: 15px 25px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 23px !important;
    line-height: 23px !important;  
}



#Evaluaion_UploadXray_OverView_Box1_Div
{
    min-width: 400px;
    width:calc(100% - 2px);
    max-width: 700px;
    padding:10px 0px;
    border-radius: 5px;
    border:1px solid #fff;
    /* height:560px; */
}

.Evaluaion_UploadXray_OverView_Box1_Content1
{
    width:calc(20% - 20px);
    background:#BBBBBB;
    height:90px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
    padding:5px 5px;
    margin-bottom:10px; 
     
}

.Evaluaion_UploadXray_OverView_Box1_Content1_Image
{
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
}

.Evaluaion_UploadXray_OverView_Box1_Content2
{
    width:calc(80% - 20px);
    height:100px;
    line-height: 100px;
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;

}

.Evaluaion_UploadXray_OverView_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 28px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}


.Evaluaion_UploadXray_OverView_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%; */
  /* height:100%; */
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_UploadXray_OverView_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 11px;
  margin-top:10px;
}


.Evaluaion_UploadXray_OverView_Old_Box_Button
{
  position: absolute;
  right:-230px;
  bottom:60px;
  display: block;
  height:57px !important;
  min-width: 200px !important;
  padding: 15px 25px !important;
  font-size: 32px !important;
  line-height: 27px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}


#Evaluaion_UploadXray_Upload_Box1_Div
{
    min-width: 400px;
    width:calc(100% - 2px);
    max-width: 700px;
    padding:10px 0px;
    border-radius: 5px;
    border:1px solid #fff;
    height:490px;
   
}


.Evaluaion_UploadXray_Upload_Box_Head
{
  /* width:100%;
  line-height: 100%; */
  /* height:100%; */
  width:fit-content;
  max-width: calc(100% - 60px);
  margin: auto;
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 15px;
  margin-top:10px;
  margin-bottom: 25px;
  display: block;

  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
}


.Evaluaion_UploadXray_Upload_Box_Line
{
    width:480px;
    height:2px;
    background-color: #fff;
    margin: auto;
    margin-bottom: 25px;
}

.circle-with-arrow
{
    width: 100px;
    height: 100px;
    margin-right: auto;
    margin-left: auto;
    border-radius: 500px;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAwIMAhnz73JJAAADyElEQVRo3u1ZWWxMURj+znRRUUpLSEVNqRceSHhgkFoifbFGEEFRTSoRT9ZHL7aoLamIRGwPbUQEUUklJJZaKrEEHV50IZRWx/bQjfk8zL230/bOPefcmRuR9Huauf/3/99/zv3+ucsIuAInYiUCmIhMACEE8RAXxRt3tfTFA7xHO9zlDO/F03iCTijjAC/lh/IxZXjADO9WXyOVJ8lHTPOmgdNK8iR5ygv5fGV5kpyfSGnBaSziK60GXrGIUyniF0/lVjZoSUejgVuYEo+8n09di5t4wrFu5XPiWHs0PtDvRj4lAas38Zyp+g3sTJg8SW6LpRPDp0xFI0bFYZ/e+AS/6LIL+GIkzEyoPJCNgOIOMA0FmIGFmCQtegvn8BLAZGzEPCm7FpV4iJuiw4HDTO5js9I5/c0NPTKL+Vsp7wv3clgs+eX8rGyq4j7Zxcq5TVxmJ79Lw9OHrayBHGh9PqJR4UBv+cMayTeYZGStYRvbuMb4lsQbGlUORcuv0kgMmjccnM0OkmQnDQtyMF9qVFpryo/hL+WkFo4zsnKj7NrKCcbRcWxRrvWToyNJJ5VTOjnXEBrS6/L8lkONyCy2K9crA8BRxkaqoMg625V9YjeZbEQ3Kddr50id8Sm1PHPcNn7MxTxsBC8rUist7xfF5GzWnodLYFCJ2Nf7dtCfh9dgSIH2lXk23rdD9zz4lX7UW8E2KSnMOTG8b4fueZjPsJTdBtZLSecdvG+H7nmokHLrwGopyZz9o0rypDUPLJAy74MHpaQMAGAO/yg3EI44gVlS5n4frkKGyF1TGPqPGfKMq6CPbyVdmqNVwiDfSaYmxHcMssSyoTPeUADgMgntTHTL3OPI3dODe1ZSeQngA8QV3HbcpvVcoL35AFiAQkfCLXHNPL+rUe/ogStcp/egScFCXI55zw0AdVgdKQ5AtGARmhzIg3AB5VrLL8d5DHKIN2Gx+Go1AIhaTMMTx5J6zwnO7OeYLmojH61NEp+Qj934riXjBt+wEwHx3vwadZZEuziI8diBaoQ9kf6DamxHnjgk2rsPJvfkiBBKUcpM+JGNkUiKCjUpiZg4gooe0l/wEY0i1JeYbJctQgjhWTyLFddVmT5Volfob+A/bKDFMdrsecdMZxW7bK9tXaxiuucN9CPRcPE6mRmYArs3wF14IX543rHjG4Bm5urW0x/DpRgeMzYCS71voMbhYh1GjW45Nx4IoAB2/411oEo80q/Xj38MbQ/Qh93ItzVvGHdwQNDjjiUvNVfo1tMfwyzH6HDFKnHsQIIvx38B8LG/8TyePKUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDMtMDJUMTI6MDI6MjUrMDA6MDDB0WniAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAzLTAyVDEyOjAyOjI1KzAwOjAwsIzRXgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
    background-position: 50% 50%;
    background-size: 50px;
    background-repeat: no-repeat;
}

#Evaluaion_UploadXray_Upload_Box_Complete_Div
{
    width: 290px;
    margin-top: 15px;
    margin-right: auto;
    margin-left: auto;
}

#Evaluaion_UploadXray_Upload_Box_Complete_Text_Div
{
    padding: 5px;
    margin-right: auto;
    margin-left: auto;
    background-color: hsla(0, 0%, 100%, 0.11);
    text-align: center;
    align-items: center;
    justify-content: center;
    font-family: 'Lato', sans-serif;
    font-size: 24px;
    color: #fff;
    vertical-align:middle;
    /* margin-bottom:10px;  */
    margin-left:10px;
    display: inline-block;

    white-space: nowrap; 
    overflow: hidden;
    text-overflow: ellipsis; 
    width: 170px;
}

.complete-circle-with-arrow
{
    width: 30px;
    height: 30px;
    display: inline-block;
    margin-right: auto;
    margin-left: auto;
    border-radius: 500px;
    vertical-align: middle;
    background-color:  #b4ec51;
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAQAAAC1+jfqAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAhsMFQH1cV8sAAAAWElEQVQoz7WOyw2AIBAFtxEBtxIrFwrxgzYynpRIXOMB5/oyeSPyI3gSas/KBkTbXoBM32DGMRJebBIw0d3SZlwxXDHwrA/flzWwV3b1y5fyIBYo8UxtywFCl3QJOKO1QAAAACV0RVh0ZGF0ZTpjcmVhdGUAMjAyMC0wMi0yN1QxMjoyMTowMSswMDowML1nhccAAAAldEVYdGRhdGU6bW9kaWZ5ADIwMjAtMDItMjdUMTI6MjE6MDErMDA6MDDMOj17AAAAGXRFWHRTb2Z0d2FyZQB3d3cuaW5rc2NhcGUub3Jnm+48GgAAAABJRU5ErkJggg==);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}


#Evaluaion_UploadXray_Upload_Box_Main
{
    border: 2px dashed #fff;
    /* width: 400px; */
    margin: auto;
    margin-top: 15px;
    padding: 25px 30px 15px;
    width: calc(100% - 142px);  /*2px parent - 60px padding - 80px outer*/
    height:calc(100% - 202px)  /*Box padding 20px*/ /*text height 80px - text margin 35px*/ /*line height 2 px - line margin bottom 25px */ /* Drop Zone Padding 40px*/
    
}

.circle-with-arrow-tiny
{
    width: 50px;
    height: 50px;
    background-size: 25px;
    margin: auto;
    margin-bottom: 15px;
    border-radius: 500px;
    background-color: hsla(0, 0%, 100%, 0.27);
    background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAEAAAABACAQAAAAAYLlVAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QAAKqNIzIAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAHdElNRQfkAwIMAhnz73JJAAADyElEQVRo3u1ZWWxMURj+znRRUUpLSEVNqRceSHhgkFoifbFGEEFRTSoRT9ZHL7aoLamIRGwPbUQEUUklJJZaKrEEHV50IZRWx/bQjfk8zL230/bOPefcmRuR9Huauf/3/99/zv3+ucsIuAInYiUCmIhMACEE8RAXxRt3tfTFA7xHO9zlDO/F03iCTijjAC/lh/IxZXjADO9WXyOVJ8lHTPOmgdNK8iR5ygv5fGV5kpyfSGnBaSziK60GXrGIUyniF0/lVjZoSUejgVuYEo+8n09di5t4wrFu5XPiWHs0PtDvRj4lAas38Zyp+g3sTJg8SW6LpRPDp0xFI0bFYZ/e+AS/6LIL+GIkzEyoPJCNgOIOMA0FmIGFmCQtegvn8BLAZGzEPCm7FpV4iJuiw4HDTO5js9I5/c0NPTKL+Vsp7wv3clgs+eX8rGyq4j7Zxcq5TVxmJ79Lw9OHrayBHGh9PqJR4UBv+cMayTeYZGStYRvbuMb4lsQbGlUORcuv0kgMmjccnM0OkmQnDQtyMF9qVFpryo/hL+WkFo4zsnKj7NrKCcbRcWxRrvWToyNJJ5VTOjnXEBrS6/L8lkONyCy2K9crA8BRxkaqoMg625V9YjeZbEQ3Kddr50id8Sm1PHPcNn7MxTxsBC8rUist7xfF5GzWnodLYFCJ2Nf7dtCfh9dgSIH2lXk23rdD9zz4lX7UW8E2KSnMOTG8b4fueZjPsJTdBtZLSecdvG+H7nmokHLrwGopyZz9o0rypDUPLJAy74MHpaQMAGAO/yg3EI44gVlS5n4frkKGyF1TGPqPGfKMq6CPbyVdmqNVwiDfSaYmxHcMssSyoTPeUADgMgntTHTL3OPI3dODe1ZSeQngA8QV3HbcpvVcoL35AFiAQkfCLXHNPL+rUe/ogStcp/egScFCXI55zw0AdVgdKQ5AtGARmhzIg3AB5VrLL8d5DHKIN2Gx+Go1AIhaTMMTx5J6zwnO7OeYLmojH61NEp+Qj934riXjBt+wEwHx3vwadZZEuziI8diBaoQ9kf6DamxHnjgk2rsPJvfkiBBKUcpM+JGNkUiKCjUpiZg4gooe0l/wEY0i1JeYbJctQgjhWTyLFddVmT5Volfob+A/bKDFMdrsecdMZxW7bK9tXaxiuucN9CPRcPE6mRmYArs3wF14IX543rHjG4Bm5urW0x/DpRgeMzYCS71voMbhYh1GjW45Nx4IoAB2/411oEo80q/Xj38MbQ/Qh93ItzVvGHdwQNDjjiUvNVfo1tMfwyzH6HDFKnHsQIIvx38B8LG/8TyePKUAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjAtMDMtMDJUMTI6MDI6MjUrMDA6MDDB0WniAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIwLTAzLTAyVDEyOjAyOjI1KzAwOjAwsIzRXgAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAAASUVORK5CYII=);
    background-position: 50% 50%;
    background-repeat: no-repeat;
}

#Evaluaion_UploadXray_Upload_Box_Main_Text
{
    margin-bottom: 15px;
    margin-top: 15px;

    font-size: 24px;
    line-height: 28px;
    color: #fff;
}

#Evaluaion_UploadXray_Upload_Next_Button_Div
{
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom:10px;
  right:10px;
}

#Evaluaion_UploadXray_Upload_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

.Evaluaion_UploadXray_Upload_Box_Loader_Text
{
    color:white;
}

.Evaluaion_UploadXray_Upload_Box_Delete_Button
{
    background-color: #b4ec51 !important;
    color:grey;
    margin-left: 20px !important;
    padding-left: 0px !important;
    padding-right: 0px !important;
    /* padding-bottom: 0px !important; */
    min-width: 40px !important;
}

.Evaluaion_UploadXray_Upload_Box_ViewType_Text
{
    color: white;
    margin-top:20px;
    padding: 10px;
    font-size: 25px;
}



#inner-box-1 {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  #inner-box-1 {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  #inner-box-1 {
    flex-direction: column;
  }
}

#Export-Script-child_1,
#Export-Script-child_2 {
  flex-grow: 1;
}

#Export-Script-child_1 {
  color: white;
  font-family: Lato, sans-serif;
  font-size: 20px;
  padding: 5px 0px 5px 10px;
  max-width: calc(60% - 10px);
  display: inline-block;
}

#Export-Script-child_2 {
  display: inline-block;
  max-width: calc(40%);
  vertical-align: super;
  vertical-align: top;
}

#Evaluaion_Export-Script_Next_Button_Div2 {
  display: inline-block;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  padding-top: 10px;
}

/* #Evaluaion_NewEvaluation_Next_Button {
    display: block;
    min-width: 280px;
    padding: 10px 20px;
    font-size: 20px;
    line-height: 22px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
  } */

#Export-Script-last-header {
  color: white;
  font-family: Lato, sans-serif;
  font-size: 22px;
  width: 60%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  max-width: 50%;
  display: inline-block;
}

#Evaluaion_ExportScript_Next_Button_Div1 {
  display: inline-block;
  justify-content: center;
  position: absolute;
  right: 0px;
  padding-top: 10px;
}

/* Re-use from newEvaluation.css */

/* this */
#Evaluaion_ExportScript_Save_Button1 {
  display: block;
  min-width: 280px;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 22px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

.Export-Script-btn-layer1 {
  flex-grow: 1;
}

.Export-Script-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

#ExportScript_Welcome_Next_Button1 {
  display: block;
  width: auto;
  min-width: 250px;
  padding: 10px 15px;
  float: left;
  font-size: 20px;
  line-height: 25px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#ExportScript_Welcome_Next_Button2 {
  display: block;
  width: auto;
  min-width: 250px;
  padding: 10px 15px;
  float: left;
  font-size: 20px;
  line-height: 25px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

@media screen and (max-width: 800px) {
  #ExportScript_Welcome_Next_Button1,
  #ExportScript_Welcome_Next_Button1 {
    min-width: 200px;
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  #ExportScript_Welcome_Next_Button1,
  #ExportScript_Welcome_Next_Button1 {
    min-width: 100px;
    font-size: 24px;
  }
}

.Evaluation_ExportScript_Heading2_Div {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: #fff;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 48px;
  font-weight: 700;
  text-align: left;
}

/* this */
#Evaluaion_ExportScript_Export_Button {
  display: block;
  width: auto;
  min-width: 100%;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#Evaluaion_ExportScript_Export_Content1 {
  position: relative;
}

#Evaluaion_ExportScript_Export_Content1_Heading {
  display: inline-block !important;
}

#Evaluaion_ExportScript_Export_Content2 {
  position: relative;
}

#Evaluaion_ExportScript_Export_Content2_Heading {
}

/* Screen: 31 Css */

#upload-type-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#upload-type-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-box-2 {
    height: 10vh;
    display: flex;
    align-items: center;
}

#upload-type-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 35px;
    margin-bottom: 10px;
}

#upload-type-btn-container-1 , #upload-type-btn-container-2 {
    margin-top: 20px;
}

#upload-type-btn-1 , #upload-type-btn-2 ,#upload-type-btn-3 , #upload-type-btn-4 {
    padding: 10px 20px;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
    text-transform: none;
    width: 300px;
}

#upload-type-btn-1 , #upload-type-btn-3 , #upload-type-btn-4 {
    line-height: 50px;
}

#upload-type-btn-back {
    padding: 10px 20px;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
    text-transform: none;
    width: 200px;
    margin-left: 50px;
}
/* Screen 32 */

#upload-type-one-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#upload-type-one-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-one-box-2 {
    height: 10vh;
    display: flex;
    align-items: center;
}

#upload-type-one-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
}

#upload-type-one-raw-1 {
    display: flex;
    flex-direction: row;
    width: 80%;
}

#upload-type-one-raw-2 {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-top: 20px;
}

.app-images {
    height: 100px;
    width: 100px;
}

#upload-type-one-handler-1 , #upload-type-one-handler-2 {
    flex-grow: 1;
    width: 50%;
}

#upload-type-one-handler-1 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 25px;
    padding: 10px 20px 10px 20px;
}

#upload-type-one-handler-2 {
    display: flex;
    flex-direction: row;;
}

.upload-type-one-top-images-box {
    flex-grow: 1;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-one-paragraph-1 , #upload-type-one-paragraph-2 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 20px;
    padding: 10px;
}

#upload-type-one-box_2 {
    height: 10vh;
}

#upload-type-one_Next_Button_Div {
    display: block;
    display: flex;
    flex-direction: row;
  }

  .upload-type-one-btn-layer1 {
    flex-grow: 1;
    margin-left: 45px;
}

.upload-type-one-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 45px;
}

#upload-type-one_Next_Button {
    display: block;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }

  #upload-type-one_Next_Button1 {
    display: block;
    width: auto;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }
/* Screen 33 */

#upload-type-two-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#upload-type-two-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-two-box-2 {
    height: 10vh;
    display: flex;
    align-items: center;
}

#upload-type-two-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
}

#upload-type-two-raw-1 {
    display: flex;
    flex-direction: row;
    width: 80%;
}

#upload-type-two-raw-2 {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-top: 20px;
}

.upload-type-two-app-images {
    height: 100px;
    width: 100px;
}

#upload-type-two-handler-1 , #upload-type-two-handler-2 {
    flex-grow: 1;
    width: 50%;
}

#upload-type-two-handler-1 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 25px;
    padding: 10px 20px 10px 20px;
}

#upload-type-two-handler-2 {
    display: flex;
    flex-direction: row;;
}

.upload-type-two-top-images-box {
    flex-grow: 1;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-two-paragraph-1 , #upload-type-two-paragraph-2 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 20px;
    padding: 10px;
}

#upload-type-two-box_2 {
    height: 10vh;
}

#upload-type-two_Next_Button_Div {
    display: block;
    display: flex;
    flex-direction: row;
  }

  .upload-type-two-btn-layer1 {
    flex-grow: 1;
    margin-left: 45px;
}

.upload-type-two-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 45px;
}

#upload-type-two_Next_Button {
    display: block;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }

  #upload-type-two_Next_Button1 {
    display: block;
    width: auto;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }
/* Screen 34 */

#upload-type-three-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#upload-type-three-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-three-box-2 {
    height: 10vh;
    display: flex;
    align-items: center;
}

#upload-type-three-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 40px;
    margin-bottom: 20px;
}

#upload-type-three-raw-2 {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin-top: 20px;
}

.upload-type-three-app-images {
    height: 100px;
    width: 100px;
}

#upload-type-three-handler-2 {
    flex-grow: 1;
    width: 50%;
}

#upload-type-three-handler-1 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 25px;
    padding: 10px 20px 10px 20px;
}

#upload-type-three-handler-2 {
    display: flex;
    flex-direction: row;;
}

.upload-type-three-top-images-box {
    flex-grow: 1;
    width: 25%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-three-paragraph-1 , #upload-type-three-paragraph-2 {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 20px;
    padding: 10px;
}

#upload-type-three-box_2 {
    height: 10vh;
}

#upload-type-three_Next_Button_Div {
    display: block;
    display: flex;
    flex-direction: row;
  }

  .upload-type-three-btn-layer1 {
    flex-grow: 1;
    margin-left: 45px;
}

.upload-type-three-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 45px;
}

#upload-type-three_Next_Button {
    display: block;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }

  #upload-type-three_Next_Button1 {
    display: block;
    width: auto;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
    width: 200px;
  }
#XRConfirmation {
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;   
    position: relative;
    overflow: hidden;
}

#all-content-wrapper {
    width: calc(100% - 150px);
    margin-left: 150px;
    /* border: 1px solid white; */
}

* {
    font-family: Lato,sans-serif;
}

#XRConfirmation-box-1 {
    /* border: 1px solid yellow; */
    height: 90vh;
    line-height: 5vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#XRConfirmation-box-2 {
    /* border: 1px solid white; */
    height: 9.5vh;
    display: flex;
    flex-direction: row;
}

#XRConfirmation-inner-box-1 , #XRConfirmation-inner-box-2 , #XRConfirmation-inner-box-3 {
    /* border: 1px solid yellow; */
    width: 58%;
    margin-top: 10px;
}

#XRConfirmation-inner-box-1 {
    color: white;
    font-size: 25px;
    padding: 10px;
}

.XRConfirmation-img-1-Wrapper
{
    width: 400px;
    height: 300px;
}

/* Image Area */
#XRConfirmation-img-1 , #XRConfirmation-img-2 {
    max-height: 300px;
    max-width: 400px;
    height: auto;
    width: auto;
}

#XRConfirmation-inner-box-2 {
    display: flex;
    justify-content: center;
    margin-top: 25px;
    width:29%;
    margin-left: calc(29% + 25px);
}

#XRConfirmation-Upper-Btn {
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-transform: none;
    border-radius: 25px;
    background-color: #b4ec51;
    height: 40px;
    width: 180px;
    opacity: 0.5;
    margin-bottom: 20px;

}

#XRConfirmation-inner-box-3 {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-top: 20px;
    width:75%;
}

#XRConfirmation-inner-box-left-image
{
    width: calc(50% - 20px);
    justify-content: center;
    text-align: center;
    margin-right:20px ;
}

#XRConfirmation-inner-box-right-image
{
    width: calc(50% - 20px);
    justify-content: center;
    text-align: center;
    margin-left:20px ;

}

@media screen and ( max-width: 800px ) {
    #XRConfirmation-inner-box-1{
        min-width: 90%;
    }

    #XRConfirmation-inner-box-2{
        min-width: 90%;
    }
}

/* Buttons Area */
#XRConfirmation-footer-box-1 , #XRConfirmation-footer-box-2 {
    flex-grow: 1;
    /* border: 1px solid red; */
}

#XRConfirmation-btn-1 , #XRConfirmation-btn-2 {
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-transform: none;
    border-radius: 25px;
    background-color: #b4ec51;
    height: 40px;
    width: auto;
    max-width: 300px;
}

#XRConfirmation-btn-1 {
    margin-left: 10px;
}

#XRConfirmation-btn-2 {
    float: right;
    margin-right: 10px;
}

#XRConfirmation-inner-box-left-button-div
{
    height:40px;
    margin-bottom: 20px;
}
/* same as welcome.css */

#Evaluaion_XrayMatching_Intro_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 2;
    box-sizing: unset;
}
#Evaluaion_XrayMatching_EvalName_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#Evaluaion_XrayMatching_EvalName_Content_Wrapper
{
  margin-left:10%;
  width:80%;
  /* min-width: 400px; */
  max-width: 100vw;
  min-height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  position: relative;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_XrayMatching_EvalName_Next_Button_Div
{
    position: absolute;
    bottom:10px;
    right:0px
}

#Evaluaion_XrayMatching_EvalName_Content1_Wrapper
{
    /* min-width: 400px;
    max-width: 700px; */
    /* height:100vh; */
    display: block;
}

#Evaluaion_XrayMatching_EvalName_Content2_Wrapper
{
  /* float: left; */
  display: inline-block;
 
  width:100%;
  margin-top: 50px;
 
}

#Evaluaion_XrayMatching_EvalName_Image_Bone_Div
{
  width:260px;
  display: inline-block;
  height:calc(100vh - 160px);
}

#Evaluaion_XrayMatching_EvalName_Image_Bone
{
  max-width: 100%;
  max-height: 100%;
}

.Evaluaion_XrayMatching_EvalName_Image_Left_Div {
  display: inline-block;
  vertical-align: top;
  margin-top: 170px;
  text-align: center;
}

.Evaluaion_XrayMatching_EvalName_Image_Right_Div {
  display: inline-block;
  vertical-align: top;
  margin-top: 170px;
  text-align: center;
}


#Evaluaion_XrayMatching_OverView_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_XrayMatching_OverView_Content1_Wrapper
{
 
  width:100vw;
  min-height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_XrayMatching_OverView_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}

#Evaluaion_XrayMatching_OverView_Heading2_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 30px;
}







.Evaluaion_XrayMatching_OverView_Box_Div
{
    min-width: 400px;
    width:calc(100% - 205px);
    max-width: 700px;
    padding:10px 0px 0px;
    border-radius: 5px;
    border:1px solid #fff;
    /* height:215px; */
    margin-bottom:10px;
    display:inline-block;
}

.Evaluaion_XrayMatching_OverView_Label_Div
{
  display: inline-block;
  width:185px;
  font-size: 50px;
  color:white;
  line-height: 45px;
  padding-right: 15px;
  vertical-align: top;
}

.Evaluaion_XrayMatching_OverView_Box1_Content1
{
    width:calc(20%);
    /* background:#BBBBBB; */
    height:90px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
    /* padding:5px 5px; */
    margin-bottom:5px; 
    margin-left: 5px;
     
}
.Evaluaion_XrayMatching_OverView_Box1_Content1_Image
{
    display: block;
    max-width: 100%;
    width: auto;
    height: auto;
    max-height: 100%;
}

.Evaluaion_XrayMatching_OverView_Box1_Content2
{
    width:calc(80% - 20px);
    height:80px;
    line-height: 80px;
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;

}

.Evaluaion_XrayMatching_OverView_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 32px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}


.Evaluaion_XrayMatching_OverView_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%; */
  /* height:100%; */
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 28px;
  line-height: 43px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_XrayMatching_OverView_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 17px;
  line-height: 15px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  /* padding: 11px; */
  padding:7px 11px;
  margin-top:10px;
}


#Evaluaion_XrayMatching_Overview_Next_Button_Div
{
  min-width:300px;
  margin:auto;
  display: block;
  position: absolute;
  bottom:10px;
  right:10px;
}

#Evaluaion_XrayMatching_Overview_Next_Button
{
    display: block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 20px !important;
    line-height: 48px !important;
    border-radius: 25px !important;
    background: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

#Evaluaion_XrayMatching_EvalName_Content2_Outer_Wrapper
{
  width:calc(100% - 50px)
}


#Evaluaion_XrayMatching_Matching_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_XrayMatching_Matching_Content1_Wrapper {
  float: left;
  margin-left: 15%;
  margin-right: 3%;
  width: 42%;
  min-width: 250px;
  max-width: 700px;
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_XrayMatching_Matching_Heading1_Div {
  font-family: "Lato", sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}

#Evaluaion_XrayMatching_Matching_Heading2_Div {
  font-family: "Lato", sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.Evaluaion_XrayMatching_Matching_State_Button_Div {
  display: block;
  margin-bottom: 10px;
  position: relative;
}

.Evaluaion_XrayMatching_Matching_State_Button {
  display: block;
  width: 100% !important;
  padding: 5px 15px !important;
  font-size: 32px !important;
  border-radius: 25px !important;
  /* border-width: 1px !important;
    border-color: hsla(0, 0%, 100%, 0.34) !important; */
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}

.Evaluaion_XrayMatching_Matching_Confirm_Button_Div {
  display: inline-block;
  width: 55%;
}

.Evaluaion_XrayMatching_Matching_Confirm_Button {
  display: inline-block;
  width: 100% !important;
  padding: 5px 15px !important;
  font-size: 28px !important;
  border-radius: 25px !important;
  background: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}

.Evaluaion_XrayMatching_Matching_AddNotes_Button_Div {
  width: 35%;
  float: right;
}

.Evaluaion_XrayMatching_Matching_Modal1_Button_Div {
  width: 35%;
  float: left;
  margin-right: 20px;
}

.Evaluaion_XrayMatching_Matching_Modal2_Button_Div {
  width: 30%;
  float: left;
}

.Evaluaion_XrayMatching_Matching_AddNotes_Button {
  display: inline-block;
  width: 100% !important;
  padding: 5px 15px !important;
  font-size: 20px !important;
  line-height: 48px !important;
  border-radius: 25px !important;
  background: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}

.Evaluaion_XrayMatching_Matching_View_FullXray_Button_Div {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#Evaluaion_XrayMatching_Matching_View_FullXray_Button {
  display: inline-block;
  width: 100% !important;
  padding: 5px 5px !important;
  font-size: 15px !important;
  line-height: 30px !important;
  border-radius: 15px !important;
  background: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
  z-index: 10;
}

.Evaluaion_XrayMatching_Matching_View_NoImage_FullXray_Text_Div {
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  height: 50%;
}

.Evaluaion_XrayMatching_Matching_View_NoImage_FullXray_Button_Div {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  height: 50%;
}

#Evaluaion_XrayMatching_Matching_View_NoImage_FullXray_Button {
  display: inline-block;
  width: 100% !important;
  padding: 5px 5px !important;
  font-size: 15px !important;
  line-height: 30px !important;
  /* border-radius: 15px !important; */
  /* background: #b4ec51 !important; */
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
  max-width: 300px !important;
}

.Evaluaion_XrayMatching_Matching_Modal2_Button {
  display: inline-block;
  width: 100% !important;
  padding: 5px 15px !important;
  font-size: 20px !important;
  line-height: 48px !important;
  border-radius: 25px !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
  border: 1px solid #b4ec51 !important;
  color: #b4ec51 !important;
}

#Evaluaion_XrayMatching_Matching_Content2_Wrapper {
  float: left;
  /* width:35%; */
  /* min-width:250px; */
  max-width: 700px;
  height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

.Evaluaion_XrayMatching_Matching_Xray_Image_Wrapper {
  /* style={{maxWidth:'500px',maxHeight:'calc(50% - 50px)',textAlign:'center',position:'relative'}} */
  position: relative;
  max-height: calc(50vh - 50px);
}

.Evaluaion_XrayMatching_Matching_Xray_Image_Cropper {
  max-height: calc(50vh - 50px);
  max-width: 500px;
  height: calc(50vh - 50px);
  width: auto;

  background-repeat: no-repeat !important;
  background-position: center !important ;
  background-size: cover !important;
}

.Evaluaion_XrayMatching_Matching_Xray_Image {
  max-width: 500px;
  /* margin-top: -70px; */
  max-height: calc(50vh - 50px);
}

.Evaluaion_XrayMatching_Matching_Xray_Image_Compairing {
  max-width: 500px;
  max-height: calc(50vh - 50px);
}

.Evaluaion_XrayMatching_Matching_NoXray_Box {
  /* style={{maxWidth:'100%',maxHeight:'100%'}} */
  /* position: relative; */
  max-width: calc(500px - 20px); /* 20px padding */

  max-height: calc(50vh - 70px); /* 20px padding */
  background: grey;
  height: 370px;
  text-align: center;
  justify-content: center;
  font-size: 38px;
  padding: 10px;
  line-height: 45px;
  color: white;
  display: flex;
  flex-direction: column;
}

#Evaluaion_XrayMatching_Matching_Heading3_Div {
  font-family: "Lato", sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}

.matching-down {
  position: relative;
  /* max-width: 500px;
  max-height: calc(50% - 49px);
  margin-left:0px;
  text-align: center; */
  /* margin: auto; */
}

.Evaluaion_XrayMatching_Matching_Modal_Div {
  background: #3023ae;
  width: 500px;
  height: 480px;
  padding-top: 15px;
  border-radius: 0px;
  outline: none !important;
}

.Evaluaion_XrayMatching_Matching_Modal_Div1 {
  background: #3023ae;
  width: 500px;
  height: 520px;
  padding-top: 15px;
  border-radius: 0px;
  outline: none !important;
}

.Evaluaion_XrayMatching_Matching_Modal_Heading {
  margin-left: 50px;
  margin-bottom: 20px;
  font-size: 36px;
  font-family: Lato, sans-serif;
  color: #fff;
}

.Evaluaion_XrayMatching_Matching_Modal_Heading1 {
  text-align: center;
  margin-bottom: 10px;
  font-size: 36px;
  font-family: Lato, sans-serif;
  color: #fff;
}

.Evaluaion_XrayMatching_Matching_Modal_Description {
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-family: Lato, sans-serif;
  color: #fff;
}

.Evaluaion_XrayMatching_Matching_Modal_Sub_Head {
  margin-left: 50px;
  margin-bottom: 5px;
  font-size: 18px;
  font-family: Lato, sans-serif;
  color: rgb(153, 138, 138);
}

.Evaluaion_XrayMatching_Matching_Modal_Notes_Div {
  background: white;
  margin: auto;
  width: 400px;
  height: 300px;
  border-radius: 10px;
  position: relative;
}

.Evaluaion_XrayMatching_Matching_Modal_Notes_TextArea {
  width: 380px;
  height: 280px;
  margin-left: 10px;
  margin-top: 10px;
  resize: none;
  border: none;
  outline: none;
  font-size: 20px;
  line-height: 30px;
  overflow: hidden;
}

.Evaluaion_XrayMatching_Matching_Image_Label1 {
  position: absolute;
  /* top: 20px; */
  left: 20px;
  width: 130px;
  height: auto;
  background: white;
  font-size: 18px;
  line-height: 11px;
  font-family: Lato, sans-serif;
  bottom: 20px;
  padding: 8px;
}

.Evaluaion_XrayMatching_Matching_Image_Label2 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: auto;
  background: rgb(255, 255, 255);
  opacity: 0.8;
  font-size: 13px;
  line-height: 11px;
  font-family: Lato, sans-serif;
  color: #333333;
  padding: 8px;
}

.Evaluaion_XrayMatching_Matching_Image_Label3 {
  position: absolute;
  bottom: 20px;
  left: 20px;
  width: 150px;
  height: auto;
  background: rgb(255, 255, 255);
  opacity: 0.8;
  font-size: 15px;
  line-height: 11px;
  font-family: Lato, sans-serif;
  color: black;
  padding: 8px;
}

/* @media only screen and (max-height:695px)
{
  .Evaluaion_XrayMatching_Matching_Image_Label2
  {
    left:60px;
  }
  .Evaluaion_XrayMatching_Matching_Image_Label1
  {
    left:60px;
  }
} */

/* .matching-text-to-image-wrapper
{    
  height: inherit;
  max-height: 100%;
  max-width: 100%;
  width: inherit;
  position: relative;

} */

.flipme img {
  transform: scaleX(-1);
}

.Evaluaion_XrayMatching_Matching_Modal_Notes_Heading {
  position: absolute;
  top: 10px;
  font-size: 20px;
  left: 5px;
}

.Evaluaion_XrayMatching_Matching_State_Button_Prediction_Box {
  position: absolute;
  left: -120px;
  width: 80px;
  background-color: orange;
  padding: 10px;
  padding-top: 20px;
  padding-bottom: 20px;
  top: 7px;
  color: black;
  font-weight: 640;
  text-align: center;
  font-size: 20px;
}

.Evaluaion_XrayMatching_Matching_View_White_Box_Left {
  height: 63%;
  width: 45%;
  border: 2px white solid;
  position: absolute;
  top: 70px;
  left: 20px;
}
.Evaluaion_XrayMatching_Matching_View_White_Box_Right {
  height: 63%;
  width: 45%;
  border: 2px white solid;
  position: absolute;
  top: 50px;
  left: 197px;
}

.zoom-pan figure:hover  img{

    opacity: 0;

    /* width:10% */
    /* width: 400px;
    height: 100px;*/
  }
  .zoom-pan figure:hover
  {
    transform: scale(1); 
    background-size: 250% 250%;

  }
  
  .zoom-pan img {
   
    display: block;    
    pointer-events: none;
    max-width: 100%;
    max-height:320px;
  }
  
  .zoom-pan {
    
    display: flex;
    justify-content: center;
    align-items: center;
  }
.XrayImage_Modal
{
    display:block;
    padding: 10px;
    background: #3023ae;
    max-width: 100%;
    align-self: center;
}

.XrayImage_Modal_Wrapper
{
    text-align: center;
    width: 100vw;
    height: 100vh;
    align-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

#XrayImage_Modal_Close_Button
{
    display: block;
    width:auto;
    min-width: 200px;
    padding: 15px 25px;
    font-size: 20px;
    line-height: 20px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: 'Lato', sans-serif !important;
    text-transform:none;
    margin-top:20px;
    margin-left: calc(50% - 100px);
}


#XrayImage_Modal_Update_View_Button
{
    display: block;
    width:auto;
    min-width: 200px;
    padding: 15px 25px;
    font-size: 20px;
    line-height: 20px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: 'Lato', sans-serif !important;
    text-transform:none;
    margin-top:20px;
    margin-left: calc(50% - 100px);
}

#Evaluaion_Report_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  margin: auto;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

#ReportMainDiv {
  width: 60vw;
  margin: auto;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: -webkit-linear-gradient(left ,#86b2fa, #6D9EEB);
  border: 1px solid #fff;
  min-width: 700px;
  max-width: 900px;
}

#Evaluaion_NewEvaluation_Back_Button_Divs{
  position: absolute;
  left:150px;
  bottom: 5px;
  max-width: 100px;
}

#Evaluaion_NewEvaluation_Next_Button_report
{
  display: block;
  width:auto;
  min-width: 220px;
  padding: 12px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 30px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#Evaluaion_Report_Content_Wrapper {
  width: 100vw;
  height: 100vh;

  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1300px) {
  #ReportMainDiv {
    width: 80vw;
  }
}

@media only screen and (max-width: 950px) {
  #ReportMainDiv {
    width: 90vw;
  }
}

@media only screen and (max-width: 850px) {
  #ReportMainDiv {
    width: calc(100vw - 40px);
  }
}

@media only screen and (max-width: 800px) {
  #ReportMainDiv {
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100vw - 10px);
  }
}

@media only screen and (max-width: 767px) {
  #ReportMainDiv {
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100vw);
  }
}

.Evaluaion_Report_Box_Text1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Text2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Priority {
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Priority_Box {
  border: 1px solid #fff;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
  padding: 10px 20px;

  /* display:block */
}

.Evaluaion_Report_Box_Text3 {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Text4 {
  height: 100px;
  width: 121px;
  /* background: #3023ae; */
  /* padding-top: 5px; */
  transform: rotate(-45deg) translateY(-27px) translateX(80px);
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
  word-wrap: nowrap;
}

.Evaluaion_Report_Box_Text5 {
  text-align: center;
  font-family: Lato, sans-serif;
  color: #fff;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 400;
}

/* .Evaluaion_Report_Box_Text6 {

} */

.Evaluaion_Report_Box_Selected_Box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
}

.Evaluaion_Report_Box_Selected_Box_Eval_Change {
  color: white;
  font-size: 16px;
}

.Evaluaion_Report_Box_Button_Div {
  width: calc(100% - 10px);
  padding-left: 5px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 7px;
}

.Evaluaion_Report_Box_Button_Div2 {
  width: calc(100% - 10px);
  padding-left: 5px;
  height: 25px;
  margin: auto;
  position: absolute;
  top: 7px;
  justify-content: center;
  text-align: center;
  padding-top: 15px;
  color: white;
}

.Evaluaion_Report_Box_Button {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  margin: auto !important;
  /* padding: 15px 0px; */
  font-size: 18px !important;
  line-height: 18px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.Evaluaion_Report_Box_Search_Box {
  position: absolute;
  border-radius: 10px;
  bottom: 10px;
  left: 10px;
  padding: 10px 10px 7px;
  background: #b4ec51;
}

.Evaluaion_Report_Box_Note_Box {
  position: absolute;
  border-radius: 5px;
  bottom: 0px;
  right: 0px;
  padding: 10px 10px 2px;
  background: #b4ec51;
}

.Evaluaion_Report_Box_Notes_Box {
  position: absolute;
  border-radius: 5px;
  bottom: 10px;
  right: 20px;
  padding: 10px 10px 2px;
  background: #b4ec51;
}

#Evaluaion_Report_Next_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#Evaluaion_Report_Next_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif;
  text-transform: none;
}

.Evaluation_Report_XrayImage_Modal_Wrapper {
  text-align: center;
  width: 100vw;
  height: 100vh;
  align-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Evaluaion_Report_Modal_Close_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif !important;
  text-transform: none;
  margin-top: 20px;
  margin-left: calc(50% - 100px);
}

#Evaluaion_Report_Box_Wrapper {
}

#Evaluaion_Report_Selected_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_Report_Selected_Content1_Wrapper
{
 
  float:left;
  margin-left:15%;
  margin-right: 3%;
  width:42%;
  min-width:250px;
  max-width: 700px;
  height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Report_Selected_Heading1_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}

#Evaluaion_Report_Selected_Heading2_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}



.Evaluaion_Report_Selected_State_Button_Div
{
  display: block;
  margin-bottom: 10px;
}

.Evaluaion_Report_Selected_State_Button
{
    display: block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 32px !important;
    border-radius: 25px !important;
    border-width: 1px !important;
    border-color: hsla(0, 0%, 100%, 0.34) !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

.Evaluaion_Report_Selected_Confirm_Button_Div
{
  display: inline-block;
  width:55%;
}

.Evaluaion_Report_Selected_Confirm_Button
{
    display: inline-block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 28px !important;
    border-radius: 25px !important;
    background: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

.Evaluaion_Report_Selected_AddNotes_Button_Div
{
  width: 30%;
  float: right;
}

.Evaluaion_Report_Selected_AddNotes_Button
{
    display: inline-block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 20px !important;
    line-height: 48px !important;
    border-radius: 25px !important;
    background: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

#Evaluaion_Report_Selected_Content2_Wrapper
{
  float:left;
  /* width:35%; */
  min-width:250px;
  max-width: 700px;
  height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Report_Selected_Heading3_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 30px;
  text-align: right;
  margin-bottom: 30px;
}



.Evaluation_ResumeEvaluation_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
}

.Evaluation_ResumeEvaluation_Content_Wrapper
{
  float:left;
  margin-left:15%;
  width:70%;
  min-width: 400px;
  max-width: 1100px;
  min-height:100vh;
  /* line-height: 100vh; */
  /* vertical-align: bottom; */
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  /* justify-content: center;
  align-items: center; */
}


.Evaluation_ResumeEvaluation_Heading2_Div
{
    font-size: 32px;
   
    font-family: 'Lato', sans-serif;
    color: #fff;
    box-sizing: border-box;

    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 48px;
    font-weight: 700;
}

.Evaluation_ResumeEvaluation_Text_Div
{
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;

    margin-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
  
  /* vertical-align: middle; 
  display: table-row;  */
}

.Evaluation_ResumeEvaluation_Button_Div
{
    /* width:150px; */
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top:60px
}

.Evaluation_ResumeEvaluation_Button
{
    display: block !important;
    width:100% !important;
    min-width: 150px !important;
    padding: 15px 25px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 23px !important;
    line-height: 23px !important;  
}


#Evaluation_PDF_MAIN_DIV
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
}

#Evaluation_PDF_Content_Wrapper
{
    /* style={{height:'100vh',margin:'auto',display:'flex',justifyContent:'center',alignItems:'center'}} */
  height:calc(100vh - 80px);
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 80px;

}

.report-canvas-div
{
    /* margin:auto; */
  height:861px;
  max-height: 80vh;
  overflow: auto;
  /* max-width: 612px; */
  width:calc(100vw - 500px);
  max-width:calc(100vw - 500px);
  margin-left: 120px;
}

.report-navbar-wrapper
{
 background: black;
 width:595px;
 margin-left: 120px !important;


}

.report-navbar-wrapper .column 
{
  margin:0px;
  padding: 0px;
  flex-grow:1;
}

#Evaluaion_pdf_Heading1_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}


#Evaluaion_pdf_Container
{
  width: calc(100% - 150px);
  height: 100%;
  margin-left:150px;
}

#Evaluaion_pdf_child1
{
  width: 70%;
  display: inline-block;
  height: 100%;
}

#Evaluaion_pdf_child2
{
  width: calc(30% - 20px);
  display: inline-block;
  position: relative;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.Evaluation_PDF_Button_Div
{
  position: relative;
  left:50%;
  transform: translateX(-50%);
  max-width:calc(100% - 40px);
  width: auto;
}

@media only screen and (min-width:1300px)
{
  .Evaluation_PDF_Button_Div
  {
    max-width:300px;
  }
}

#Evaluaion_PDF_Download_Button_Div
{
  top:100px;
}

#Evaluaion_PDF_RCP_Button_Div
{
  bottom:200px;
}

#Evaluaion_PDF_Back_Button_Div
{
  bottom:10px;
  position: absolute;
  /* left:50%;
  transform: translateX(-50%) */
}

#Evaluaion_PDF_Back_Home_Button_Div
{
  bottom:10px;
}

#Evaluaion_PDF_Button
{
  display: block;
  width:max-content;
  max-width: 100%;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}
#Evaluation_Print_Button{
  display: block;
  width:max-content;
  max-width: 100%;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  margin-top: 10px;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}
#Evaluaion_pdf_iframe_wrapper
{
  height: calc(100% - 100px);
  max-width: 1000px;
}

#Evaluaion_NewEvaluation_Main_Div {
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;   
    position: relative;
    overflow: hidden;
}

.upper-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    width: 60%;
    color: white;
    font-family: Lato,sans-serif;
    padding-bottom: 40px;
}

.listing {
    width: 60%;
    color: white;
    font-size: 30px;
    font-family: Lato,sans-serif;
}

.listing li
{
    padding-bottom:20px;
}

.btn-box-parent {
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.btn-container {
    height: 10vh;
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.btn-layer1 {
    flex-grow: 1;
    position: relative;
}

.btn-layer2 {
    flex-grow: 1;
}

#Evaluaion_RequiredReminder_Next_Button_Div2 {
  display: block;
  position: absolute;
  bottom:15px;
  left:5px;
}

#Evaluaion_RequiredReminder_Next_Button_Div1 {
    display: block;
    position: absolute;
    bottom:15px;
    right:5px;
}

/* Re-use from newEvaluation.css */
#Evaluaion_RequiredReminder_Next_Button {
  display: block;
  width:auto;
  min-width: 300px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and ( max-width: 800px ) {
    #Evaluaion_RequiredReminder_Next_Button {
        min-width: 200px;
    }
}

@media screen and ( max-width : 500px ) {
    #Evaluaion_RequiredReminder_Next_Button {
        min-width: 100px;
    }
}
.chart-head-1
{
    position:absolute;
    top:60px;
    left:85px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.chart-head-2
{
    position:absolute;
    top:60px;
    left:340px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.chart-head-3
{
    position:absolute;
    top:60px;
    left:593px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.chart-head-4
{
    position:absolute;
    top:60px;
    left:840px;
    font-size: 20px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
}

.chart-notation-text
{
    color: rgb(0, 0, 0);
    font-size: 14px;
    font-weight: bold;
    position: absolute;
    z-index: 2;
    left: 106.3px;
    text-align: center;
    width: 49px;
}

.chart-notation-line
{
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    width: 1px;
    background: rgb(34, 30, 32);
    left: 130px;
    height: 10px;
}

.chart-notation-dot
{
    position: absolute;
    z-index: 2;
    border-radius: 50%;
    width: 11px;
    background: rgb(34, 30, 32);
    left: 125px;
    height: 11px;
}

.chart-joint-outer-wrapper
{
    display: block;
    white-space: nowrap;
    position: relative;
    margin-top: 30px;
}

.chart-joint-inner-wrapper
{
    border: 1px solid black;
    margin-left: 170px;
    display: inline-block;
    width: fit-content;
    margin-right: 20px;
    background: white;
    height: 350px;
    padding-bottom: 5px;
}

.chart-joint-outer-text
{
    display: inline-block;
    position: absolute;
    top: 170px;
    left: 30px;
    font-size: 24px;
}

.Chart-Joint-Name-Header-Wrapper
{
    display: inline-block;
}

.Chart-Joint-Name-Header
{
    width: 130px;
    text-align: center;
    font-size: 23px;
    font-weight: bold;
}



#Evaluaion_Chart_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    overflow: auto;
    position: relative;
}

#Evaluaion_Chart_Content_Wrapper
{
  float:left;
  margin-left:15%;
  width:80%;
  min-width: 1200px;
  /* min-height:100vh; */
  /* line-height: 100vh; */
  /* vertical-align: bottom; */
  /* display: flex; */
  /* justify-content: center; */
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top:50%;
  transform: translateY(-50%);
  /* margin-top: calc(100px)   */
  /* justify-content: center;
  align-items: center; */
}

@media only screen and (max-width:1400px)
{
  #Evaluaion_Chart_Content_Wrapper
  {
    width:90%;
    margin-left:7%;

  }
}

#Evaluaion_MultiChart_Content
{
  max-height: calc(100vh - 260px);
  overflow: auto;
}

.custom-scrollbarv1::-webkit-scrollbar {
  height: 12px;
  width: 12px;
  background: #68B2D7;
}

.custom-scrollbarv1::-webkit-scrollbar-track {
  background: #f1f1f1;
  width: 200px;
  height: 8px;
  border-radius: 5px;
}

.custom-scrollbarv1::-webkit-scrollbar-thumb {
  background: #cacaca;
  border-radius: 5px;
  cursor: pointer;
}

.custom-scrollbarv1::-webkit-scrollbar-thumb:hover{
  background: #8a8989;
}


#Evaluaion_Chart_Text_Div
{
    margin-bottom: 15px;
    font-size: 20px;
    line-height: 28px;

    margin-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
  
  /* vertical-align: middle; 
  display: table-row;  */
}

#Evaluaion_Chart_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
}

#Evaluaion_Chart_Heading2_Div
{
    font-size: 32px;
   
    font-family: 'Lato', sans-serif;
    color: #fff;
    box-sizing: border-box;

    margin-top: 0px;
    margin-bottom: 5px;
    line-height: 48px;
    font-weight: 700;
  

  /* vertical-align: middle; 
  display: table-row; */
}

.arrow-text-span
{
  color:#231F20;
  position: absolute;
  top:210px;
  left:5px;
  transform:rotate(270deg) translateY(-86px);
  font-family:Lato;
  font: weight 700;
  font-size:17px;
  width:196px;
}

#arrow-div
{
  margin-left:10px;
}

#Evaluaion_Chart_Footer_Div
{
  width: 1060px;
  background: #fff;
  text-align: center;
}

#Evaluaion_Chart_Footer_Image
{
  width: 1060px;
  background: #fff;
  text-align: center;
}


#Evaluaion_MultiChart_Content_Wrapper
{
  float:left;
  /* margin-left:15%; */
  width:max-content;
  min-width: 1200px;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  top:50%;
  left: 50%;
  transform: translate(-50%,-50%);

}

@media only screen and (max-height:1140px)
{
  #Evaluaion_MultiChart_Content_Wrapper
  {
    height:calc(100% - 50px);
    margin-top: 100px;
    position: absolute;
    top:0;
    left: 50%;
    transform: translate(-50%,0%);
  }
}

@media only screen and (max-height:1400px)
{
  #Evaluaion_MultiChart_Content_Wrapper
  {
    height: calc(100% - 50px);
    /* margin-left: 100px; */
    margin-top: 50px;
    position: absolute;
    top:0;
    left: 50%;
    transform: translate(-50%,0%);
    
  }
}
#Evaluaion_NewEvaluation_Back_Button_Div1{
  display: block;
  position: absolute;
  left:20px;
}
#Evaluaion_Forms_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_Forms_Content1_Wrapper
{
 
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Forms_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 30px;
}

#Evaluaion_Forms_Box1_Div
{
    min-width: 400px;
    width:calc(100% - 22px);
    max-width: 700px;
    padding:10px 10px;
    border-radius: 5px;
    border:1px solid #fff;
    height:220px;
}

.Evaluaion_Forms_Box1_Content1
{
    width:7%;
    background:#BBBBBB;
    height:50px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
    padding:25px 3%;
     
}

.Evaluaion_Forms_Box1_Content1_Image
{
    display: block;
    width: 50px;
}

.Evaluaion_Forms_Box1_Content2
{
    width:calc(80% - 20px);
    height:100px;
    line-height: 100px;
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;

}

.Evaluaion_Forms_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 32px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}


.Evaluaion_Forms_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%; */
  /* height:100%; */
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_Forms_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 11px;
  margin-top:10px;
}

#Evaluaion_Forms_Next_Button_Div
{
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom:10px;
  right:10px;
}

#Evaluaion_Forms_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}


#Evaluaion_PatientReport_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  line-height: 1.5;
}
#Evaluaion_NewEvaluation_Back_Button_Div1{
  display: block;
  position: absolute;
  bottom:20px;
  left:200px;
}
#Evaluaion_PatientReport_Content_Wrapper {
  float: left;
  margin-left: 15%;
  width: 75%;
  min-width: 400px;
  max-width: 900px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_PatientReport_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
}

.Evaluaion_PatientReport_Text1 {
  font-size: 22px;
  color: white;
}

.Evaluaion_PatientReport_Text2 {
  font-size: 22px;
  color: white;
  margin-top: 15px;
}

.Evaluaion_PatientReport_SubHead1 {
  font-size: 28px;
  font-weight: bold;
}

.Evaluaion_PatientReport_SubHead2 {
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
}

.Evaluaion_PatientReport_Question_Div {
  display: block;
  margin-top: 15px;
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
}

.Evaluaion_PatientReport_Answer1_Div {
  padding-left: 30px;
}

.Evaluaion_PatientReport_Answer1_Option {
  font-size: 18px;
  line-height: 20px;
  vertical-align: text-bottom;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.Evaluaion_PatientReport_Answer2_Option_Head {
  font-size: 18px;
  line-height: 20px;
  vertical-align: text-bottom;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.Evaluaion_PatientReport_Answer2_Option_Name {
  padding-top: 0px;
  margin-top: 0px;
  line-height: 34px;
  vertical-align: text-top;
  font-family: "Lato", sans-serif;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 16px;
}

.Evaluaion_PatientReport_Answer2_Option {
  text-align: center;
}

.Evaluaion_PatientReport_Answer2_Option_Radio {
  color: white !important;
  padding: 5px !important;
  border-bottom: 1px solid #fff !important ;
}

#Evaluaion_PatientReport_Next_Button_Div {
  display: block;
  margin-top: 10%;
  margin-right: 5%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  
}

#Evaluaion_PatientReport_Back_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  left: 70px;
}

#Evaluaion_PatientReport_Next_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif;
  text-transform: none;
}

#Evaluaion_PatientReport_Next_New_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  left: 70px;
  border: 3px solid red;
}

.red-emphasis {
  font-family: Lato, sans-serif;
  color: #7b1414;
  font-weight: 700;
}

.new-or-exis-back-button-div
{
    position: absolute;
    bottom :20px;
    width:250px;
    left:120px;
}
 #Medent_Welcome_Next_Button_Div {
    display: block;
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    margin-bottom: 0px;
  }

  .btn-layer1 {
    flex-grow: 1;
  }

  .btn-layer2 {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}
  
  @media only screen and (min-height: 700px) {
    #Medent_Welcome_Next_Button_Div {
      margin-top: 60px;
    }
  }
  
  @media only screen and (min-height: 750px) {
    #Medent_Welcome_Next_Button_Div {
      margin-top: 80px;
    }
  }
  
  @media only screen and (min-height: 800px) {
    #Medent_Welcome_Next_Button_Div {
      margin-top: 100px;
    }
  }
  
  #Medent_Welcome_Next_Button {
    display: block;
    width: auto;
    min-width: 300px;
    padding: 15px 25px;
    float: left;
    font-size: 28px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
  }

  @media screen and ( max-width: 800px ) {
    #Medent_Welcome_Next_Button {
        min-width: 200px;
    }
}

@media screen and ( max-width : 500px ) {
    #Medent_Welcome_Next_Button {
        min-width: 100px;
    }
}

#box_1 {
    height: 100vh;
}

.footer-header {
  margin-bottom: 80px !important;
}
#Medent-Form-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    line-height: normal;
}

* {
    font-family: Lato, sans-serif;
}

#Medent-Form-box_1 {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Medent-Form-heading {
    color: white;
    padding-left: 18px;
    font-size: 30px;
}

#Medent-Form-header1 , #Medent-Form-header2 , #Medent-Form-header3 , #Medent-Form-header4 {
    margin-bottom: 10px;
}

#Medent-Form-div_1 , #Medent-Form-div_2 , #Medent-Form-div_3 , #Medent-Form-div_4 {
    color: white;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 25px;
}

.Medent-Form-Same {
    margin-left: 30px !important;
}

#Medent-Form-handler1 {
    margin-left: 70px;
    margin-top: 100px;
}

#Medent-Form-handler2 {
    margin-left: 70px;
}

#Medent-Form-handler3 {
    margin-left: 40px;
}

#Medent-Form-input-field1 , #Medent-Form-input-field2 , #Medent-Form-input-field3 {
    width: 120px;
    font-family: Lato, sans-serif;
}

#Medent-Form-input-field2 , #Medent-Form-input-field3 {
    margin-left: 20px;
}

#Medent-Form-last2 {
    margin-left: 154px !important;
}

#Medent-Form-last3 {
    margin-left: 43px !important;
}

#Evaluaion_RequiredReminder_Next_Button_Div {
    height: 15vh;
}

.Medent-Form-btn-layer1 {
    flex-grow: 1;
}

.Medent-Form-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

#Evaluaion_MedentForm_Next_Button {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
  }

#Evaluaion_MedentForm_Next_Button_1 {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
}

  @media screen and ( max-width: 800px ) {
    #Evaluaion_RequiredReminder_Next_Button , #Evaluaion_RequiredReminder_Next_Button_1 {
        min-width: 200px;
        font-size: 20px;
    }
}

@media screen and ( max-width : 500px ) {
    #Evaluaion_RequiredReminder_Next_Button , #Evaluaion_RequiredReminder_Next_Button_1 {
        min-width: 100px;
        font-size: 24px;
    }
}

#Medent-Form-Wrapper {
    margin: 0px 50px 0px 50px !important;
}
#Pro-gate-main-box {
    background-image: linear-gradient(
        119deg,
        #3023ae,
        #53a0fd 47%,
        #6eb6cc 81%,
        #b4ec51
      );
      min-height: 100vh;
      position: relative;
      overflow: hidden;
      line-height: normal;
}

#Pro-gate-first-box {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Pro-gate-second-box {
    height: 10vh;
}

#Pro-gate-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 30px;
    margin-bottom: 40px;
}

#Pro-gate-yes-btn , #Pro-gate-no-btn {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
}

#Pro-gate-back-btn {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 50px;   
}
#Evaluation-PRO-Required-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#Evaluation-PRO-Required-box_1 {
    height: 89vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Evaluation-PRO-Required-box_2 {
    height: 10vh;
}

#Evaluation-PRO-Required-heading {
    color: white;
    font-family: Lato, sans-serif;
    margin-bottom: 20px;
    font-size: 30px;
    text-align: center;
}

.Evaluation-PRO-Required-seconds_last_box {
    border: 3px solid white;
    width: 50%;
    font-size: 25px;
    color: white;
    text-align: center;
    margin-top: 10px;
    font-family: Lato, sans-serif;
    padding: 5px;
}

#Evaluation-PRO-Required_Next_Button_Div {
    display: block;
    display: flex;
    flex-direction: row;
  }

  #Evaluation-PRO-Required_Next_Button_Div1 {
    display: block;
    margin-bottom: 20px;
}

#Evaluation-PRO-Required_Next_Button {
    display: block;
    min-width: 300px;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
  }

  .Evaluation-PRO-Required_Next_Button_Final {
      min-width:  200px !important;
  }

  #Evaluation-PRO-Required_Next_Button1 {
    display: block;
    width: auto;
    padding: 10px 20px;
    font-size: 22px;
    line-height: 32px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    text-transform: none;
  }

.Evaluation-PRO-Required-btn-layer1 {
    flex-grow: 1;
    margin-left: 45px;
}

.Evaluation-PRO-Required-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
  margin-right: 45px;
}

  @media screen and ( max-width: 800px ) {
    #Tutorials_Welcome_Next_Button {
        min-width: 200px;
        font-size: 20px;
    }
}

@media screen and ( max-width : 500px ) {
    #Tutorials_Welcome_Next_Button {
        min-width: 100px;
        font-size: 24px;
    }
}
/* CRM Screen */

.RCPE-Welcome-for-screen-4-container {
    display: flex;
    flex-direction: column;
}

#RCPE-Welcome-for-screen-4-box1 {
    width: 100%;
}

#RCPE-Welcome-for-screen-4-care-grid {
    height: auto;
    float: right;
    width: 50%;
    margin-right: 90px;
    max-width: 720px;
}

@media screen and ( max-width: 800px ) {
    #RCPE-Welcome-for-screen-4-care-grid {
        width: 75%;
        height: auto;
        margin-right: 60px;
        margin-top: 20px;
    }
}

@media screen and ( max-width : 500px ) {
    #RCPE-Welcome-for-screen-4-care-grid {
        width: 70%;
        height: auto;
    }
}

#RCPE-Welcome-for-screen-4-heading-1 {
    color: white;
    padding: 20px;
    font-family: Lato, sans-serif;
    padding-top: 30px;
    padding-left: 50px;
}

#RCPE-Welcome-for-screen-4-heading-2 {
    font-family: Lato, sans-serif;
    color: white;
    padding: 20px;
    padding-left: 50px;
}
#JN1-Image {
    width: 200px;
    height: 200px;
    margin: auto;
}
* {
    font-family: Lato, sans-serif;
}
 
#RCPNO-outer-box-2
{    
    display: flex;
    justify-content: center;
    align-items: center;
}

#RCPNO-inner-box-1 {
    flex-grow: 1;
    place-self: flex-start;
}

#RCPNO-inner-box-2 {
    flex-grow: 1;
    place-self: flex-start;

}

#RCPNO-heading {
    color: white;
    width: 100%;
}

.RCPNO-List {
    list-style-type: none;
}

.RCPNO-List li {
    margin: 30px 0;
}

#RCPNO-Image {
    height: auto;
    width: 300px;
    margin-left: 40px;
}

.RCPNO-2nd-List {
}

@media screen and ( max-width: 800px ) {
    #RCPNO-field1 , #RCPNO-field2 , #RCPNO-field3 , #RCPNO-field4 , #RCPNO-field5 , #RCPNO-field6 {
        font-size: 18px;
    }

    #RCPNO-heading {
        padding-top: 10px;
        margin-left: -30%;
    }

    .RCPNO-2nd-List {
        margin-top: 210px !important;
    }

    #RCPNO-Image {
        height: 150px;
        width: 150px;
    }
}
* {
  font-family: Lato, sans-serif;
}

#RCPNOI-form-box-1 {
  display: flex;
  flex-direction: row;
}

.RCPNOI-heading-1-wrapper {
  margin-top: 30px;
}

.RCPNOI-heading-1 {
  display: inline-block;
  color: white;
  font-size: 20px;
  text-decoration: underline;
  vertical-align: baseline;
}

.RCPNOI-heading-1-button-wrapper {
  display: inline-block;
  margin-left: 40px;
  vertical-align: middle;
}

.RCPNOI-heading-1-button-2-wrapper {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.RCPNOI-heading-1-button {
  font-family: Lato, sans-serif !important;
  font-size: 20px !important;
  text-transform: none !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.RCPNOI-heading-2 {
  margin-top: 30px;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

/* changethere */
#RCPNOI-heading {
  font-family: Lato, sans-serif;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 1.5;
}

.RCPNOI-form-field {
  font-family: Lato, sans-serif;
  color: #fff; 
  font-size: 25px;
  line-height: 1.5;
}

/* Styling for checkbox */

.RCPNOI-styled-checkbox {
  position: absolute;
  opacity: 0;
}
.RCPNOI-styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.RCPNOI-styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid white;
}

.RCPNOI-styled-checkbox:checked + label:before {
  background: darkblue;
}

.RCPNOI-styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.RCPNOI-styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.RCPNOI-styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.RCPNOI-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.RCPNOI-unstyled li {
  margin: 10px 0;
  /* border: 1px solid white; */
}

.RCPNOI-centered {
  width: 300px;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .RCPNOI-form-field {
    font-size: 18px;
  }

  #RCPNOI-heading {
    margin-top: 50px;
    padding-left: 10px;
  }

  .RCPNOI-unstyled li {
    padding-left: 10px;
  }
}

.RCPNOI-square-box {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  display: inline-block;
}

.RCPNOI-text-field {
  display: inline-block;
  line-height: 20px;
  vertical-align: top;
  margin-left: 10px;
  color: white;
  border-bottom: 1px solid white;
  font-size: 22px;
}

#RKOCI-Image {
  height: 200px;
  width: 200px;
  margin-top: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 800px) {
  #RKOCI-Image {
    width: 200px;
    height: 200px;
    margin-top: 110px;
  }

  #RKOCI-Image {
    font-size: 16px;
  }
}
#RCPNOI-container-1 {
  width: 99%;
}
#RCPNOI-form-box-1 {
}
#RCPNOI-form {
  width: -webkit-fill-available;
}

#JointNoi3-inner-box-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}

#JN4-Image {
    height: 250px;
    margin-top: 20px;
    transform: translateX(-100px);
    width: auto;
    margin-left: 20px;
}
/* JointSummary Screen */

#JointSummary-main-box {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

* {
    font-family: Lato, sans-serif;;
}

#JointSummary-heading {
    color: white;
    text-align: left;
    width: 100%;
    font-size: calc(1rem + 20px);
    text-align: center;

}

#JointSummary-box-1 {
    /* border: 1px solid yellow; */
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
}

#JointSummary-inner-box-1 {
    /* border: 1px solid yellow; */
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    align-self: center;
    text-align: left;
}

#JointSummary-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#JointSummary-part-1 {
    background-color: #B3D89B;
    border: 1px solid white;
    height: 140px;
    width: 152px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
}

#JointSummary-part-4 {
    background-color: #B3D89B;
    border: 1px solid white;
    border-radius: 100px;
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
}

#JointSummary-part-last-box {
    margin-left: 20px;
}

#JointSummary-part-1-heading {
    color: white;
    /* border: 1px solid black; */
    width: 152px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: lighter;
    margin-top: 10px;
}

#JointSummary-part-2-heading {
    color: white;
    /* border: 1px solid black; */
    width: 140px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: lighter;
    margin-top: 10px;
}

#JointSummary-row-2 {
    background-color: #B3D89B;
    /* border: 1px solid white; */
    height: 140px;
    width: 140px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

#JointSummary-Image {
    height: 140px;
    width: 140px;
}

#JointSummary-btn-2 {
    /* width: 150px; */
    margin-right: 15px;
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-transform: none;
    border-radius: 25px;
    background-color: #b4ec51;
    height: 40px;
    min-width: 150px;
}

.Joint_Summary_Box_Search_Box
{
   position:absolute;
   border-top-right-radius:3px; 
   border-bottom-right-radius: 0px;
   bottom:0px;
   left:0px;
   padding:5px 5px 7px;
   background:#B4EC51;
   cursor: pointer;
}

#Joint_Summary_Heading_2
{
    position: relative;
    left: 0px;
    font-size: 3rem;
    color: white;
}

#JointSummary-recommendation-div
{
    /* width: 100%; */
    display: inline-block;
    padding: 5px 10px;
    background-color: white;
    color: black;
}
/* Joint Treatment Screen */

#Joint-Treatment-Options-box-2 {
    display: flex;
    flex-direction: row;
    /* border: 1px solid black; */
}

#Joint-Treatment-Options-small-box-1 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    /* border: 1px solid black; */
    text-align: center;
    width: 170px;
    margin-left: 35px;
    margin-top: 15px;
}

#Joint-Treatment-Options-small-box-2 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    border: 1px solid black;
    text-align: center;
    width: 200px;
    margin-top: 10px;
    margin-left: 20px;
}

#Joint-Treatment-Options-header {
    color: white;
    margin-top: 30px;
}
.JPR-footer-box1 , .JPR-footer-box2 , .JPR-footer-box3 {
    width: 160px;
}

.JPR-footer-box2 , .JPR-footer-box3 {
    border: none !important;
}

.JPR-header {
    margin-left: 200px;
}
#LearnAbout-box-1
{
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


#LearnAbout-inner-box-1
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-right: 10px;
    width: 100%;
}

.LearnAbout-unstyled {
    padding: 0px;
    list-style-type: none;
    border: 4px white solid;
    padding-left: 10px;
    width: 100%;
    min-width: 50vw;
    height: 77%;
    max-width: calc(100% - 20px);
}

#LearnAbout-btn-2
{
    font-family: Lato, sans-serif !important;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    position: absolute;
    right: 10px;
}

    .SurgeryInfo-btn-1 {
        /* width: 150px; */
        margin-right: 15px !important;
        font-family: Lato, sans-serif;
        font-size: 20px !important;
        text-transform: none !important;
        border-radius: 25px !important;
        background-color: #b4ec51 !important;
        min-width: 150px !important;
        max-width: 250px !important;
    }
#Knee-Replacement {
    color: white;
    margin-top: 10px;
    width: 100%;
}

#Knee-Replacement-small-box-1 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    border: 1px solid black;
    text-align: center;
    margin-top: 20px;
}

#Knee-Replacement-small-box-2 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    /* border: 1px solid black; */
    text-align: center;
    margin-top: 15px;
}

#Knee-Replacement-heading {
    color: white;
    width: 100%;
}

@media screen and ( max-width : 800px ) {
    #Knee-Replacement-heading {
        padding-left: 13%;
    }

    #Knee-Replacement {
        padding-left: 13%;
    }
}

.Knee-Replacement-Recommendation-Box
{
    text-align: center;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
/* Welcome Screen */

#RCPE-Welcome-main-container {
    background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  position: relative;
  overflow: hidden;   
}

#all-content-wrapper
{
  width: calc(100% - 300px);
  margin-left: 150px;
}

@media only screen and (max-width:980px)
{
  #all-content-wrapper
  {
    width: calc(100% - 120px);
    margin-left: 100px;
    margin-right: 20px;
  }
}

#RCPE-Welcome-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: row;
}

#RCPE-Welcome-box-2 {
    height: 10vh;
    display: flex;
    flex-direction: row;
}

#RCPE-Welcome-inner-box-1 {
    flex-grow: 2;
    padding: 30px;
}

#RCPE-Welcome-inner-box-2 {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

#RCPE-Welcome-Heading {
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 65px;
    line-height: 75px;
    margin-bottom: 10px;
    display: block;
  }

  #RCPE_Welcome_Neon_Line {
    width: 300px;
    height: 3px;
    margin-top: 35px;
    margin-bottom: 50px;
    background-color: #b4ec51;
    box-sizing: border-box;
  }

  #RCPE_Welcome_Heading2 {
    margin-bottom: 15px;
    font-size: 34px;
    line-height: 48px;
    font-weight: 700;
    font-family: Lato, sans-serif;
    color: #fff;
    box-sizing: border-box;
  }

  #RCPE_Welcome_Text_Div {
    font-size: 30px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 40px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
  }

  #RCPE_Welcome_Text_Div2 {
    font-size: 25px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
  }

#bone-image {
    height: 80vh;
    margin-top: 120px;
}

#RCPE-Welcome-footer-div1 {
    flex-grow: 1;
    position: relative;
}

#RCPE-Welcome-footer-div2 {
    flex-grow: 2;
    display: flex;
    justify-content: center;
}

#RCPE-Welcome-footer-div3 {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
}

#RCPE-Welcome-btn-1 , #RCPE-Welcome-btn-2 , #RCPE-Welcome-btn-3 {
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-transform: none;
    border-radius: 25px;
    background-color: #b4ec51;
    height: 40px;
}

#RCPE-Welcome-btn-1 {
    width: 150px;
    margin-left: 15px;
}

#RCPE-Welcome-btn-3 {
    width: 150px;
    margin-right: 15px;
}

@media screen and ( max-width: 800px ) {
  #RCPE-Welcome-Heading {
    font-size: 40px;
    margin-bottom: 5px;
  }

  #RCPE_Welcome_Heading2 {
    font-size: 30px;
    margin-bottom: 5px;
  }

  #RCPE-Welcome-btn-1 , #RCPE-Welcome-btn-3 {
    font-size: 17px;
    width: 100px;
  }

  #RCPE-Welcome-btn-2 {
    font-size: 17px;
  }
}
.LearnMore-heading-1
{
    color:white;
    /* padding-left: 50px; */
    font-size: 24px;
    font-family: Lato, sans-serif;
    margin-top: 10px;

}

.LearnMore-heading-1-span
{
    font-weight: 600;
}

#LearnMore-row-1
{
    padding-left: 70px;
}


.LearnMoreGeneral-btn-1 {
    /* width: 150px; */
    margin-top:20px !important;
    font-family: Lato, sans-serif;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    min-width: 150px !important;
    max-width: auto !important;
}
.chart-image-wrapper
{
    width:900px;
    height:1100px;
    position: relative;
    top:0px;
    left:0px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px;

}

.chart-pdf-header
{
    width: calc(100% - 60px);
    margin:auto;   
    height:60px;
    background-image:linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    color: white;
    font-size: 30px;
    line-height: 50px;
    padding-left:20px;
    font-weight: bold;
}

.chart-pdf-patient-header
{   
    width: calc(100% - 60px);
    border-bottom:1px solid black;
    font-size: 26px;
    /* padding-left:20px; */
    font-weight: bold;
    margin:auto;
    height: 40px;
    margin-top:20px;
    position: relative;
}

.chart-patient-header-middle
{
    position: absolute;
    left:calc(50% - 110px);
    display: inline-block
}


.chart-patient-header-right
{
    position: absolute;
    right:calc(0px);
    display: inline-block
}

.chart-pdf-image-footer-wrapper
{
    margin-top: 50px;
    width: calc(100%);
    margin-left: 150px;
    transform: translateY(-233px);
}

.chart-pdf-image-footer-1
{
    transform: translateX(-35px)
}

#arrow-image-div
{
   position: relative;
   top:100px; 
    left: 27px;
    /* transform: sca
   /* transform: scale(1.5) */
}

#arrow-image-tag
{
    width: 30px;
    height: 200px;
}

.arrow-text-span-image
{
    color: #231F20;
    position: absolute;
    top: 215px;
    left: 5px;
    transform: rotate(270deg) translateY(-86px);
    font-family: Lato;
    font-weight: 700;
    font-size: 17px;
    width: 196px;
}
#GetImage_Report_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    margin: auto;
    position: relative;
    overflow: hidden;
    z-index: 2;
    
}

.ImageReportMainDiv
{
    width:800px;
    margin:auto;
    padding:10px;
    padding-top:40px;
    padding-left:20px;
    padding-right:20px;
    position: relative;
    /* background: #53a0fd; */
}


.GetImage_Report_Box_Text1
{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #000000;
    font-family: 'Lato',sans-serif;
}

.GetImage_Report_Box_Text2
{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #000000;
    font-family: 'Lato',sans-serif;
}



.GetImage_Report_Box_Priority
{
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    color: #fff;
    font-family: 'Lato',sans-serif;
}

.GetImage_Report_Box_Priority_Box
{
    border:1px solid #fff;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: #fff;
    font-family: 'Lato',sans-serif;
    padding: 10px 20px;
    
    /* display:block */
}

.GetImage_Report_Box_Text3
{
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    font-family: 'Lato',sans-serif;
}

.bold
{
    font-weight: bold;
}


.GetImage_Report_Box_Text4
{   
    height:100px;
    width:121px;
    /* background: #3023ae; */
    /* padding-top: 5px; */
    transform: rotate(-45deg) translateY(-27px) translateX(80px);
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    font-family: 'Lato',sans-serif;
    word-wrap: nowrap;
}

.GetImage_Report_Box_Text5
{
    
    text-align: center;
    font-family: Lato, sans-serif;
    color: #000000;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 400;
}

.GetImage_Report_Box_Selected_Box
{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}



.GetImage_Report_Box_Button_Div
{
  
  width:calc(100% - 10px);
  padding-left:5px;
  height:40px;
  margin: auto;
  position: absolute;
  top:7px;
}

.GetImage_Report_Box_Button
{
    display: block !important;
    width:100% !important;
    height:100% !important;
    margin: auto !important;
    /* padding: 15px 0px; */
    font-size: 18px !important;
    line-height: 18px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.GetImage_Report_Box_Search_Box
{
   position:absolute;
   border-radius:10px; 
   bottom:10px;
   left:10px;
   padding:10px 10px 7px;
   background:#B4EC51;
}

.GetImage_Report_Box_Note_Box
{
    position:absolute;
    border-radius:5px; 
    bottom:0px;
    right:0px;
    padding:10px 10px 2px;
    background:#B4EC51;
 }

 #GetImage_Report_Next_Button_Div
{
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom:10px;
  right:10px;
}

#GetImage_Report_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

.xray-report-card-header
{
    width:calc(100% - 25px);
    /* margin:auto; */
    min-height:58px;
    background-image:linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    color: white;
    font-size: 30px;
    padding-left:20px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 50px;    
}

.report-image-patient-info-div
{
    display: block;
    height:300px
}

.report-image-patient-info-box
{
    display:block;
    margin:10px;
    
}

.report-image-patient-info-left
{
   float: left;
   /* max-width: 300px; */
   /* background: #b4ec51  ; */
}

.report-image-patient-info-right
{
   float: right;
}

.report-image-patient-info-right-inner-text
{
    width:180px;
    padding-left: 20px;
    display:inline-block;
}

.pdf-page-number-header
{
    position: absolute;
    right:25px;
    transform : translateY(-25px);
    margin-bottom: 10px;
    font-weight: bold;
}

.margin-right-10
{
    margin-right: 10px;
}

.GetImage_Report_AI_BG
{
    background-repeat: no-repeat !important;
    background-size: contain !important;
    color: black;
}

.GetImage_Report_PREDICTION_SPAN
{
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: black;


}
.Joint-Rcp-Image-Header-Hr
{
    
}

.Joint-Rcp-Image-Inner-Header
{
    font-size: 30px;
    margin: 40px;
    font-weight: bold;

}
.Joint-Rcp-Image-Box-Wrapper
{
    margin:auto;
    width: fit-content;
    border: 1px solid black;
    padding: 20px 40px;
}


.Joint-Rcp-Image-Compartment-Box-Wrapper
{
    display: inline-block;
    width:160px;
    text-align: center;
}

.Joint-Rcp-Image-Compartment-Head
{
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.Joint-Rcp-Image-Compartment-Box
{
    width:100%;
    height:160px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: 600;
}

.Joint-Rcp-Image-Score-Box-Wrapper
{
    display: inline-block;
    width:160px;
    margin-left: 20px;
    text-align: center;
}


.Joint-Rcp-Image-Score-Box
{
    width: 100%;
    height: 160px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid black;
}

.Centered
{
    vertical-align: middle;
    display: inline-block;
}

.Centerer
{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}


.Joint-Rcp-Image-Compartment-Hr
{
    width: calc(100% + 80px);
    transform: translateX(-40px);
    background-color: black;
    height: 1px;
    margin:40px 0px 40px;
}

.Joint-Rcp-Image-Compartment-Path
{
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

.margin-top-20
{
    margin-top: 40px;
}

.Joint-Rcp-Image-OI-List-Wrapper
{
    margin-top:20px
}

.Joint-Rcp-Image-OI-List-Left
{
    display: inline-block;
    width: 50%;
    vertical-align: top;

}

.Joint-Rcp-Image-OI-List-Right
{
    display: inline-block;
    width: 50%;
    vertical-align: top;

}

.Joint-Rcp-Image-OI-List-Item
{
    display: block;
    width: 100%;
    margin: 30px 0px;
}

.Joint-Rcp-Image-OI-List-Box
{
    width:20px;
    height: 20px;
    border:2px solid black;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.Joint-Rcp-Image-OI-List-Text
{
    display: inline-block;
    width: calc(100% - 34px);
    font-size: 24px;
    font-weight: 600;
}

.image-compartment-label-wrapper
{
    position: absolute;
    height: 23px;
    width: 125px;
    background:blue;
    text-align: center;
    justify-content: center;
    padding-top: 3px;
}

.image-compartment-label-wrapper-1 /* right label 1*/ /*kneecap*/
{
    bottom:271px !important;
    left:102px !important;
}

.image-compartment-label-wrapper-2 /* right label 2*/ /*Lateral*/
{
    bottom:168px !important;
    left:104px !important;
    height: 24px !important;
}


.image-compartment-label-wrapper-3 /* right label 3*/ /*Medial*/
{
    bottom:182px !important;
    left:571px !important;
    height: 24px !important;
}


.image-compartment-label-wrapper-4 /* left label 1*/
{
    bottom:271px !important;
    left:565px !important;
}

.image-compartment-label-wrapper-5    /* left label 2*/
{
    bottom:182px;
    left:97px;
    height: 24px;
}

.image-compartment-label-wrapper-6 /* left label 3*/
{
    bottom:168px;
    left:566px;
    height: 24px;
}

.image-compartment-label
{
    font-size: 15px;
}


.Evaluation_Upload_Report_Images_Next_Button_Wrapper
{
    position: absolute;
    bottom: 30px;
    right: 30px;

}

.Evaluation_Upload_Report_Images_Next_Button
{
    font-weight: 600;
    color: white !important;
}

.Evaluation_Upload_Report_Images_Text
{
    position: absolute;
    top:50%;
    left:50%;
    transform: translate(-50%,-50%);
    color:white;
    font-size: 32px;
    font-weight: 600;
}
#noi-treatment-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:0px;
    left:800px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px;
}

#treatment-a-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:-4000px;
    left:800px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
}

#treatment-b-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:-0;
    left:0px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
    
}

#treatment-c-wrapper
{
    width:900px;
    height:1100px;
    position: relative;
    top:-0;
    left:0px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
}
.Page1B-Number
{
    position: absolute;
    right:30px;
    bottom:20px;
    color:rgb(99, 99, 99)
}

.xrayImage-pdf-image-head
{
    font-weight: 600;
    font-size: 20px;
}

.pdf-xrays-images-wrapper
{
    width:900px;
    height:1100px;
    background-color:white;
    position:absolute;
    top:-1600px;
    left:2000px;
    padding-top: 40px;
    padding-bottom: 20px;
}

#Report_Feedback_Welcome_Box1
{
    margin-top: 20px;
    height: calc(90vh - 20px);
}

#Report_Feedback_Heading_1
{
    background: white;
    color:#3941C1;
    font-size: 2rem;
    font-weight: bold;
    width: fit-content;
    padding:10px 50px;
    padding-right: 100px;
    margin-bottom: 20px;

}

#Report_Feedback_Heading_2
{
    color: white;
    font-size: 2rem;
    padding-left: 50px;
    margin-bottom: 20px;
}

#Report_Feedback_Div_2
{
    font-size: 25px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Report_Feedback_Buttons
{
    font-family: Lato, sans-serif;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    /* min-width: 150px !important; */
    width: 350px !important;
    font-weight: bold !important;
    margin-top: 20px !important;
}

#Report_Feedback_Chat_Wrapper
{
    width: 300px;
    height: 400px;
    max-height: 80vh;
    position: absolute;
    right:0px;
    outline: black;
    border:1px solid black;

}

#Report_Feedback_Chat_Head
{
    background: #b4ec51;
    color: black;
    height: 40px;
    width: 100%;
    text-align: center;
    justify-content: center;
    line-height: 40px;
    font-weight: bold;
}

#Report_Feedback_Chat_Bottom
{
    position: absolute;
    bottom: 0px;
    background: grey;
    height: 40px;
    width: 100%;
    padding: 10px;
}

#Report_Feedback_Chat_Type
{
    background: rgb(255,255,255,0.4);
    border-radius: 20px;
    padding: 10px;
    color: rgb(255,255,255,0.9);
    width:calc(100% - 100px);
    height: calc(100% - 20px);
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

#Report_Feedback_Chat_Send
{
    display: inline-block;
    background: #b4ec51;
    border-radius: 50%;
    vertical-align: middle;
    padding: 7px 7px;

}
      

#Evaluation_SinglePDF_Content_Wrapper {
    height: calc(100vh - 80px);
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-top: 80px;
}

#Evaluaion_PDF_Summary_Image
{
  text-align: center;
}


.Download_Single_Summaries_Div_Button {
    margin: auto !important;
    /* display: block !important; */
    width: max-content !important;
    max-width: calc(100% - 50px) !important;
    min-width: 200px !important;
    padding: 15px 25px !important;
    /* float: right; */
    font-size: 28px !important;
    line-height: 32px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: "Lato",sans-serif !important;
    text-transform: none !important;
    margin-bottom: 20px !important;
    display: block !important;
  }

  #Evaluaion_SinglePDF_Download_Button_Div
  {
    text-align: center;
  }

#Evaluaion_PDF_Summary_Head
{
    color: white;
    /* display: inline-flex; */
    font-size: 26px;
    font-weight: 200px;
    transform: translateY(0px);
    margin-top: 30px;
    text-align: center;
}
#Evaluaion_CurrentEvaluation_Heading1_Div {
  font-family: Lato,sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 50px;
  line-height: 40px;
  width: 910px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 80px;
}
#Evaluaion_CurrentEvaluation_Heading1_Div {
  font-family: Lato,sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 50px;
  line-height: 40px;
  width: 910px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 80px;
}

#Download_Single_Summaries_Div
{
  display: inline-block;
}

.Download_Single_Summaries_Div
{
  color: white;
  margin-right: 40px;
  display: inline-block;
  font-size: 24px;
  vertical-align: top;
}

.Download_Both_Summaries_Div
{
  color: white;
  margin-bottom: 40px;
  font-size: 24px;
}

.Summaries_Download_Buttons {
  font-family: Lato, sans-serif;
  font-size: 20px !important;
  text-transform: none !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  /* min-width: 150px !important; */
  width: 200px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
}
#Main_Drawer
{
    position: fixed;
    left:0px;
    width:70px;
    /* background: yellow; */
    min-height:100vh;
    z-index: 3;
}

#Main_Drawer_Copyright_Text
{
    
    position: absolute;
    bottom:30px;
    left:70px;

    transform-origin: 0% 100%;
    font-family: Lato, sans-serif;
    color: #fff;
    font-size: 11px;
    font-weight: 300;
  
    transform: rotate(270deg);
    ms-transform: rotate(270deg);
    height: 45px;
    width: 300px;

}

#Main_Drawer_Menu_Image_Div
{   position: absolute;
    right:20px;
    top: 10px;
    
    transform: rotateY(200deg)
}

#Main_Drawer_Menu_Image_Div:hover
{
    cursor: pointer;
}

#Main_Drawer_Menu_Image{
   
}

#Main_Drawer_Menu_Div
{
    transition: all 0.5s ease;
    height: 440px;
    background: #fff;
    position: absolute;
    width: 200px;
    left:0px;
    padding-top:5px;
    
    
}

.Main_Drawer_Menu_Div_Close
{
    top:-445px;
}

.Main_Drawer_Menu_Div_Open
{
    top:0px;
    /* left: 0px; */
}

#Main_Drawer_Menu_Close_Image
{
    position: absolute;
    right:10px;
    top:10px;
}

#Main_Drawer_Menu_Close_Image:hover
{
    cursor: pointer;
}

.Main_Drawer_Menu_Text
{
    border-bottom: 1px solid #b4ec51;
    text-align: center;
    padding-right:16px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-family: Lato, sans-serif;
    font-size: 18px;
    color:#222222;
    cursor: pointer;
}

#Main_Drawer_Menu_Text_Wrapper
{
    width:80%;
    margin-left:10%;
}
#Tutorials_SBS_Welcome_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_SBS_Welcome_Image_div {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 45%;
}

#Tutorials_SBS_Welcome_Image_Bone {
  /* max-height:calc(100vh - 40px);
  min-height: 100px;
  width:auto;
  height:auto; */
  width: 300px;
  margin-top: 50px;
}

#Tutorials_SBS_Welcome_Text_Wrapper {
  float: left;
  margin-left: 15%;
  width: 70%;
  min-width: 400px;
  max-width: 700px;
  height: 100vh;
  line-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Tutorials_SBS_Welcome_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Tutorials_SBS_Welcome_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 35px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_SBS_Welcome_Heading2_Div {
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Tutorials_SBS_Welcome_Text_Div {
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;

  /* vertical-align: middle; 
  display: table-row;  */
}
#Tutorials_SBS_Welcome_Next_Button_Div {
  display: block;
  margin-top: 20px;
}

@media only screen and (min-height: 700px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 60px;
  }
}

@media only screen and (min-height: 750px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 80px;
  }
}

@media only screen and (min-height: 800px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 100px;
  }
}

#Tutorials_SBS_Welcome_Next_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#SBSVideo_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

.sbs-video-wrapper {
  width: auto;
  max-width: calc(100vw - 150px);
  height: auto;
  max-height: calc(100vh - 200px);
}

#SBSVideo_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#SBSVideo_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 30px;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
}

#SBSVideo_Image_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: space-around;
  margin: auto;
  margin-top: 15px;
  margin-bottom: 15px;
  /* width: max-content;
  height: max-content; */
}

#SBSVideo_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
}
#SBSVideo_Image_Container img {
  width: 100%;
}

#SBSVideo_Next_Button_Div {
  display: block;
  margin-top: 10px;
  margin-right: 20px;
}

@media only screen and (max-width: 760px) {
  #SBSVideo_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#SBSVideo_Next_Button {
  float: right;
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Welcome_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Welcome_Image_div {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 45%;
}

#Tutorials_Welcome_Image_Bone {
  width: 300px;
  margin-top: 50px;
}

#Tutorials_Welcome_Text_Wrapper {
  float: left;
  margin-left: 15%;
  width: 70%;
  min-width: 400px;
  max-width: 700px;
  height: 100vh;
  line-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Tutorials_Welcome_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Tutorials_Welcome_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 35px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Welcome_Heading2_Div {
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Tutorials_Welcome_Text_Div {
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;

  /* vertical-align: middle; 
  display: table-row;  */
}
#Tutorials_Welcome_Next_Button_Div {
  display: block;
  margin-top: 20px;
}

@media only screen and (min-height: 700px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 60px;
  }
}

@media only screen and (min-height: 750px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 80px;
  }
}

@media only screen and (min-height: 800px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 100px;
  }
}

#Tutorials_Welcome_Next_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Overview_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Overview_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 74%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Tutorials_Overview_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Overview_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Overview_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Overview_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Overview_Image_Bone {
  width: 28vw;
  max-width: 250px;
  /* max-width: 400px;
  min-width: 250px; */
}

#Tutorials_Overview_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 3vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Overview_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Overview_Text_Div ul li {
  margin-left: 5%;
  margin-top: 5px;
  /* line-height: 3vw; */
  display: list-item;
  list-style: disc;
}

#Tutorials_Overview_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Overview_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Overview_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #Tutorials_Overview_Heading1_Div {
    font-size: 3.2vw;
  }

  #Tutorials_Overview_Text_Div {
    font-size: 2.3vw;
  }
  #Tutorials_Overview_Next_Button,
  #Tutorials_Overview_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Overview_Image_Bone {
    width: 50vw;
  }

  #Tutorials_Overview_Text_Div ul li {
    margin-left: 7%;
  }
}

#KDAA_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#KDAA_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#KDAA_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#KDAA_Text_Div,
#KDAA_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#KDAA_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#KDAA_Image_Heading {
  color: #fff;
  text-align: center;
}
#KDAA_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  width: 95%;
}

#KDAA_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#KDAA_Inner_Image_Container img {
  width: 100%;
}
#KDAA_Next_Button_Div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

#KDAA_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #KDAA_Heading1_Div {
    font-size: 3vw;
  }

  #KDAA_Text_Div,
  #KDAA_Image_Heading {
    font-size: 2vw;
  }
  #KDAA_Next_Button {
    font-size: 1.9vw;
  }
}

#KDAA2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#KDAA2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#KDAA2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#KDAA2_Text_Div,
#KDAA2_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#KDAA2_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#KDAA2_Image_Heading {
  color: #fff;
  text-align: center;
}
#KDAA2_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  width: 95%;
}

#KDAA2_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#KDAA2_Inner_Image_Container img {
  width: 100%;
}
#KDAA2_Next_Button_Div {
  display: block;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

#KDAA2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #KDAA2_Heading1_Div {
    font-size: 3vw;
  }

  #KDAA2_Text_Div,
  #KDAA2_Image_Heading {
    font-size: 2vw;
  }
  #KDAA2_Next_Button {
    font-size: 1.9vw;
  }
}

#COTK_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#COTK_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#COTK_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#COTK_Text_Div,
#COTK_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#COTK_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#COTK_Image_Container {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
}

#COTK_Inner_Image_Container img {
  width: 100%;
}

#COTK_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #COTK_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #COTK_Heading1_Div {
    font-size: 24px;
  }

  #COTK_Text_Div,
  #COTK_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #COTK_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #COTK_Heading1_Div {
    font-size: 26px;
  }

  #COTK_Text_Div,
  #COTK_Image_Heading {
    font-size: 16px;
  }
} */

#COTK_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #COTK_Heading1_Div {
    font-size: 2.8vw;
  }

  #COTK_Text_Div {
    font-size: 1.9vw;
  }
  #COTK_Next_Button {
    font-size: 1.9vw;
  }
}

#VDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#VDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXR_Text_Div,
#VDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#VDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXR_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#VDWXR_Inner_Image_Container img {
  width: 100%;
}
#VDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #VDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#VDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXR_Text_Div {
    font-size: 1.9vw;
  }
  #VDWXR_Next_Button {
    font-size: 1.9vw;
  }
}

#LATMALCOTKITX_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.gradient_div{
   height: 50px;
   display: flex;
   align-items: center;
    width: 100%;
    margin: 5px;
    /* background: linear-gradient(0.25turn, #3C78D8, #296fe0); */
}
.test_span{
    color: #FCB23C;
    font-weight: bold;
     font-size:2rem;
     margin-left: 10px;
}
#LATMALCOTKITX_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#LATMALCOTKITX_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#LATMALCOTKITX_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#LATMALCOTKITX_Buttons_wrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}

#LATMALCOTKITX_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

#LATMALCOTKITX_Pair_Buttons_wrapper Button {
  margin: 8px 0;
}

#LATMALCOTKITX_Button {
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  display: block;
  width: auto;
}

#LATMALCOTKITX_Image_Wrapper {
  border: 3px solid white;
  padding: 15px;
}

#LATMALCOTKITX_Image_Container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#LATMALCOTKITX_Image_Container img {
  width: 100%;
}

#LATMALCOTKITX_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #LATMALCOTKITX_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#LATMALCOTKITX_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LATMALCOTKITX_Heading1_Div {
    font-size: 2.8vw;
  }

  #LATMALCOTKITX_Text_Div {
    font-size: 1.9vw;
  }
  #LATMALCOTKITX_Next_Button {
    font-size: 1.9vw;
  }
}

.tutorial-Complete-Box {
  /* height: 100px; */
  /* width: 100px; */
  background-color: #b4ec51;
  color: white;
  font-size: 24px;
  padding: 10px;
  font-weight: 540;
  vertical-align: middle;
  /* display: inline-block; */
 
}

.tutorial-Complete-Box-tick{
  display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
}

#VDWXRTKC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 80vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXRTKC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#VDWXRTKC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXRTKC_Text_Div,
#VDWXRTKC_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXRTKC_Image_Wrapper {
  margin-top: 10px;
  border: 3px solid white;
  padding: 5px;
}

#VDWXRTKC_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXRTKC_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXRTKC_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  /* width: 48%; */
}

#VDWXRTKC_Inner_Image_Container img {
  width: 100%;
}
#VDWXRTKC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #VDWXRTKC_Content_Wrapper {
    padding: 0rem 0rem 0rem 0rem;
  }
}

#VDWXRTKC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXRTKC_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXRTKC_Text_Div,
  #VDWXRTKC_Image_Heading {
    font-size: 1.85vw;
  }
  #VDWXRTKC_Next_Button {
    font-size: 1.85vw;
  }
}

#COTK2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#COTK2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#COTK2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#COTK2_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#COTK2_Buttons_wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#COTK2_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

#COTK2_Pair_Buttons_wrapper Button {
  margin: 10px 0;
}

#COTK2_Button {
  display: block;
  width: auto;
  font-family: Lato, sans-serif;
  text-transform: none;
  min-width: 220px;
  padding: 16px 6px;
  float: right;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  margin: 0 10px;
}

#COTK2_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

#COTK2_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#COTK2_Image_Container img {
  width: 100%;
}

#COTK2_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media only screen and (max-width: 760px) {
  #COTK2_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
} */

#COTK2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #COTK2_Heading1_Div {
    font-size: 3vw;
  }

  #COTK2_Text_Div {
    font-size: 2vw;
  }
  #COTK2_Next_Button {
    font-size: 1.9vw;
  }
  #COTK2_Button {
    font-size: 1.9vw;
    line-height: 2vw;
  }
}

#ETMALC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#ETMALC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ETMALC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#ETMALC_Text_Div,
#ETMALC_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#ETMALC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#ETMALC_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#ETMALC_Image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#ETMALC_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
}

#ETMALC_Inner_Image_Container img {
  width: 100%;
}
#ETMALC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #ETMALC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#ETMALC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #ETMALC_Heading1_Div {
    font-size: 2.8vw;
  }

  #ETMALC_Text_Div,
  #ETMALC_Image_Heading {
    font-size: 1.9vw;
  }
  #ETMALC_Next_Button {
    font-size: 1.9vw;
  }
}

#ETKC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#ETKC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ETKC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#ETKC_Text_Div,
#ETKC_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#ETKC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#ETKC_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#ETKC_Image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#ETKC_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
}

#ETKC_Inner_Image_Container img {
  width: 100%;
}
#ETKC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #ETKC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#ETKC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #ETKC_Heading1_Div {
    font-size: 2.8vw;
  }

  #ETKC_Text_Div,
  #ETKC_Image_Heading {
    font-size: 1.9vw;
  }
  #ETKC_Next_Button {
    font-size: 1.9vw;
  }
}

#LV_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#LV_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#LV_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#LV_Text_Div,
#LV_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#LV_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#LV_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
#LV_Image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#LV_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  width: 40%;
}

#LV_Inner_Image_Container img {
  width: 100%;
}
#LV_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #LV_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#LV_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LV_Heading1_Div {
    font-size: 2.8vw;
  }

  #LV_Text_Div,
  #LV_Image_Heading {
    font-size: 1.9vw;
  }
  #LV_Next_Button {
    font-size: 1.9vw;
  }
}

#OTCXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#OTCXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#OTCXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#OTCXR_Text_Div,
#OTCXR_Image_heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#OTCXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#OTCXR_Images_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: center;
}

#OTCXR_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 50%;
}

#OTCXR_Inner_Image_Container img {
  width: 60%;
}

#OTCXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #OTCXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#OTCXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #OTCXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #OTCXR_Text_Div,
  #OTCXR_Image_Heading {
    font-size: 1.9vw;
  }
  #OTCXR_Next_Button {
    font-size: 1.9vw;
  }
}

#SOXRVNBOPS_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#SOXRVNBOPS_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#SOXRVNBOPS_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#SOXRVNBOPS_Heading_row {
  width: 30%;
}

#SOXRVNBOPS_Image_Wrapper {
  border: 3px solid white;
  padding: 15px;
}
#SOXRVNBOPS_Image_Container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}

#SOXRVNBOPS_Table_Row {
  display: flex;
  flex-direction: row;
}

#SOXRVNBOPS_Table_Col_1 {
  width: 40%;
  padding: 20px;
  font-size: 1.4vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#SOXRVNBOPS_Table_Col_2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#SOXRVNBOPS_Table_Col_2_inner_rotate {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
}

#SOXRVNBOPS_Table_Col_2_inner_rotate p {
  position: absolute;
}

#SOXRVNBOPS_Table_Col_2_inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}
#SOXRVNBOPS_Table_Col_2_inner img {
  margin: 2rem;
  width: 47%;
}

.SOXRVNBOPS_Rotaed p {
  transform-origin: 12% 0%;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 1.2vw;
  font-weight: 300;
  transform: rotate(305deg);
  border: transparent !important;
  text-align: center;
}

#SOXRVNBOPS_tick {
  width: 20%;
}

#SOXRVNBOPS_Table {
  display: flex;
  flex-direction: column;
}

.SOXRVNBOPS_yellowBack {
  background-color: #f4ea1c;
}

.SOXRVNBOPS_Green_Back {
  background-color: #b6d777;
}

.SOXRVNBOPS_blueBack {
  background-color: #71cdda;
}

.SOXRVNBOPS_noBack {
  background-color: transparent;
}

#SOXRVNBOPS_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #SOXRVNBOPS_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
  .SOXRVNBOPS_Rotaed p {
    font-size: 1.2vw;
  }
}

#SOXRVNBOPS_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #SOXRVNBOPS_Heading1_Div {
    font-size: 2.8vw;
  }

  #SOXRVNBOPS_Next_Button {
    font-size: 1.9vw;
  }
}

#WXRWYR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  max-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WXRWYR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WXRWYR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#WXRWYR_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}
#WXRWYR_Image_heading {
  font-size: 1.2vw;
  font-family: Lato, sans-serif;
  color: #fff;
  align-self: center;
  text-align: center;
  padding: 7px 0;
}

#WXRWYR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
}

#WXRWYR_Images_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 1%; */
}

#WXRWYR_Images_row {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#WXRWYR_Image {
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* padding: 10px; */
}

#WXRWYR_Image_box {
  width: 50px;
  height: 50px;
  position: absolute;
  bottom: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
}

#WXRWYR_Helper_Div {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

#WXRWYR_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  margin: 0 10px;
}

#WXRWYR_Inner_Image_Container img {
  width: 75%;
}

#WXRWYR_tick {
  width: 50% !important;
}
/* 
#WXRWYR_Image_Wrapper,
#WXRWYR_Image_heading,
#WXRWYR_Images_Container,
#WXRWYR_Inner_Image_Container,
#WXRWYR_Image {
  border: 1px solid red;
} */

#WXRWYR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #WXRWYR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#WXRWYR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WXRWYR_Heading1_Div {
    font-size: 2.8vw;
  }

  #WXRWYR_Text_Div {
    font-size: 1.9vw;
  }
  #WXRWYR_Next_Button {
    font-size: 1.9vw;
  }

  #WXRWYR_Image_heading {
    font-size: 1.8vw;
  }
}

#EKDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#EKDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#EKDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#EKDWXR_Text_Div,
#EKDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#EKDWXR_Inner_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}

#EKDWXR_Inner_Wrapper_2 {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-between;
  align-self: stretch;
}

#EKDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 4px;
  display: flex;
  flex-direction: row;
  align-items: stretch;
}
/* #EKDWXR_Inner_Wrapper,
#EKDWXR_Inner_text,
#EKDWXR_Inner_Image_Container {
  border: 1px solid red;
} */
#EKDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#EKDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#EKDWXR_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#EKDWXR_Inner_Image_Container img {
  /* width: 60%; */
  border: 8px solid white;
  min-width: 125px;
}

#EKDWXR_Inner_text {
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  padding: 5px 0;
}

#EKDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #EKDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 700px;
  }

  #EKDWXR_Inner_Image_Container img {
    height: 280px;
  }
}

@media screen and (min-height: 700px) {
  #EKDWXR_Inner_Image_Container img {
    height: auto;
  }
}

/* @media screen and (max-width: 800px) {
  #EKDWXR_Content_Wrapper {
    padding: 2rem 0rem 0rem 0rem;
    max-width: 700px;
  }
} */

#EKDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #EKDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #EKDWXR_Text_Div,
  #EKDWXR_Image_Heading {
    font-size: 1.7vw;
  }
  #EKDWXR_Next_Button {
    font-size: 1.9vw;
  }
  #EKDWXR_Inner_text {
    font-size: 1.7vw;
  }
}

#HTIJSNAD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#HTIJSNAD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#HTIJSNAD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#HTIJSNAD_Text_Div,
#HTIJSNAD_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#HTIJSNAD_Inner_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px;
}
#HTIJSNAD_Image_Pair_Wrapper {
  display: flex;
  flex-direction: row;
}

#HTIJSNAD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* #HTIJSNAD_Inner_Wrapper,
#HTIJSNAD_Inner_text,
#HTIJSNAD_Inner_Image_Container {
  border: 1px solid red;
} */
#HTIJSNAD_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#HTIJSNAD_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#HTIJSNAD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#HTIJSNAD_Inner_Image_Container img {
  width: 100%;
  min-width: 125px;
}

#HTIJSNAD_Inner_text {
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  padding: 5px 0;
  align-self: stretch;
}

#HTIJSNAD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #HTIJSNAD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }
}

#HTIJSNAD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #HTIJSNAD_Heading1_Div {
    font-size: 2.8vw;
  }

  #HTIJSNAD_Text_Div,
  #HTIJSNAD_Image_Heading {
    font-size: 1.9vw;
  }
  #HTIJSNAD_Next_Button {
    font-size: 1.9vw;
  }

  #HTIJSNAD_Inner_text {
    font-size: 1.8vw;
  }
}

#WDTJSSAEFTMALC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WDTJSSAEFTMALC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WDTJSSAEFTMALC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 0px;
  display: block;
  font-weight: bold;
}

#WDTJSSAEFTMALC_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#WDTJSSAEFTMALC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#WDTJSSAEFTMALC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#WDTJSSAEFTMALC_Image_Wrapper img {
  border: 5px solid white;
  width: 80%;
}

#WDTJSSAEFTMALC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #WDTJSSAEFTMALC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#WDTJSSAEFTMALC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WDTJSSAEFTMALC_Heading1_Div {
    font-size: 2.8vw;
  }

  #WDTJSSAEFTMALC_Text_Div {
    font-size: 1.9vw;
  }
  #WDTJSSAEFTMALC_Next_Button {
    font-size: 1.9vw;
  }
}

#LOJSND_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#LOJSND_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#LOJSND_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#LOJSND_Text_Div,
#LOJSND_Inner_Text_Container,
#LOJSND_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#LOJSND_Inner_Text_Container {
  text-align: center;
}

#LOJSND_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#LOJSND_Inner_Image_text div {
  width: 100%;
}

#LOJSND_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#LOJSND_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#LOJSND_Image_Wrapper img {
  width: 100%;
}

#LOJSND_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #LOJSND_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#LOJSND_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LOJSND_Heading1_Div {
    font-size: 2.8vw;
  }

  #LOJSND_Text_Div,
  #LOJSND_Inner_Text_Container,
  #LOJSND_Inner_Image_text {
    font-size: 1.9vw;
  }
  #LOJSND_Next_Button {
    font-size: 1.9vw;
  }
}

#JSNMC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#JSNMC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNMC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNMC_Text_Div,
#JSNMC_Inner_Text_Container,
#JSNMC_Inner_Image_text,
#JSNMC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNMC_Inner_Text_Container {
  text-align: center;
}

#JSNMC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNMC_Inner_Image_text div {
  width: 100%;
}

#JSNMC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNMC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNMC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNMC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;
}

#JSNMC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNMC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNMC_Image_Wrapper img {
  width: 100%;
}

#JSNMC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNMC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNMC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNMC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNMC_Text_Div,
  #JSNMC_Inner_Text_Container,
  #JSNMC_Inner_Image_text,
  #JSNMC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNMC_Next_Button {
    font-size: 1.9vw;
  }
}

.JSNMC_Secondary_Image_Block
{
  /* width: 161px !important; */
  height: 10.47vw !important;
}

.JSNMC_DispalyNone
{
  background-color: #d3d3d3 !important;
}

#JSNLC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.complete_div{
    width: 150px;
    font-size: 1.5rem;
     height: 50px;
      display: flex;
    background-color: #B7D555;
     align-self: center;
    align-items:center;
    color:white
}
#JSNLC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNLC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNLC_Text_Div,
#JSNLC_Inner_Text_Container,
#JSNLC_Inner_Image_text,
#JSNLC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNLC_Inner_Text_Container {
  text-align: center;
}

#JSNLC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNLC_Inner_Image_text div {
  width: 100%;
}

#JSNLC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNLC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNLC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNLC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNLC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNLC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNLC_Image_Wrapper img {
  width: 100%;
}

#JSNLC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNLC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNLC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNLC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNLC_Text_Div,
  #JSNLC_Inner_Text_Container,
  #JSNLC_Inner_Image_text,
  #JSNLC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNLC_Next_Button {
    font-size: 1.9vw;
  }
}

#WDTJSSAEFTMALC2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WDTJSSAEFTMALC2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WDTJSSAEFTMALC2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 0px;
  display: block;
  font-weight: bold;
}

#WDTJSSAEFTMALC2_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#WDTJSSAEFTMALC2_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#WDTJSSAEFTMALC2_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#WDTJSSAEFTMALC2_Image_Wrapper img {
  width: 80%;
}

#WDTJSSAEFTMALC2_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #WDTJSSAEFTMALC2_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#WDTJSSAEFTMALC2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WDTJSSAEFTMALC2_Heading1_Div {
    font-size: 2.8vw;
  }

  #WDTJSSAEFTMALC2_Text_Div {
    font-size: 1.9vw;
  }
  #WDTJSSAEFTMALC2_Next_Button {
    font-size: 1.9vw;
  }
}

#WITJSFTKC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WITJSFTKC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#WITJSFTKC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#WITJSFTKC_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#WITJSFTKC_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 10px;
}

#WITJSFTKC_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#WITJSFTKC_Image_Container img {
  width: 100%;
}

#WITJSFTKC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 760px) {
  #WITJSFTKC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#WITJSFTKC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WITJSFTKC_Heading1_Div {
    font-size: 2.8vw;
  }

  #WITJSFTKC_Text_Div {
    font-size: 1.9vw;
  }
  #WITJSFTKC_Next_Button {
    font-size: 1.9vw;
  }
}

#YMLATJSONBSOTK_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#YMLATJSONBSOTK_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#YMLATJSONBSOTK_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#YMLATJSONBSOTK_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#YMLATJSONBSOTK_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
  margin-top: 10px;
}

#YMLATJSONBSOTK_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#YMLATJSONBSOTK_Image_Container img {
  width: 100%;
}

#YMLATJSONBSOTK_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #YMLATJSONBSOTK_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#YMLATJSONBSOTK_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #YMLATJSONBSOTK_Heading1_Div {
    font-size: 2.8vw;
  }

  #YMLATJSONBSOTK_Text_Div {
    font-size: 1.9vw;
  }
  #YMLATJSONBSOTK_Next_Button {
    font-size: 1.9vw;
  }
}

#JSNKC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#JSNKC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNKC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNKC_Text_Div,
#JSNKC_Inner_Text_Container,
#JSNKC_Inner_Image_text,
#JSNKC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNKC_Inner_Text_Container {
  text-align: center;
}

#JSNKC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNKC_Inner_Image_text div {
  width: 100%;
}

#JSNKC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNKC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNKC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNKC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNKC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNKC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNKC_Image_Wrapper img {
  width: 100%;
}

#JSNKC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNKC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNKC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNKC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNKC_Text_Div,
  #JSNKC_Inner_Text_Container,
  #JSNKC_Inner_Image_text,
  #JSNKC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNKC_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMATTDLOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMATTDLOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#XRMATTDLOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMATTDLOD_Text_Div,
#XRMATTDLOD_Inner_Text_Container {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#XRMATTDLOD_Inner_Text_Container {
  text-align: center;
}

#XRMATTDLOD_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 10px;
}

#XRMATTDLOD_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#XRMATTDLOD_Image_Container img {
  width: 100%;
}

#XRMATTDLOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#XRMATTDLOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMATTDLOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMATTDLOD_Text_Div,
  #XRMATTDLOD_Inner_Text_Container {
    font-size: 1.9vw;
  }
  #XRMATTDLOD_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMALOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMALOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMALOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMALOD_Text_Div,
#XRMALOD_Inner_Text_Container,
#XRMALOD_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMALOD_Inner_Text_Container {
  text-align: center;
}

#XRMALOD_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#XRMALOD_Inner_Image_text div {
  width: 100%;
}

#XRMALOD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#XRMALOD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#XRMALOD_Image_Wrapper img {
  width: 100%;
}

#XRMALOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #XRMALOD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#XRMALOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMALOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMALOD_Text_Div,
  #XRMALOD_Inner_Text_Container,
  #XRMALOD_Inner_Image_text {
    font-size: 1.9vw;
  }
  #XRMALOD_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMMS_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMMS_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMMS_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 26px;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMMS_Text_Div,
#XRMMS_Inner_Text_Container,
#XRMMS_Inner_Image_text {
  font-size: 16px;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMMS_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#XRMMS_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Congrats_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Tutorials_Congrats_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Congrats_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Congrats_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Congrats_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Congrats_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Congrats_Image_Bone {
  width: 28vw;
  max-width: 300px;
  max-height: calc(100vh - 100px);
}

#Tutorials_Congrats_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Heading2_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Congrats_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Congrats_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1000px) {
  #Tutorials_Congrats_Heading1_Div {
    font-size: 5vw;
  }
  #Tutorials_Congrats_Heading2_Div {
    font-size: 3.1vw;
  }

  #Tutorials_Congrats_Next_Button,
  #Tutorials_Congrats_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Congrats_Image_Bone {
    width: 35vw;
  }

  #Tutorials_Congrats_Text_Div ul li {
    margin-left: 7%;
  }
}

.education-stepper-wrapper
{
    position: absolute;
    z-index: 1;
    margin-top: 120px;
    margin-left: 100px;
    /* display: inline-block;
    width: 100px;
    height: 100px; */
}

.vertical-stepper-wrapper
{
    display: inline-block;
    vertical-align: top;
    cursor: pointer;
}

.vertical-stepper-box-wrapper
{
    vertical-align: top;
    /* overflow: hidden; */
    height: 52px;
    /* text-overflow: inherit; */
    transition: 1s all;
    white-space: nowrap;
  overflow: hidden;
  background: white;
}

.vertical-stepper-box
{
    width: 20px;
    height: 50px;
    border: 1px solid rgb(194, 194, 194);
    border-right: 0px;
    display: inline-block;
}

.vertical-stepper-menu
{
    height: 50px;
    border: 1px solid rgb(194, 194, 194);
    border-left: 0px;

    width: 100%;
    display: inline-block;
    /* background: tomato; */
    vertical-align: top;
    line-height: 50px;
}

.vertical-stepper-menu-wrapper
{
    transition: all 1s;
}

.vertical-stepper-toggler-wrapper
{
    display: inline-block;
    vertical-align: top;
    width: 50px;
    height: 52px;
    background: rgb(194, 194, 194);
    cursor: pointer;
}

.vertical-stepper-toggler-icon
{
    width: 50px;
    transition: all 1s;
}
.LGS_test_span__35KAE{color:#FCB23C;font-weight:bold;font-size:3rem;margin-left:10px}

.Tutorials_XrayMatchingEducation_Matching_Main_Content_Wrapper
{
    text-align: center;
    width: 100%;
    position: relative;
}

#Tutorials_XrayMatchingEducation_Matching_Overview_Explanation_Div
{
    display: inline-block;
    position: absolute;
    top:0px;
    width:200px;
    background: green;
    height:auto;
    margin-left:10px;
    background-color: rgba(18, 20, 53, 0.81);
    font-size: 20px;
    color: white;
    text-align: left;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;

    
}

.Tutorials_XrayMatchingEducation_Matching_Overview_Wrapper
{
    width: 800px;
    margin: auto;
    display: inline-block;
    
    align-items: center;
}



.Tutorial_XrayMatching_Matching_Error_Div
{
    font-size: 40px;
    color:#C50000;
    font-weight: bold;

}

.Tutorial_XrayMatching_Matching_Correct_Div
{
    font-size: 40px;
    color: #b4ec51;
    font-weight: bold;
}
.Tutorial_XrayMatching_Matching_Error_Div
{
    font-size: 40px;
    color:#C50000;
    font-weight: bold;

}

.Tutorial_XrayMatching_Matching_Correct_Div
{
    font-size: 40px;
    color: #b4ec51;
    font-weight: bold;
}

#Evaluaion_XrayMatching_Matching_Content3_Wrapper
{
    float: left;
    width: 50px;
    
    height: 100vh;
    display: flex;
    justify-content: center;
    flex-direction: column;
    justify-content: center;
    margin-left: 20px;
}
#Knee_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  overflow-y: hidden;
}

#Knee_Content1_Wrapper
{
  vertical-align: top;
  min-height:100vh;
  margin-left: 10vw;
}

#Knee_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 40px;
  line-height: 75px;
  margin-bottom: 0px;
  display: block;
}

.image-grid
{
    align-content: center;
    text-align: center;
    align-items: center;

    
}


@media only screen and (min-width:959px)
{
    #Knee_Content1_Wrapper
    {
        /* height: 100vh;
        overflow: hidden; */
    }
    .image-grid
    {
        transform:translateY(-60px)
    }
}
#WIACP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WIACP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#WIACP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#WIACP_Text_Div,
#WIACP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#WIACP_Text_Div_list {
  font-size: 23px;
  margin-left: 15px;
}

#WIACP_Image_Wrapper {
  margin-top: 15px;
  /* padding: 15px; */
}

#WIACP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#WIACP_Inner_Image_Container img {
  width: 100%;
}

#WIACP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #WIACP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #WIACP_Heading1_Div {
    font-size: 24px;
  }

  #WIACP_Text_Div,
  #WIACP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #WIACP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #WIACP_Heading1_Div {
    font-size: 26px;
  }

  #WIACP_Text_Div,
  #WIACP_Image_Heading {
    font-size: 16px;
  }
} */

#WIACP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WIACP_Heading1_Div {
    font-size: 2.8vw;
  }

  #WIACP_Text_Div {
    font-size: 1.9vw;
  }
  #WIACP_Next_Button {
    font-size: 1.9vw;
  }
}

#SBSRCP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#SBSRCP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#SBSRCP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#SBSRCP_Text_Div,
#SBSRCP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#SBSRCP_Text_Div li {
  margin: 5px 15px;
}

#SBSRCP_Image_Wrapper {
  margin-top: 15px;
  /* padding: 15px; */
}

#SBSRCP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#SBSRCP_Inner_Image_Container img {
  width: 100%;
}

#SBSRCP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #SBSRCP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #SBSRCP_Heading1_Div {
    font-size: 24px;
  }

  #SBSRCP_Text_Div,
  #SBSRCP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #SBSRCP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #SBSRCP_Heading1_Div {
    font-size: 26px;
  }

  #SBSRCP_Text_Div,
  #SBSRCP_Image_Heading {
    font-size: 16px;
  }
} */

#SBSRCP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #SBSRCP_Heading1_Div {
    font-size: 2.8vw;
  }

  #SBSRCP_Text_Div {
    font-size: 1.9vw;
  }
  #SBSRCP_Next_Button {
    font-size: 1.9vw;
  }
}

#OCP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#OCP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#OCP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#OCP_Text_Div,
#OCP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#OCP_Text_Div li {
  margin: 5px 0;
}

#OCP_Image_Wrapper {
  margin-top: 15px;
  /* padding: 15px; */
}

#OCP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#OCP_Inner_Image_Container img {
  width: 100%;
}

#OCP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #OCP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #OCP_Heading1_Div {
    font-size: 24px;
  }

  #OCP_Text_Div,
  #OCP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #OCP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #OCP_Heading1_Div {
    font-size: 26px;
  }

  #OCP_Text_Div,
  #OCP_Image_Heading {
    font-size: 16px;
  }
} */

#OCP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #OCP_Heading1_Div {
    font-size: 2.8vw;
  }

  #OCP_Text_Div {
    font-size: 1.9vw;
  }
  #OCP_Next_Button {
    font-size: 1.9vw;
  }
}

#NOP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#NOP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#NOP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#NOP_Text_Div,
#NOP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#NOP_Text_Div li {
  margin: 5px 0;
}

#NOP_Image_Wrapper {
  margin-top: 15px;
  /* padding: 15px; */
}

#NOP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#NOP_Inner_Image_Container img {
  width: 100%;
}

#NOP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #NOP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #NOP_Heading1_Div {
    font-size: 24px;
  }

  #NOP_Text_Div,
  #NOP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #NOP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #NOP_Heading1_Div {
    font-size: 26px;
  }

  #NOP_Text_Div,
  #NOP_Image_Heading {
    font-size: 16px;
  }
} */

#NOP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #NOP_Heading1_Div {
    font-size: 2.8vw;
  }

  #NOP_Text_Div {
    font-size: 1.9vw;
  }
  #NOP_Next_Button {
    font-size: 1.9vw;
  }
}

#OP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#OP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#OP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#OP_Text_Div,
#OP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#OP_Text_Div li {
  margin: 5px 0;
}

#OP_Image_Wrapper {
  margin-top: 15px;
  /* padding: 15px; */
}

#OP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#OP_Inner_Image_Container img {
  width: 100%;
}

#OP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #OP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #OP_Heading1_Div {
    font-size: 24px;
  }

  #OP_Text_Div,
  #OP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #OP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #OP_Heading1_Div {
    font-size: 26px;
  }

  #OP_Text_Div,
  #OP_Image_Heading {
    font-size: 16px;
  }
} */

#OP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #OP_Heading1_Div {
    font-size: 2.8vw;
  }

  #OP_Text_Div {
    font-size: 1.9vw;
  }
  #OP_Next_Button {
    font-size: 1.9vw;
  }
}

#BAJHASPP_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#BAJHASPP_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#BAJHASPP_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#BAJHASPP_Text_Div,
#BAJHASPP_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#BAJHASPP_Text_Div li {
  margin: 5px 0;
}

#BAJHASPP_Image_Wrapper {
  margin: 10px 0;
  /* padding: 15px; */
}

#BAJHASPP_Image_Container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
}

#BAJHASPP_Inner_Image_Container img {
  width: 100%;
}

#BAJHASPP_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #BAJHASPP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #BAJHASPP_Heading1_Div {
    font-size: 24px;
  }

  #BAJHASPP_Text_Div,
  #BAJHASPP_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #BAJHASPP_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #BAJHASPP_Heading1_Div {
    font-size: 26px;
  }

  #BAJHASPP_Text_Div,
  #BAJHASPP_Image_Heading {
    font-size: 16px;
  }
} */

#BAJHASPP_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #BAJHASPP_Heading1_Div {
    font-size: 2.8vw;
  }

  #BAJHASPP_Text_Div {
    font-size: 1.9vw;
  }
  #BAJHASPP_Next_Button {
    font-size: 1.9vw;
  }
}

#SBSVideo_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
    
  );
  min-height: 100vh;

  position: relative;
  /* overflow: hidden; */
}

.sbs-video-wrapper {
  width: auto;
  max-width: calc(100vw - 150px);
  height: auto;
  border: 2px solid sandybrown;
  /* max-height: calc(100vh - 200px); */
}

#SBSVideo_Content_Wrapper {
  margin: auto;
  min-width: 1000px;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#SBSVideo_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 30px;
  display: block;
  font-weight: bold;
  margin-bottom: 10px;
  margin-left: 10px;
}

#SBSVideo_Image_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  padding: 0px;
  height: 600px;
  justify-content: space-around;
  margin: auto;
  margin-top: 15px;
}

#SBSVideo_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
}
#SBSVideo_Image_Container img {
  width: 100%;
}

#SBSVideo_Next_Button_Div {
  display: block;
  margin-top: 10px;
  margin-right: 20px;
}

@media only screen and (max-width: 760px) {
  #SBSVideo_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#SBSVideo_Next_Button {
  float: right;
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Welcome_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Welcome_Image_div {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 45%;
}

#Tutorials_Welcome_Image_Bone {
  width: 300px;
  margin-top: 50px;
}

#Tutorials_Welcome_Text_Wrapper {
  float: left;
  margin-left: 15%;
  width: 70%;
  min-width: 400px;
  max-width: 700px;
  height: 100vh;
  line-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Tutorials_Welcome_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Tutorials_Welcome_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 35px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Welcome_Heading2_Div {
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Tutorials_Welcome_Text_Div {
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;

  /* vertical-align: middle; 
  display: table-row;  */
}
#Tutorials_Welcome_Next_Button_Div {
  display: block;
  margin-top: 20px;
}

@media only screen and (min-height: 700px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 60px;
  }
}

@media only screen and (min-height: 750px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 80px;
  }
}

@media only screen and (min-height: 800px) {
  #Tutorials_Welcome_Next_Button_Div {
    margin-top: 100px;
  }
}

#Tutorials_Welcome_Next_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Overview_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Overview_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 74%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Tutorials_Overview_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Overview_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Overview_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Overview_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Overview_Image_Bone {
  width: 28vw;
  max-width: 250px;
  /* max-width: 400px;
  min-width: 250px; */
}

#Tutorials_Overview_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 3vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Overview_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Overview_Text_Div ul li {
  margin-left: 5%;
  margin-top: 5px;
  /* line-height: 3vw; */
  display: list-item;
  list-style: disc;
}

#Tutorials_Overview_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Overview_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Overview_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #Tutorials_Overview_Heading1_Div {
    font-size: 3.2vw;
  }

  #Tutorials_Overview_Text_Div {
    font-size: 2.3vw;
  }
  #Tutorials_Overview_Next_Button,
  #Tutorials_Overview_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Overview_Image_Bone {
    width: 50vw;
  }

  #Tutorials_Overview_Text_Div ul li {
    margin-left: 7%;
  }
}

#KDAA_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#KDAA_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#KDAA_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#KDAA_Text_Div,
#KDAA_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#KDAA_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#KDAA_Image_Heading {
  color: #fff;
  text-align: center;
}
#KDAA_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  width: 95%;
}

#KDAA_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#KDAA_Inner_Image_Container img {
  width: 100%;
}
#KDAA_Next_Button_Div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

#KDAA_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #KDAA_Heading1_Div {
    font-size: 3vw;
  }

  #KDAA_Text_Div,
  #KDAA_Image_Heading {
    font-size: 2vw;
  }
  #KDAA_Next_Button {
    font-size: 1.9vw;
  }
}

#KDAA2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#KDAA2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#KDAA2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#KDAA2_Text_Div,
#KDAA2_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#KDAA2_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#KDAA2_Image_Heading {
  color: #fff;
  text-align: center;
}
#KDAA2_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  width: 95%;
}

#KDAA2_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#KDAA2_Inner_Image_Container img {
  width: 100%;
}
#KDAA2_Next_Button_Div {
  display: block;
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

#KDAA2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #KDAA2_Heading1_Div {
    font-size: 3vw;
  }

  #KDAA2_Text_Div,
  #KDAA2_Image_Heading {
    font-size: 2vw;
  }
  #KDAA2_Next_Button {
    font-size: 1.9vw;
  }
}

#COTK_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#COTK_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#COTK_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#COTK_Text_Div,
#COTK_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#COTK_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#COTK_Image_Container {
  background-color: white;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: stretch;
  height: 60vh;
}

#COTK_Inner_Image_Container img {
  width: 100%;
}

#COTK_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media screen and (max-width: 935px) {
  #COTK_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }

  #COTK_Heading1_Div {
    font-size: 24px;
  }

  #COTK_Text_Div,
  #COTK_Image_Heading {
    font-size: 15px;
  }
} */
/* 
@media screen and (min-height: 700px) {
  #COTK_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 830px;
  }

  #COTK_Heading1_Div {
    font-size: 26px;
  }

  #COTK_Text_Div,
  #COTK_Image_Heading {
    font-size: 16px;
  }
} */

#COTK_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #COTK_Heading1_Div {
    font-size: 2.8vw;
  }

  #COTK_Text_Div {
    font-size: 1.9vw;
  }
  #COTK_Next_Button {
    font-size: 1.9vw;
  }
}

#VDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#VDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXR_Text_Div,
#VDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#VDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXR_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#VDWXR_Inner_Image_Container img {
  width: 100%;
}
#VDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #VDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#VDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXR_Text_Div {
    font-size: 1.9vw;
  }
  #VDWXR_Next_Button {
    font-size: 1.9vw;
  }
}

#LATMALCOTKITX_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.gradient_div {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px;
  /* background: linear-gradient(0.25turn, #3C78D8, #296fe0); */
}
.test_span {
  color: #fcb23c;
  font-weight: bold;
  font-size: 2rem;
  margin-left: 10px;
}
#LATMALCOTKITX_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#LATMALCOTKITX_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#LATMALCOTKITX_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#LATMALCOTKITX_Buttons_wrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#KDAA_Image_Heading {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

#LATMALCOTKITX_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-grow: 1;
}

#LATMALCOTKITX_Pair_Buttons_wrapper Button {
  margin: 8px 0;
}

#LATMALCOTKITX_Button {
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  display: block;
  width: auto;
}

#LATMALCOTKITX_Image_Wrapper {
  border: 3px solid white;
  padding: 15px;
}

#LATMALCOTKITX_Image_Container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
#LATMALCOTKITX_Image_Container img {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

#LATMALCOTKITX_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #LATMALCOTKITX_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#LATMALCOTKITX_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LATMALCOTKITX_Heading1_Div {
    font-size: 2.8vw;
  }

  #LATMALCOTKITX_Text_Div {
    font-size: 1.9vw;
  }
  #LATMALCOTKITX_Next_Button {
    font-size: 1.9vw;
  }
}

.tutorial-Complete-Box {
  /* height: 100px; */
  /* width: 100px; */
  background-color: #b4ec51;
  color: white;
  font-size: 24px;
  padding: 10px;
  font-weight: 540;
  vertical-align: middle;
  /* display: inline-block; */
}

.tutorial-Complete-Box-tick {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
}

#ETMALC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#ETMALC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#ETMALC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#ETMALC_Text_Div,
#ETMALC_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#ETMALC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#ETMALC_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#ETMALC_Image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#ETMALC_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
}

#ETMALC_Inner_Image_Container img {
  width: 100%;
}
#ETMALC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #ETMALC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#ETMALC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #ETMALC_Heading1_Div {
    font-size: 2.8vw;
  }

  #ETMALC_Text_Div,
  #ETMALC_Image_Heading {
    font-size: 1.9vw;
  }
  #ETMALC_Next_Button {
    font-size: 1.9vw;
  }
}

#LV_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#LV_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#LV_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#LV_Text_Div,
#LV_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#LV_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 10px;
}

#LV_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 100%;
  vertical-align: middle;
}
#LV_Image_container {
  margin-top: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#LV_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5px;
  margin: 5px;
  width: 40%;
}

#LV_Inner_Image_Container img {
  width: 100%;
}
#LV_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #LV_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#LV_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LV_Heading1_Div {
    font-size: 2.8vw;
  }

  #LV_Text_Div,
  #LV_Image_Heading {
    font-size: 1.9vw;
  }
  #LV_Next_Button {
    font-size: 1.9vw;
  }
}

#VDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#VDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXR_Text_Div,
#VDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#VDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXR_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#VDWXR_Inner_Image_Container img {
  width: 100%;
}
#VDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #VDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#VDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXR_Text_Div {
    font-size: 1.9vw;
  }
  #VDWXR_Next_Button {
    font-size: 1.9vw;
  }
}

#SOXRVNBOPS_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#SOXRVNBOPS_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#SOXRVNBOPS_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#SOXRVNBOPS_Heading_row {
  width: 30%;
}

#SOXRVNBOPS_Image_Wrapper {
  border: 3px solid white;
  padding: 15px;
}
#SOXRVNBOPS_Image_Container {
  width: 100%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}

#SOXRVNBOPS_Table_Row {
  display: flex;
  flex-direction: row;
}

#SOXRVNBOPS_Table_Col_1 {
  width: 40%;
  padding: 20px;
  font-size: 1.4vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#SOXRVNBOPS_Table_Col_2 {
  display: flex;
  flex-direction: row;
  width: 100%;
}

#SOXRVNBOPS_Table_Col_2_inner_rotate {
  width: 100%;
  display: flex;
  align-items: flex-start;
  margin-left: 5px;
}

#SOXRVNBOPS_Table_Col_2_inner_rotate p {
  position: absolute;
}

#SOXRVNBOPS_Table_Col_2_inner {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid white;
}
#SOXRVNBOPS_Table_Col_2_inner img {
  margin: 2rem;
  width: 47%;
}

.SOXRVNBOPS_Rotaed p {
  transform-origin: 12% 0%;
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 1.2vw;
  font-weight: 300;
  transform: rotate(305deg);
  border: transparent !important;
  text-align: center;
}

#SOXRVNBOPS_tick {
  width: 20%;
}

#SOXRVNBOPS_Table {
  display: flex;
  flex-direction: column;
}

.SOXRVNBOPS_yellowBack {
  background-color: #f4ea1c;
}

.SOXRVNBOPS_Green_Back {
  background-color: #b6d777;
}

.SOXRVNBOPS_blueBack {
  background-color: #71cdda;
}

.SOXRVNBOPS_noBack {
  background-color: transparent;
}

#SOXRVNBOPS_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #SOXRVNBOPS_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
  .SOXRVNBOPS_Rotaed p {
    font-size: 1.2vw;
  }
}

#SOXRVNBOPS_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #SOXRVNBOPS_Heading1_Div {
    font-size: 2.8vw;
  }

  #SOXRVNBOPS_Next_Button {
    font-size: 1.9vw;
  }
}

#WXRWYR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  max-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WXRWYR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WXRWYR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#WXRWYR_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}
#WXRWYR_Image_heading {
  font-size: 1.2vw;
  font-family: Lato, sans-serif;
  color: #fff;
  align-self: center;
  text-align: center;
  padding: 7px 0;
}

#WXRWYR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  /* padding: 10px; */
  display: flex;
  flex-direction: row;
}

#WXRWYR_Images_col {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  /* margin: 1%; */
}

#WXRWYR_Images_row {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

#WXRWYR_Image {
  background-color: black;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  /* padding: 10px; */
}

.WXRWYR_Image_box {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.2s ease-in;
  margin-block: 10px;
}

#WXRWYR_Helper_Div {
  cursor: pointer;
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
}

#WXRWYR_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
  width: 100%;
  margin: 0 10px;
}

#WXRWYR_Inner_Image_Container img {
  width: 75%;
}

#WXRWYR_tick {
  width: 50% !important;
}
/* 
#WXRWYR_Image_Wrapper,
#WXRWYR_Image_heading,
#WXRWYR_Images_Container,
#WXRWYR_Inner_Image_Container,
#WXRWYR_Image {
  border: 1px solid red;
} */

#WXRWYR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 935px) {
  #WXRWYR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#WXRWYR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WXRWYR_Heading1_Div {
    font-size: 2.8vw;
  }

  #WXRWYR_Text_Div {
    font-size: 1.9vw;
  }
  #WXRWYR_Next_Button {
    font-size: 1.9vw;
  }

  #WXRWYR_Image_heading {
    font-size: 1.8vw;
  }
}

#VDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#VDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXR_Text_Div,
#VDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#VDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXR_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#VDWXR_Inner_Image_Container img {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}
#VDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #VDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#VDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXR_Text_Div {
    font-size: 1.9vw;
  }
  #VDWXR_Next_Button {
    font-size: 1.9vw;
  }
}

#HTIJSNAD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#HTIJSNAD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#HTIJSNAD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#HTIJSNAD_Text_Div,
#HTIJSNAD_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#HTIJSNAD_Inner_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px;
}
#HTIJSNAD_Image_Pair_Wrapper {
  display: flex;
  flex-direction: row;
}

#HTIJSNAD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* #HTIJSNAD_Inner_Wrapper,
#HTIJSNAD_Inner_text,
#HTIJSNAD_Inner_Image_Container {
  border: 1px solid red;
} */
#HTIJSNAD_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#HTIJSNAD_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#HTIJSNAD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#HTIJSNAD_Inner_Image_Container img {
  width: 100%;
  min-width: 125px;
  height: 30vh;
  object-fit: contain;
}

.HTIJSNAD_Inner_text {
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  padding: 5px 0;
  align-self: stretch;
}

#HTIJSNAD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #HTIJSNAD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }
}

#HTIJSNAD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #HTIJSNAD_Heading1_Div {
    font-size: 2.8vw;
  }

  #HTIJSNAD_Text_Div,
  #HTIJSNAD_Image_Heading {
    font-size: 1.9vw;
  }
  #HTIJSNAD_Next_Button {
    font-size: 1.9vw;
  }

  #HTIJSNAD_Inner_text {
    font-size: 1.8vw;
  }
}

#HTIJSNAD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#HTIJSNAD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#HTIJSNAD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#HTIJSNAD_Text_Div,
#HTIJSNAD_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#HTIJSNAD_Inner_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px;
}
#HTIJSNAD_Image_Pair_Wrapper {
  display: flex;
  flex-direction: row;
}

#HTIJSNAD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* #HTIJSNAD_Inner_Wrapper,
#HTIJSNAD_Inner_text,
#HTIJSNAD_Inner_Image_Container {
  border: 1px solid red;
} */
#HTIJSNAD_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#HTIJSNAD_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#HTIJSNAD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#HTIJSNAD_Inner_Image_Container img {
  width: 100%;
  min-width: 125px;
}

#HTIJSNAD_Inner_text {
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  padding: 5px 0;
  align-self: stretch;
}

#HTIJSNAD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #HTIJSNAD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }
}

#HTIJSNAD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #HTIJSNAD_Heading1_Div {
    font-size: 2.8vw;
  }

  #HTIJSNAD_Text_Div,
  #HTIJSNAD_Image_Heading {
    font-size: 1.9vw;
  }
  #HTIJSNAD_Next_Button {
    font-size: 1.9vw;
  }

  #HTIJSNAD_Inner_text {
    font-size: 1.8vw;
  }
}

#LOJSND_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#LOJSND_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#LOJSND_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#LOJSND_Text_Div,
#LOJSND_Inner_Text_Container,
#LOJSND_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#LOJSND_Inner_Text_Container {
  text-align: center;
}

#LOJSND_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#LOJSND_Inner_Image_text div {
  width: 100%;
}

#LOJSND_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#LOJSND_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#LOJSND_Image_Wrapper img {
  width: 100%;
}

#LOJSND_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #LOJSND_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#LOJSND_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LOJSND_Heading1_Div {
    font-size: 2.8vw;
  }

  #LOJSND_Text_Div,
  #LOJSND_Inner_Text_Container,
  #LOJSND_Inner_Image_text {
    font-size: 1.9vw;
  }
  #LOJSND_Next_Button {
    font-size: 1.9vw;
  }
}

#JSNMC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#JSNMC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNMC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNMC_Text_Div,
#JSNMC_Inner_Text_Container,
#JSNMC_Inner_Image_text,
#JSNMC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNMC_Inner_Text_Container {
  text-align: center;
}

#JSNMC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNMC_Inner_Image_text div {
  width: 100%;
}

#JSNMC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNMC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNMC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNMC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;
}

#JSNMC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNMC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNMC_Image_Wrapper img {
  width: 100%;
}

#JSNMC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNMC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNMC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNMC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNMC_Text_Div,
  #JSNMC_Inner_Text_Container,
  #JSNMC_Inner_Image_text,
  #JSNMC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNMC_Next_Button {
    font-size: 1.9vw;
  }
}

.JSNMC_Secondary_Image_Block
{
  /* width: 161px !important; */
  height: 10.47vw !important;
}

.JSNMC_DispalyNone
{
  background-color: #d3d3d3 !important;
}

#JSNLC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.complete_div{
    width: 150px;
    font-size: 1.5rem;
     height: 50px;
      display: flex;
    background-color: #B7D555;
     align-self: center;
    align-items:center;
    color:white
}
#JSNLC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNLC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNLC_Text_Div,
#JSNLC_Inner_Text_Container,
#JSNLC_Inner_Image_text,
#JSNLC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNLC_Inner_Text_Container {
  text-align: center;
}

#JSNLC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNLC_Inner_Image_text div {
  width: 100%;
}

#JSNLC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNLC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNLC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNLC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNLC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNLC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNLC_Image_Wrapper img {
  width: 100%;
}

#JSNLC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNLC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNLC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNLC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNLC_Text_Div,
  #JSNLC_Inner_Text_Container,
  #JSNLC_Inner_Image_text,
  #JSNLC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNLC_Next_Button {
    font-size: 1.9vw;
  }
}

#WDTJSSAEFTMALC2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#WDTJSSAEFTMALC2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#WDTJSSAEFTMALC2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 0px;
  display: block;
  font-weight: bold;
}

#WDTJSSAEFTMALC2_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#WDTJSSAEFTMALC2_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#WDTJSSAEFTMALC2_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#WDTJSSAEFTMALC2_Image_Wrapper img {
  width: 80%;
}

#WDTJSSAEFTMALC2_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #WDTJSSAEFTMALC2_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#WDTJSSAEFTMALC2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #WDTJSSAEFTMALC2_Heading1_Div {
    font-size: 2.8vw;
  }

  #WDTJSSAEFTMALC2_Text_Div {
    font-size: 1.9vw;
  }
  #WDTJSSAEFTMALC2_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMATTDLOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMATTDLOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#XRMATTDLOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMATTDLOD_Text_Div,
#XRMATTDLOD_Inner_Text_Container {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#XRMATTDLOD_Inner_Text_Container {
  text-align: center;
}

#XRMATTDLOD_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: center; */
  margin-top: 10px;
}

#XRMATTDLOD_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#XRMATTDLOD_Image_Container img {
  width: 100%;
}

#XRMATTDLOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#XRMATTDLOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMATTDLOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMATTDLOD_Text_Div,
  #XRMATTDLOD_Inner_Text_Container {
    font-size: 1.9vw;
  }
  #XRMATTDLOD_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMALOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMALOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMALOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMALOD_Text_Div,
#XRMALOD_Inner_Text_Container,
#XRMALOD_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMALOD_Inner_Text_Container {
  text-align: center;
}

#XRMALOD_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#XRMALOD_Inner_Image_text div {
  width: 100%;
}

#XRMALOD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#XRMALOD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#XRMALOD_Image_Wrapper img {
  width: 100%;
}

#XRMALOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #XRMALOD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#XRMALOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMALOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMALOD_Text_Div,
  #XRMALOD_Inner_Text_Container,
  #XRMALOD_Inner_Image_text {
    font-size: 1.9vw;
  }
  #XRMALOD_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMALOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMALOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMALOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMALOD_Text_Div,
#XRMALOD_Inner_Text_Container,
#XRMALOD_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMALOD_Inner_Text_Container {
  text-align: center;
}

#XRMALOD_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#XRMALOD_Inner_Image_text div {
  width: 100%;
}

#XRMALOD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#XRMALOD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#XRMALOD_Image_Wrapper img {
  width: 100%;
}

#XRMALOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #XRMALOD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#XRMALOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMALOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMALOD_Text_Div,
  #XRMALOD_Inner_Text_Container,
  #XRMALOD_Inner_Image_text {
    font-size: 1.9vw;
  }
  #XRMALOD_Next_Button {
    font-size: 1.9vw;
  }
}

#XRMMS_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMMS_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMMS_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 26px;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMMS_Text_Div,
#XRMMS_Inner_Text_Container,
#XRMMS_Inner_Image_text {
  font-size: 16px;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMMS_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#XRMMS_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Congrats_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Tutorials_Congrats_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Congrats_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Congrats_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Congrats_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Congrats_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Congrats_Image_Bone {
  width: 28vw;
  max-width: 300px;
  max-height: calc(100vh - 100px);
}

#Tutorials_Congrats_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Heading2_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Congrats_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Congrats_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1000px) {
  #Tutorials_Congrats_Heading1_Div {
    font-size: 5vw;
  }
  #Tutorials_Congrats_Heading2_Div {
    font-size: 3.1vw;
  }

  #Tutorials_Congrats_Next_Button,
  #Tutorials_Congrats_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Congrats_Image_Bone {
    width: 35vw;
  }

  #Tutorials_Congrats_Text_Div ul li {
    margin-left: 7%;
  }
}

.LGS_test_span__XfM7A{color:#FCB23C;font-weight:bold;font-size:3rem;margin-left:10px}

.Tutorials_XrayMatchingEducation_Matching_Main_Content_Wrapper
{
    text-align: center;
    width: 100%;
    position: relative;
}

#Tutorials_XrayMatchingEducation_Matching_Overview_Explanation_Div
{
    display: inline-block;
    position: absolute;
    top:0px;
    width:200px;
    background: green;
    height:auto;
    margin-left:10px;
    background-color: rgba(18, 20, 53, 0.81);
    font-size: 20px;
    color: white;
    text-align: left;
    padding-top: 10px;
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 10px;

    
}

.Tutorials_XrayMatchingEducation_Matching_Overview_Wrapper
{
    width: 800px;
    margin: auto;
    display: inline-block;
    
    align-items: center;
}



.Tutorial_XrayMatching_Matching_Error_Div
{
    font-size: 40px;
    color:#C50000;
    font-weight: bold;

}

.Tutorial_XrayMatching_Matching_Correct_Div
{
    font-size: 40px;
    color: #b4ec51;
    font-weight: bold;
}
.Tutorial_XrayMatching_Matching_Error_Div {
  font-size: 40px;
  color: #c50000;
  font-weight: bold;
}

.Tutorial_XrayMatching_Matching_Correct_Div {
  font-size: 40px;
  color: #b4ec51;
  font-weight: bold;
}

#Evaluaion_XrayMatching_Matching_Content3_Wrapper {
  float: left;
  width: 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}

#Tutorials_Congrats_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Congrats_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Tutorials_Congrats_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Congrats_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Congrats_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Congrats_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Congrats_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Congrats_Image_Bone {
  width: 28vw;
  max-width: 300px;
  max-height: calc(100vh - 100px);
}

#Tutorials_Congrats_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Heading2_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Congrats_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Congrats_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1000px) {
  #Tutorials_Congrats_Heading1_Div {
    font-size: 5vw;
  }
  #Tutorials_Congrats_Heading2_Div {
    font-size: 3.1vw;
  }

  #Tutorials_Congrats_Next_Button,
  #Tutorials_Congrats_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Congrats_Image_Bone {
    width: 35vw;
  }

  #Tutorials_Congrats_Text_Div ul li {
    margin-left: 7%;
  }
}

#COTK2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#COTK2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#COTK2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#COTK2_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#COTK2_Buttons_wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#COTK2_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

#COTK2_Pair_Buttons_wrapper Button {
  margin: 10px 0;
}

#COTK2_Button {
  display: block;
  width: auto;
  font-family: Lato, sans-serif;
  text-transform: none;
  min-width: 220px;
  padding: 16px 6px;
  float: right;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  margin: 0 10px;
}

#COTK2_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

#COTK2_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#COTK2_Image_Container img {
  width: 100%;
}

#COTK2_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media only screen and (max-width: 760px) {
  #COTK2_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
} */

#COTK2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #COTK2_Heading1_Div {
    font-size: 3vw;
  }

  #COTK2_Text_Div {
    font-size: 2vw;
  }
  #COTK2_Next_Button {
    font-size: 1.9vw;
  }
  #COTK2_Button {
    font-size: 1.9vw;
    line-height: 2vw;
  }
}

#JSNKC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#JSNKC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNKC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNKC_Text_Div,
#JSNKC_Inner_Text_Container,
#JSNKC_Inner_Image_text,
#JSNKC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNKC_Inner_Text_Container {
  text-align: center;
}

#JSNKC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNKC_Inner_Image_text div {
  width: 100%;
}

#JSNKC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNKC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNKC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNKC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNKC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNKC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNKC_Image_Wrapper img {
  width: 100%;
}

#JSNKC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNKC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNKC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNKC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNKC_Text_Div,
  #JSNKC_Inner_Text_Container,
  #JSNKC_Inner_Image_text,
  #JSNKC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNKC_Next_Button {
    font-size: 1.9vw;
  }
}

#JSNLC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.complete_div{
    width: 150px;
    font-size: 1.5rem;
     height: 50px;
      display: flex;
    background-color: #B7D555;
     align-self: center;
    align-items:center;
    color:white
}
#JSNLC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNLC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNLC_Text_Div,
#JSNLC_Inner_Text_Container,
#JSNLC_Inner_Image_text,
#JSNLC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNLC_Inner_Text_Container {
  text-align: center;
}

#JSNLC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNLC_Inner_Image_text div {
  width: 100%;
}

#JSNLC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNLC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNLC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNLC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNLC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNLC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNLC_Image_Wrapper img {
  width: 100%;
}

#JSNLC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNLC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNLC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNLC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNLC_Text_Div,
  #JSNLC_Inner_Text_Container,
  #JSNLC_Inner_Image_text,
  #JSNLC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNLC_Next_Button {
    font-size: 1.9vw;
  }
}

#JSNMC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#JSNMC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNMC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNMC_Text_Div,
#JSNMC_Inner_Text_Container,
#JSNMC_Inner_Image_text,
#JSNMC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNMC_Inner_Text_Container {
  text-align: center;
}

#JSNMC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNMC_Inner_Image_text div {
  width: 100%;
}

#JSNMC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNMC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNMC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNMC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;
}

#JSNMC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNMC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNMC_Image_Wrapper img {
  width: 100%;
}

#JSNMC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNMC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNMC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNMC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNMC_Text_Div,
  #JSNMC_Inner_Text_Container,
  #JSNMC_Inner_Image_text,
  #JSNMC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNMC_Next_Button {
    font-size: 1.9vw;
  }
}

.JSNMC_Secondary_Image_Block
{
  /* width: 161px !important; */
  height: 10.47vw !important;
}

.JSNMC_DispalyNone
{
  background-color: #d3d3d3 !important;
}

#Tutorials_Congrats_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Congrats_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Tutorials_Congrats_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Congrats_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Congrats_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Congrats_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Congrats_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Congrats_Image_Bone {
  width: 28vw;
  max-width: 300px;
  max-height: calc(100vh - 100px);
}

#Tutorials_Congrats_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Heading2_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Congrats_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Congrats_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1000px) {
  #Tutorials_Congrats_Heading1_Div {
    font-size: 5vw;
  }
  #Tutorials_Congrats_Heading2_Div {
    font-size: 3.1vw;
  }

  #Tutorials_Congrats_Next_Button,
  #Tutorials_Congrats_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Congrats_Image_Bone {
    width: 35vw;
  }

  #Tutorials_Congrats_Text_Div ul li {
    margin-left: 7%;
  }
}

#Tutorials_LearnMore_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_LearnMore_Image_div {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 45%;
}

#Tutorials_LearnMore_Image_Bone {
  /* max-height:calc(100vh - 40px);
  min-height: 100px;
  width:auto;
  height:auto; */
  width: 300px;
  margin-top: 50px;
}

#Tutorials_LearnMore_Text_Wrapper {
  float: left;
  margin-left: 15%;
  width: 70%;
  min-width: 400px;
  max-width: 700px;
  height: 100vh;
  line-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Tutorials_LearnMore_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Tutorials_LearnMore_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 35px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_LearnMore_Heading2_Div {
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  max-width: 600px;
  /* vertical-align: middle; 
  display: table-row; */
}

#Tutorials_LearnMore_Text_Div {
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
  max-width: 600px;

  /* vertical-align: middle; 
  display: table-row;  */
}
#Tutorials_LearnMore_Next_Button_Div {
  display: block;
  margin-top: 20px;
}

@media only screen and (min-height: 700px) {
  #Tutorials_LearnMore_Next_Button_Div {
    margin-top: 60px;
  }
}

@media only screen and (min-height: 750px) {
  #Tutorials_LearnMore_Next_Button_Div {
    margin-top: 80px;
  }
}

@media only screen and (min-height: 800px) {
  #Tutorials_LearnMore_Next_Button_Div {
    margin-top: 100px;
  }
}

#Tutorials_LearnMore_Next_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Evalution_History_Table_Wrapper {
  height: 76vh;
  overflow-y: auto !important;
}

#Evalution_History_Table_Head {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  width: 100%;
}
#Evalution_History_Table_Body {
  font-family: Lato, sans-serif;
  color: #fff;
}

.Evalution_History_Table_Body_Row {
  height: 60px;
  margin-top: 10px;
  width: 100%;
}

.Evalution_History_Table_Body_Text {
  vertical-align: bottom;
  line-height: 50px;
}

.Evaluaion_History_Button {
  display: block;
  width: auto;
  min-width: 10px !important;
  max-width: 100% !important;
  padding: 10px 15px !important;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}
.Evaluation_Delete_btn {
  display: block;
  width: auto;
  min-width: 10px !important;
  max-width: 100% !important;
  padding: 10px 15px !important;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}

.Evaluation_IncompleteEvaluation_Button_Div
{
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top:60px;
    width:calc(50% - 30px)
}

.Evaluation_IncompleteEvaluation_Button
{
    display: block !important;
    width:100% !important;
    min-width: 150px !important;
    padding: 15px 15px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 23px !important;  
}
#Login_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

#Login_Main_Div .MuiButton-contained {
  color: #000 !important;
}

#Login_Content1_Wrapper {
  width: 60vw;
  height: 100vh;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  vertical-align: top;

  /* justify-content: center;
  align-items: center; */
}

#Login_Content2_Wrapper {
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 400px;
  max-width: 40vh;
}

#Login_Content2_Wrapper_Text {
  transform: translateY(-80px);
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 28px;
  line-height: 36px;
  font-weight: 400;
  text-align: center;
}

#Login_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 40px;
  display: block;
  /* margin-left: 110px; */
  width: 60%;
}

.Login_Box1_Div {
  display: block;
  height: 60px;
  margin-top: 10px;
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  /* background: #b4ec51 */
}

.Login_Box1_Div_Content1 {
  display: inline-block;
  font-size: 28px;
  vertical-align: top;
  height: 60px;
  line-height: 60px;
  color: #fff;
  width: 20%;
  /* background: #3023ae */
}

.Login_Box1_Div_Content2 {
  display: inline-block;
  height: 60px;
  /* background: #270c41; */
  /* height: 80px; */
  vertical-align: top;
  margin-left: 10px;
  width: 60%;
}

.MuiOutlinedInput-notchedOutline {
  border-color: #fff !important;
  border-width: 2px !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-width: 3px !important;
}

#Login_Button_Div {
  display: block;
  margin-top: 45px;
  width: 100%;
  display: flex;
  justify-content: center;
}

#Login_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Login_Image_Bone {
  width: 300px;
  margin-top: 50px;
}

#Login-BoneImage {
  /* width: 400px; */
}

@media only screen and (max-width: 960px) {
  #Login-BoneImage {
    display: none;
  }
}

@media only screen and (max-width: 600px) {
  #Login_Heading1_Div {
    font-size: 60px;
    line-height: 60px;
    margin-left: unset;
    width: 80%;
  }
  .Login_Box1_Div_Content1 {
    font-size: 23px;
    width: 30%;
  }
  #Login_Button_Div {
    margin-left: unset;
  }
}

#Login-Grid {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#Admin_CreateUser_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  box-sizing: unset;
}

#Admin_CreateUser_Content1_Wrapper
{
 
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Admin_CreateUser_Heading1_Div
{
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    font-size: 75px;
    line-height: 75px;
    margin-bottom: 40px;
    display: block;
}

.react-swipeable-view-container
{
    /* margin:0px 0px !important; */
    /* justify-content: space-between !important; */
}

.Admin_Swipe_Div
{   
    width: 100%;
    position: relative;
    height: 100%;
    text-align: center;
    margin: auto;
}





.Admin_CreateUser_Button_Div
{
  display: block !important;
  height:calc(20% - 30px);
  /* min-width: 300px !important; */
margin: auto;
  margin-bottom: 0px;
  padding-top:10px;
    width: calc(410px); /* min-width: 300px; */
  text-align: center;
  justify-self: center;
  align-items: center;
  justify-content: center;
  /* padding-left:40px; */
  
  /* position: relative; */
  /* margin-top: 20px !important; */
  /* padding-right: 10px !important; */
  /* margin:20px -4px 0px -4px; */
  
}

.Admin_CreateUser_Button
{
 

  display: block !important;
  width:100% !important;
  /* min-width: 300px !important; */
  padding: 15px 25px !important;
  float: left !important;
  font-size: 28px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: Lato, sans-serif !important;
  text-transform: none !important;
}


.Admin_Box1_Div
{
    display: block;
    height:calc(20% - 40px);
    padding-top: 30px;
    /* height:60px;  */

    /* background: #b4ec51 */
}

.Admin_Box1_Div_Content1
{
    display: inline-block;
    font-size: 28px;
    vertical-align:top;
    height:60px;
    line-height: 60px;
    color: #fff;
    width:170px;
    /* background: #3023ae */

}

.Admin_Box1_Div_Content2
{
    display: inline-block;
    height:60px;
    /* background: #270c41; */
    /* height: 80px; */
    vertical-align: top;
    margin-left:10px;
}

.Admin_CreateUser_Back_Button_Div
{
  display: inline-block !important;
  height:calc(50px);
  /* min-width: 300px !important; */
position: relative;
    margin: auto;
  margin-bottom: 0px;
/* background: #3023ae; */
  padding-top:30px;
    width: calc(260px); /* min-width: 300px; */
 
  /* padding-left:40px; */
  
  /* position: relative; */
  /* margin-top: 20px !important; */
  /* padding-right: 10px !important; */
  /* margin:20px -4px 0px -4px; */
  
}

.Admin_CreateUser_Back_Button
{
 
position: absolute !important;
right: -7px !important; 
/* display: block !important; */
  width:150px !important;
  /* min-width: 300px !important; */
  padding: 10px 25px !important;
  float: left !important;
  font-size: 20px !important;
  line-height: 20px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: Lato, sans-serif !important;
}

.Admin_CreateUser_Add_Button
{
 
position: absolute !important;
right: -7px !important; 
/* display: block !important; */
  width:200px !important;
  /* min-width: 300px !important; */
  padding: 10px 25px !important;
  float: left !important;
  font-size: 20px !important;
  line-height: 20px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: Lato, sans-serif !important;
}

#Home_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  overflow-y: hidden;
}

#Home_Content1_Wrapper {
  vertical-align: top;
  min-height: 100vh;
  /* height:calc(100vh - 360px); */
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 10vw;
  align-items: center;
}

@media only screen and (min-width:960px)
{
  #Home_Content1_Wrapper {
    max-height: 100vh;
  }
}

#Home_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 0px;
  display: block;
}

.home-grid-inside-heading {
  font-size: 28px;
  font-family: Lato, sans-serif;
  font-weight: 650;
  color: white;
  text-align: center;
}

@media only screen and (min-height: 700px) {
  #Home_Heading1_Div {
    margin-bottom: 20px;
  }
}

@media only screen and (min-height: 800px) {
  #Home_Heading1_Div {
    margin-bottom: 60px;
  }
}

#Home_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 10px;
  margin-bottom: 0px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

.Home_Button_Div {
  display: block;
  margin-top: 20px;
  white-space: nowrap;
  /* margin-left: 100px; */
}

#Get_started_Div{
  margin-top: 0px !important;
}


#Home_Image_div_wrapper {
  display: flex;
  justify-content: center;
  text-align: center;
  flex-direction: column;
  align-items: center;
}

#Home_Image_div {
  margin: auto;
  max-width: 200px;
  max-height: calc(100vh - 650px);
  overflow-y: visible;
}

#Home_Image_div_patient_evaluation_home {
  margin: auto;
  max-width: 300px;
  max-height: calc(100vh - 250px);
  overflow-y: hidden;
}

#Home_Image_div_knee_evaluation {
  margin: auto;
  width: 400px;
  max-height: calc(100vh - 250px);
  overflow-y: hidden;
}

#Home_Image_div_Hip_Evaluation {
  margin: auto;
  width: 600px;
  max-height: calc(100vh - 250px);
  overflow-y: hidden;
}

#Hip_IEvaluation_HipBone {
  width: 100%;
  margin-top: 50px;
  max-height: 100%;
  max-width: 100%;
}

#Knee_Evaluation_KneeBone {
  width: 100%;
  margin-top: 50px;
  max-height: 100%;
  max-width: 100%;
}

#Home_Image_Bone {
  width: auto;
  margin-top: 50px;
  max-height: 100%;
  max-width: 100%;
}

.Home_Button_Div_Inline {
  /* margin-left: 80px; */
  width: calc(100%);
  text-align: center;
  height: 62px;
}

#Home_Button_Div_Inline_One {
  display: inline-block;
  margin-top: 20px;
  /* margin-left: 100px; */
}

#Home_Button_Div_Inline_Two {
  display: inline-block;
  position: absolute;
  margin-top: 20px;
  margin-left: 220px;
}

@media only screen and (max-width: 1000px) {
  .Home_Button_Div_Inline {
    width: calc(100%);
  }
  #Home_Button_Div_Inline_Two {
    margin-left: 150px;
  }
}

@media only screen and (max-width: 920px) {
  .Home_Button_Div_Inline {
    width: calc(100%);
  }
  #Home_Button_Div_Inline_Two {
    margin-left: 100px;
  }
}

@media only screen and (max-width: 900px) {
  .Home_Button_Div {
    margin-left: 0px;
  }

  .Home_Button_Div_Inline {
    width: calc(938px - 300px);
  }

  #Home_Button_Div_Inline_One {
    margin-left: 0px;
  }
}

#Home_Button {
  display: block;
  width: auto;
  min-width: 350px;
  max-width: 350px;
  padding: 15px 25px;
  float: left;
  font-size: 22px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  font-weight: 500;
  text-transform: none;
  color: #000;
}

#Home_Button_One {
  display: block;
  width: auto;
  color: #000;

  min-width: 180px;
  padding: 15px 25px;
  float: left;
  font-size: 22px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

.home-grid-left {
  display: flex;
  flex-direction: column;
  align-items: center;
}

#Home_Content2_Wrapper {
  display: flex;
}

.Evaluation_Home_ResumeEvaluationWarningModal_Text_Div
{
  margin-top: 10px;
  font-size: 26px;
  font-family: Lato, sans-serif;
  color: #000;
  text-align: center;
}

.Home_Incomplete_Div
{
  margin-left: 20px;
  padding:0px 10px;
  /* width: 100px; */
  height: 42px;
  line-height: 42px;
  margin-top: 10px;
  background: rgba(255, 30, 0, 0.63);
  display: inline-block;
  position: relative;
  color: white;
  opacity: 0.8;
}

.Home_Complete_Div
{
  margin-left: 20px;
  padding:0px 10px;
  height: 42px;
  line-height: 42px;
  margin-top: 10px;
  background: #b4ec51;
  display: inline-block;
  position: relative;
  color: white;
  opacity: 1;
}

#EditProfile_Main_Div {
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  position: relative;
  overflow: hidden;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#EditProfile_Content_Div {
  width: 50%;
  min-width: 400px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* align-items: center; */
  border: none;
  box-shadow: none;
}

.EditProfile_Heading {
  color: white;
  margin-bottom: 60px !important;
}

#EditProfile_Rodal_Heading_Div
{
  margin-bottom: 40px;
  text-align: center;
  font-size: 28px;
  color:white;
}

.EditProfile_Email_Div,
.EditProfile_Organization_Div,
.EditProfile_UserType_Div {
  display: flex;
  width: 80%;
  align-items: center;
  margin: 8px 0;
}

.EditProfile_Email_Input_Div,
.EditProfile_Organization_Input_Div,
.EditProfile_UserType_Input_Div {
  width: 100%;
  margin-left: 10px;
}

.EditProfile_OldPassword_Text
{
  width: 50%;
}

.EditProfile_Rodal_Row_Div {
  display: flex;
  width: 100%;
  align-items: center;
  margin: 8px 0;
}

.EditProfile_OldPassword_Input_Div
{
  width: 40%;
}

#password-update-error-message
{
  color:red;
  font-size: 18px;
}

#password-update-success-message
{
  margin-top:20px;
  color:green;
  font-size: 22px;
}



.EditProfile_Input_Texts {
  color: white;
}

#Change_Password_Button {
  display: block;
  width: auto;
  margin-top: 40px;
  min-width: 300px;
  max-width: 250px;
  padding: 15px 25px;
  float: left;
  font-size: 20px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

