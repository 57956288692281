* {
    font-family: Lato, sans-serif;
}
 
#RCPNO-outer-box-2
{    
    display: flex;
    justify-content: center;
    align-items: center;
}

#RCPNO-inner-box-1 {
    flex-grow: 1;
    place-self: flex-start;
}

#RCPNO-inner-box-2 {
    flex-grow: 1;
    place-self: flex-start;

}

#RCPNO-heading {
    color: white;
    width: 100%;
}

.RCPNO-List {
    list-style-type: none;
}

.RCPNO-List li {
    margin: 30px 0;
}

#RCPNO-Image {
    height: auto;
    width: 300px;
    margin-left: 40px;
}

.RCPNO-2nd-List {
}

@media screen and ( max-width: 800px ) {
    #RCPNO-field1 , #RCPNO-field2 , #RCPNO-field3 , #RCPNO-field4 , #RCPNO-field5 , #RCPNO-field6 {
        font-size: 18px;
    }

    #RCPNO-heading {
        padding-top: 10px;
        margin-left: -30%;
    }

    .RCPNO-2nd-List {
        margin-top: 210px !important;
    }

    #RCPNO-Image {
        height: 150px;
        width: 150px;
    }
}