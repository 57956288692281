#Pro-gate-main-box {
    background-image: linear-gradient(
        119deg,
        #3023ae,
        #53a0fd 47%,
        #6eb6cc 81%,
        #b4ec51
      );
      min-height: 100vh;
      position: relative;
      overflow: hidden;
      line-height: normal;
}

#Pro-gate-first-box {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Pro-gate-second-box {
    height: 10vh;
}

#Pro-gate-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 30px;
    margin-bottom: 40px;
}

#Pro-gate-yes-btn , #Pro-gate-no-btn {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
}

#Pro-gate-back-btn {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    font-weight: bold;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 50px;   
}