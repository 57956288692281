#Evaluaion_Report_Selected_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_Report_Selected_Content1_Wrapper
{
 
  float:left;
  margin-left:15%;
  margin-right: 3%;
  width:42%;
  min-width:250px;
  max-width: 700px;
  height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Report_Selected_Heading1_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}

#Evaluaion_Report_Selected_Heading2_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 22px;
  line-height: 30px;
  text-align: left;
  margin-bottom: 30px;
}



.Evaluaion_Report_Selected_State_Button_Div
{
  display: block;
  margin-bottom: 10px;
}

.Evaluaion_Report_Selected_State_Button
{
    display: block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 32px !important;
    border-radius: 25px !important;
    border-width: 1px !important;
    border-color: hsla(0, 0%, 100%, 0.34) !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

.Evaluaion_Report_Selected_Confirm_Button_Div
{
  display: inline-block;
  width:55%;
}

.Evaluaion_Report_Selected_Confirm_Button
{
    display: inline-block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 28px !important;
    border-radius: 25px !important;
    background: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

.Evaluaion_Report_Selected_AddNotes_Button_Div
{
  width: 30%;
  float: right;
}

.Evaluaion_Report_Selected_AddNotes_Button
{
    display: inline-block;
    width:100% !important;
    padding: 5px 15px !important;
    font-size: 20px !important;
    line-height: 48px !important;
    border-radius: 25px !important;
    background: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    text-transform: none !important;
}

#Evaluaion_Report_Selected_Content2_Wrapper
{
  float:left;
  /* width:35%; */
  min-width:250px;
  max-width: 700px;
  height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Report_Selected_Heading3_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  display: block;
  margin-bottom: 5px;
  font-size: 28px;
  line-height: 30px;
  text-align: right;
  margin-bottom: 30px;
}


