#Evaluaion_Forms_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_Forms_Content1_Wrapper
{
 
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_Forms_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 30px;
}

#Evaluaion_Forms_Box1_Div
{
    min-width: 400px;
    width:calc(100% - 22px);
    max-width: 700px;
    padding:10px 10px;
    border-radius: 5px;
    border:1px solid #fff;
    height:220px;
}

.Evaluaion_Forms_Box1_Content1
{
    width:7%;
    background:#BBBBBB;
    height:50px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
    padding:25px 3%;
     
}

.Evaluaion_Forms_Box1_Content1_Image
{
    display: block;
    width: 50px;
}

.Evaluaion_Forms_Box1_Content2
{
    width:calc(80% - 20px);
    height:100px;
    line-height: 100px;
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;

}

.Evaluaion_Forms_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 32px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}


.Evaluaion_Forms_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%; */
  /* height:100%; */
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_Forms_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 11px;
  margin-top:10px;
}

#Evaluaion_Forms_Next_Button_Div
{
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom:10px;
  right:10px;
}

#Evaluaion_Forms_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

