#Evaluaion_UploadXray_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}
#Evaluaion_UploadXray_Content1_Wrapper
{
 
  width:100vw;
  height:100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  text-align: center;
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_UploadXray_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 30px;
}
