#COTK2_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#COTK2_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#COTK2_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#COTK2_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#COTK2_Buttons_wrapper {
  padding: 1rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

#COTK2_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-direction: row;
}

#COTK2_Pair_Buttons_wrapper Button {
  margin: 10px 0;
}

#COTK2_Button {
  display: block;
  width: auto;
  font-family: Lato, sans-serif;
  text-transform: none;
  min-width: 220px;
  padding: 16px 6px;
  float: right;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  margin: 0 10px;
}

#COTK2_Image_Wrapper {
  border: 3px solid white;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-around;
}

#COTK2_Image_Container {
  width: 80%;
  margin: 1rem;
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: flex-start;
}
#COTK2_Image_Container img {
  width: 100%;
}

#COTK2_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

/* @media only screen and (max-width: 760px) {
  #COTK2_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
} */

#COTK2_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #COTK2_Heading1_Div {
    font-size: 3vw;
  }

  #COTK2_Text_Div {
    font-size: 2vw;
  }
  #COTK2_Next_Button {
    font-size: 1.9vw;
  }
  #COTK2_Button {
    font-size: 1.9vw;
    line-height: 2vw;
  }
}
