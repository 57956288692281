#Evalution_History_Table_Wrapper {
  height: 76vh;
  overflow-y: auto !important;
}

#Evalution_History_Table_Head {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
  width: 100%;
}
#Evalution_History_Table_Body {
  font-family: Lato, sans-serif;
  color: #fff;
}

.Evalution_History_Table_Body_Row {
  height: 60px;
  margin-top: 10px;
  width: 100%;
}

.Evalution_History_Table_Body_Text {
  vertical-align: bottom;
  line-height: 50px;
}

.Evaluaion_History_Button {
  display: block;
  width: auto;
  min-width: 10px !important;
  max-width: 100% !important;
  padding: 10px 15px !important;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}
.Evaluation_Delete_btn {
  display: block;
  width: auto;
  min-width: 10px !important;
  max-width: 100% !important;
  padding: 10px 15px !important;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px !important;
  font-family: "Lato", sans-serif !important;
  text-transform: none !important;
}
