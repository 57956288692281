#Tutorials_Congrats_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_Congrats_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
#Tutorials_Congrats_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 25px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_Congrats_Center_Wrapper {
  display: flex;
  flex-direction: column;
}

#Tutorials_Congrats_Inner_Content_Wrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

#Tutorials_Congrats_Text_Wrapper {
  display: flex;
  align-items: center;
}

#Tutorials_Congrats_Image_div {
  display: flex;
  align-items: center;
  justify-content: center;
}

#Tutorials_Congrats_Image_Bone {
  width: 28vw;
  max-width: 300px;
  max-height: calc(100vh - 100px);
}

#Tutorials_Congrats_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 4.5vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Heading2_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2.6vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#Tutorials_Congrats_Text_Div {
  font-size: 2vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#Tutorials_Congrats_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

#Tutorials_Congrats_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

#Tutorials_Congrats_Back_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 15px 25px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1000px) {
  #Tutorials_Congrats_Heading1_Div {
    font-size: 5vw;
  }
  #Tutorials_Congrats_Heading2_Div {
    font-size: 3.1vw;
  }

  #Tutorials_Congrats_Next_Button,
  #Tutorials_Congrats_Back_Button {
    font-size: 1.9vw;
  }

  #Tutorials_Congrats_Image_Bone {
    width: 35vw;
  }

  #Tutorials_Congrats_Text_Div ul li {
    margin-left: 7%;
  }
}
