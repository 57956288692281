#Evaluaion_Report_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  margin: auto;
  position: relative;
  overflow: hidden;
  z-index: 2;
}

#ReportMainDiv {
  width: 60vw;
  margin: auto;
  padding: 10px;
  padding-left: 20px;
  padding-right: 20px;
  background-image: -webkit-linear-gradient(left ,#86b2fa, #6D9EEB);
  border: 1px solid #fff;
  min-width: 700px;
  max-width: 900px;
}

#Evaluaion_NewEvaluation_Back_Button_Divs{
  position: absolute;
  left:150px;
  bottom: 5px;
  max-width: 100px;
}

#Evaluaion_NewEvaluation_Next_Button_report
{
  display: block;
  width:auto;
  min-width: 220px;
  padding: 12px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 30px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#Evaluaion_Report_Content_Wrapper {
  width: 100vw;
  height: 100vh;

  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
}

@media only screen and (max-width: 1300px) {
  #ReportMainDiv {
    width: 80vw;
  }
}

@media only screen and (max-width: 950px) {
  #ReportMainDiv {
    width: 90vw;
  }
}

@media only screen and (max-width: 850px) {
  #ReportMainDiv {
    width: calc(100vw - 40px);
  }
}

@media only screen and (max-width: 800px) {
  #ReportMainDiv {
    padding-left: 5px;
    padding-right: 5px;
    width: calc(100vw - 10px);
  }
}

@media only screen and (max-width: 767px) {
  #ReportMainDiv {
    padding-left: 0px;
    padding-right: 0px;
    width: calc(100vw);
  }
}

.Evaluaion_Report_Box_Text1 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Text2 {
  font-size: 40px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Priority {
  font-size: 32px;
  line-height: 48px;
  font-weight: 400;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Priority_Box {
  border: 1px solid #fff;
  font-size: 32px;
  line-height: 48px;
  font-weight: 700;
  color: #fff;
  font-family: "Lato", sans-serif;
  padding: 10px 20px;

  /* display:block */
}

.Evaluaion_Report_Box_Text3 {
  font-size: 24px;
  line-height: 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
}

.Evaluaion_Report_Box_Text4 {
  height: 100px;
  width: 121px;
  /* background: #3023ae; */
  /* padding-top: 5px; */
  transform: rotate(-45deg) translateY(-27px) translateX(80px);
  font-size: 18px;
  line-height: 30px;
  color: #fff;
  font-family: "Lato", sans-serif;
  word-wrap: nowrap;
}

.Evaluaion_Report_Box_Text5 {
  text-align: center;
  font-family: Lato, sans-serif;
  color: #fff;
  height: 100%;
  justify-content: center;
  align-items: center;
  display: flex;
  font-size: 20px;
  font-weight: 400;
}

/* .Evaluaion_Report_Box_Text6 {

} */

.Evaluaion_Report_Box_Selected_Box {
  display: flex;
  height: 100%;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  color: white;
}

.Evaluaion_Report_Box_Selected_Box_Eval_Change {
  color: white;
  font-size: 16px;
}

.Evaluaion_Report_Box_Button_Div {
  width: calc(100% - 10px);
  padding-left: 5px;
  height: 40px;
  margin: auto;
  position: absolute;
  top: 7px;
}

.Evaluaion_Report_Box_Button_Div2 {
  width: calc(100% - 10px);
  padding-left: 5px;
  height: 25px;
  margin: auto;
  position: absolute;
  top: 7px;
  justify-content: center;
  text-align: center;
  padding-top: 15px;
  color: white;
}

.Evaluaion_Report_Box_Button {
  display: block !important;
  width: 100% !important;
  height: 100% !important;
  margin: auto !important;
  /* padding: 15px 0px; */
  font-size: 18px !important;
  line-height: 18px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: "Lato", sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
  letter-spacing: normal !important;
}

.Evaluaion_Report_Box_Search_Box {
  position: absolute;
  border-radius: 10px;
  bottom: 10px;
  left: 10px;
  padding: 10px 10px 7px;
  background: #b4ec51;
}

.Evaluaion_Report_Box_Note_Box {
  position: absolute;
  border-radius: 5px;
  bottom: 0px;
  right: 0px;
  padding: 10px 10px 2px;
  background: #b4ec51;
}

.Evaluaion_Report_Box_Notes_Box {
  position: absolute;
  border-radius: 5px;
  bottom: 10px;
  right: 20px;
  padding: 10px 10px 2px;
  background: #b4ec51;
}

#Evaluaion_Report_Next_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  right: 10px;
}

#Evaluaion_Report_Next_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif;
  text-transform: none;
}

.Evaluation_Report_XrayImage_Modal_Wrapper {
  text-align: center;
  width: 100vw;
  height: 100vh;
  align-content: center;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

#Evaluaion_Report_Modal_Close_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif !important;
  text-transform: none;
  margin-top: 20px;
  margin-left: calc(50% - 100px);
}

#Evaluaion_Report_Box_Wrapper {
}
