#VDWXR_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#VDWXR_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 60%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#VDWXR_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#VDWXR_Text_Div,
#VDWXR_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#VDWXR_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
}

#VDWXR_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#VDWXR_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#VDWXR_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#VDWXR_Inner_Image_Container img {
  width: 100%;
}
#VDWXR_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #VDWXR_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#VDWXR_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #VDWXR_Heading1_Div {
    font-size: 2.8vw;
  }

  #VDWXR_Text_Div {
    font-size: 1.9vw;
  }
  #VDWXR_Next_Button {
    font-size: 1.9vw;
  }
}
