.XrayImage_Modal
{
    display:block;
    padding: 10px;
    background: #3023ae;
    max-width: 100%;
    align-self: center;
}

.XrayImage_Modal_Wrapper
{
    text-align: center;
    width: 100vw;
    height: 100vh;
    align-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center
}

#XrayImage_Modal_Close_Button
{
    display: block;
    width:auto;
    min-width: 200px;
    padding: 15px 25px;
    font-size: 20px;
    line-height: 20px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: 'Lato', sans-serif !important;
    text-transform:none;
    margin-top:20px;
    margin-left: calc(50% - 100px);
}


#XrayImage_Modal_Update_View_Button
{
    display: block;
    width:auto;
    min-width: 200px;
    padding: 15px 25px;
    font-size: 20px;
    line-height: 20px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: 'Lato', sans-serif !important;
    text-transform:none;
    margin-top:20px;
    margin-left: calc(50% - 100px);
}