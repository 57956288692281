/* JointSummary Screen */

#JointSummary-main-box {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

* {
    font-family: Lato, sans-serif;;
}

#JointSummary-heading {
    color: white;
    text-align: left;
    width: 100%;
    font-size: calc(1rem + 20px);
    text-align: center;

}

#JointSummary-box-1 {
    /* border: 1px solid yellow; */
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
}

#JointSummary-inner-box-1 {
    /* border: 1px solid yellow; */
    /* height: 90vh; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* align-items: center; */
    position: relative;
    align-self: center;
    text-align: left;
}

#JointSummary-row-1 {
    display: flex;
    flex-direction: row;
    justify-content: center;
}

#JointSummary-part-1 {
    background-color: #B3D89B;
    border: 1px solid white;
    height: 140px;
    width: 152px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
}

#JointSummary-part-4 {
    background-color: #B3D89B;
    border: 1px solid white;
    border-radius: 100px;
    height: 140px;
    width: 140px;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    font-size: 22px;
    margin-bottom: 20px;
    margin-top: 20px;
}

#JointSummary-part-last-box {
    margin-left: 20px;
}

#JointSummary-part-1-heading {
    color: white;
    /* border: 1px solid black; */
    width: 152px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: lighter;
    margin-top: 10px;
}

#JointSummary-part-2-heading {
    color: white;
    /* border: 1px solid black; */
    width: 140px;
    text-align: center;
    margin-bottom: 10px;
    font-weight: lighter;
    margin-top: 10px;
}

#JointSummary-row-2 {
    background-color: #B3D89B;
    /* border: 1px solid white; */
    height: 140px;
    width: 140px;
    /* display: flex;
    justify-content: center;
    align-items: center; */
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
}

#JointSummary-Image {
    height: 140px;
    width: 140px;
}

#JointSummary-btn-2 {
    /* width: 150px; */
    margin-right: 15px;
    font-family: Lato, sans-serif;
    font-size: 20px;
    text-transform: none;
    border-radius: 25px;
    background-color: #b4ec51;
    height: 40px;
    min-width: 150px;
}

.Joint_Summary_Box_Search_Box
{
   position:absolute;
   border-top-right-radius:3px; 
   border-bottom-right-radius: 0px;
   bottom:0px;
   left:0px;
   padding:5px 5px 7px;
   background:#B4EC51;
   cursor: pointer;
}

#Joint_Summary_Heading_2
{
    position: relative;
    left: 0px;
    font-size: 3rem;
    color: white;
}

#JointSummary-recommendation-div
{
    /* width: 100%; */
    display: inline-block;
    padding: 5px 10px;
    background-color: white;
    color: black;
}