
    .SurgeryInfo-btn-1 {
        /* width: 150px; */
        margin-right: 15px !important;
        font-family: Lato, sans-serif;
        font-size: 20px !important;
        text-transform: none !important;
        border-radius: 25px !important;
        background-color: #b4ec51 !important;
        min-width: 150px !important;
        max-width: 250px !important;
    }