#inner-box-1 {
  display: flex;
  flex-direction: row;
}

@media screen and (max-width: 800px) {
  #inner-box-1 {
    flex-direction: column;
  }
}

@media screen and (max-width: 500px) {
  #inner-box-1 {
    flex-direction: column;
  }
}

#Export-Script-child_1,
#Export-Script-child_2 {
  flex-grow: 1;
}

#Export-Script-child_1 {
  color: white;
  font-family: Lato, sans-serif;
  font-size: 20px;
  padding: 5px 0px 5px 10px;
  max-width: calc(60% - 10px);
  display: inline-block;
}

#Export-Script-child_2 {
  display: inline-block;
  max-width: calc(40%);
  vertical-align: super;
  vertical-align: top;
}

#Evaluaion_Export-Script_Next_Button_Div2 {
  display: inline-block;
  display: flex;
  justify-content: flex-end;
  position: absolute;
  right: 0px;
  padding-top: 10px;
}

/* #Evaluaion_NewEvaluation_Next_Button {
    display: block;
    min-width: 280px;
    padding: 10px 20px;
    font-size: 20px;
    line-height: 22px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
  } */

#Export-Script-last-header {
  color: white;
  font-family: Lato, sans-serif;
  font-size: 22px;
  width: 60%;
  text-align: left;
  margin-top: 10px;
  margin-bottom: 10px;
  width: auto;
  max-width: 50%;
  display: inline-block;
}

#Evaluaion_ExportScript_Next_Button_Div1 {
  display: inline-block;
  justify-content: center;
  position: absolute;
  right: 0px;
  padding-top: 10px;
}

/* Re-use from newEvaluation.css */

/* this */
#Evaluaion_ExportScript_Save_Button1 {
  display: block;
  min-width: 280px;
  padding: 10px 20px;
  font-size: 20px;
  line-height: 22px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

.Export-Script-btn-layer1 {
  flex-grow: 1;
}

.Export-Script-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

#ExportScript_Welcome_Next_Button1 {
  display: block;
  width: auto;
  min-width: 250px;
  padding: 10px 15px;
  float: left;
  font-size: 20px;
  line-height: 25px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#ExportScript_Welcome_Next_Button2 {
  display: block;
  width: auto;
  min-width: 250px;
  padding: 10px 15px;
  float: left;
  font-size: 20px;
  line-height: 25px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

@media screen and (max-width: 800px) {
  #ExportScript_Welcome_Next_Button1,
  #ExportScript_Welcome_Next_Button1 {
    min-width: 200px;
    font-size: 20px;
  }
}

@media screen and (max-width: 500px) {
  #ExportScript_Welcome_Next_Button1,
  #ExportScript_Welcome_Next_Button1 {
    min-width: 100px;
    font-size: 24px;
  }
}

.Evaluation_ExportScript_Heading2_Div {
  font-size: 20px;
  font-family: "Lato", sans-serif;
  color: #fff;
  box-sizing: border-box;
  margin-top: 0px;
  margin-bottom: 20px;
  line-height: 48px;
  font-weight: 700;
  text-align: left;
}

/* this */
#Evaluaion_ExportScript_Export_Button {
  display: block;
  width: auto;
  min-width: 100%;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  text-transform: none;
}

#Evaluaion_ExportScript_Export_Content1 {
  position: relative;
}

#Evaluaion_ExportScript_Export_Content1_Heading {
  display: inline-block !important;
}

#Evaluaion_ExportScript_Export_Content2 {
  position: relative;
}

#Evaluaion_ExportScript_Export_Content2_Heading {
}
