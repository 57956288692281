#Evaluaion_PatientDemoGraphics_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
    box-sizing: unset;

}

#Evaluaion_PatientDemoGraphics_Text_Wrapper
{
  
  width:75%;
  min-width: 400px;
  max-width: 900px;
  min-height:100vh;

  float:left;
  margin-left:15%;
  
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  
  /* justify-content: center;
  align-items: center; */
}


#Evaluaion_PatientDemoGraphics_Heading2_Div
{
  margin-bottom: 60px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: 'Lato', sans-serif;
  color: #fff;
  box-sizing: border-box;
  

  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_PatientDemoGraphics_Form_Div
{
  font-size: 22px;
  line-height: 28px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;
  width: 100%;

  
  /* vertical-align: middle; 
  display: table-row;  */
}

#Evaluaion_PatientDemoGraphics_Form_Div Input
{
  color:white;
}

#Evaluaion_PatientDemoGraphics_Form_Div .MuiSelect-root
{
  color:white;
}

#Evaluaion_PatientDemoGraphics_Form_Div .MuiSelect-icon
{
  color:white;
}



#Evaluaion_PatientDemoGraphics_Next_Button_Div
{
  display: block;
  margin-top: 10%;
}

#Evaluaion_PatientDemoGraphics_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

.Evaluaion_PatientDemoGraphics_Form_Span1
{
    display:inline-block;
    width:calc((100% / 3) * 2);
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .Evaluaion_PatientDemoGraphics_Form_Span1 {
    width: 100%;
  }
}



.Evaluaion_PatientDemoGraphics_Form_Span2
{
    display:inline-block;
    width:calc(100% / 3);
    margin-bottom: 10px;
    margin-top: 10px;
}

@media screen and (max-width: 768px) {
  .Evaluaion_PatientDemoGraphics_Form_Span2 {
    width: 100%;
  }
}

.Evaluaion_PatientDemoGraphics_Form_Span3
{
    display:inline-block;
    width:100%;
    margin-bottom: 10px;
    margin-top: 10px;
}



#Evaluaion_PatientDemoGraphics_name
{
    margin-bottom: 5px;
    padding: 0px;
    padding-left: 5px;
    padding-right: 5px;
    border-color:#fff !important;
    
   
}

.MuiInput-underline::before
{
    
    border-width: 1px 1px 2px !important;
    border-color: #000 #000 #fff !important;
}

.MuiInput-underline::after
{
    
    border-width: 1px 1px 2px !important;
    border-color: #000 #000 #3898EC !important;
}



.Evaluaion_PatientDemoGraphics_Form_Div_Text
{
    padding-left: 5px;
    width: 100% !important;
}

.MuiInputBase-input
{
    padding-top: 0px !important;
    padding-bottom: 3px !important;
}



