#Evaluaion_CurrentEvaluation_Heading1_Div {
  font-family: Lato,sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 50px;
  line-height: 40px;
  width: 910px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 80px;
}