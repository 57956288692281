.Evaluation_Acknowledge_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    
    position: relative;
    overflow: hidden;
}

.Evaluation_Acknowledge_Content_Wrapper
{
  /* width:70%; */
  /* min-width: 400px; */
  max-width: 1100px;
  position: relative;
  /* line-height: 100vh; */
  /* vertical-align: bottom; */
  /* display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  text-align: center; */
  /* justify-content: center;
  align-items: center; */
}


.Evaluation_Acknowledge_Heading2_Div
{
    font-size: 35px;
   
    font-family: 'Lato', sans-serif;
    color: #fff;
    box-sizing: border-box;

    margin-top: 0px;
    margin-bottom: 20px;
    line-height: 48px;
    font-weight: 700;
    text-align: left;
}

.Evaluation_Acknowledge_Text_Div
{
    margin-bottom: 15px;
    font-size: 24px;
    line-height: 28px;

    margin-top: 0px;
    font-family: 'Lato', sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    text-align: left;
  
  /* vertical-align: middle; 
  display: table-row;  */
}

.Evaluation_Acknowledge_Button_Div_Left
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    /* position: absolute;
    left:0px; */
    margin-right: 40px;
    margin-top:30px
}


.Evaluation_Acknowledge_Button_Div_Right
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    /* position: absolute;
    right:0px; */
    margin-top:30px
}

.Evaluation_Acknowledge_Button_Div_Bottom
{
    /* width:150px; */
    display: inline-block;
    /* margin-left: auto;
    margin-right: auto; */
    position: absolute;
    bottom:20px;
    left:80px;
    max-width: 500px !important;
}

.Evaluation_Acknowledge_Button
{
    display: inline-block !important;
    width:100% !important;
    min-width: 150px !important;
    max-width: 300px !important;

    padding: 15px 25px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 23px !important;
    line-height: 23px !important;  
}

