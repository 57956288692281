.Tutorial_XrayMatching_Matching_Error_Div {
  font-size: 40px;
  color: #c50000;
  font-weight: bold;
}

.Tutorial_XrayMatching_Matching_Correct_Div {
  font-size: 40px;
  color: #b4ec51;
  font-weight: bold;
}

#Evaluaion_XrayMatching_Matching_Content3_Wrapper {
  float: left;
  width: 50px;
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  margin-left: 20px;
}
