#Knee-Replacement {
    color: white;
    margin-top: 10px;
    width: 100%;
}

#Knee-Replacement-small-box-1 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    border: 1px solid black;
    text-align: center;
    margin-top: 20px;
}

#Knee-Replacement-small-box-2 {
    background-color: white;
    color: black;
    font-size: 20px;
    padding: 10px;
    /* border: 1px solid black; */
    text-align: center;
    margin-top: 15px;
}

#Knee-Replacement-heading {
    color: white;
    width: 100%;
}

@media screen and ( max-width : 800px ) {
    #Knee-Replacement-heading {
        padding-left: 13%;
    }

    #Knee-Replacement {
        padding-left: 13%;
    }
}

.Knee-Replacement-Recommendation-Box
{
    text-align: center;
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}