#LearnAbout-box-1
{
    height: 90vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
}


#LearnAbout-inner-box-1
{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 20px;
    margin-right: 10px;
    width: 100%;
}

.LearnAbout-unstyled {
    padding: 0px;
    list-style-type: none;
    border: 4px white solid;
    padding-left: 10px;
    width: 100%;
    min-width: 50vw;
    height: 77%;
    max-width: calc(100% - 20px);
}

#LearnAbout-btn-2
{
    font-family: Lato, sans-serif !important;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    padding-left: 30px !important;
    padding-right: 30px !important;
    position: absolute;
    right: 10px;
}