#Evaluaion_PatientReport_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  line-height: 1.5;
}
#Evaluaion_NewEvaluation_Back_Button_Div1{
  display: block;
  position: absolute;
  bottom:20px;
  left:200px;
}
#Evaluaion_PatientReport_Content_Wrapper {
  float: left;
  margin-left: 15%;
  width: 75%;
  min-width: 400px;
  max-width: 900px;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_PatientReport_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 36px;
  line-height: 40px;
  text-align: center;
  margin-bottom: 30px;
}

.Evaluaion_PatientReport_Text1 {
  font-size: 22px;
  color: white;
}

.Evaluaion_PatientReport_Text2 {
  font-size: 22px;
  color: white;
  margin-top: 15px;
}

.Evaluaion_PatientReport_SubHead1 {
  font-size: 28px;
  font-weight: bold;
}

.Evaluaion_PatientReport_SubHead2 {
  font-size: 30px;
  font-weight: bold;
  text-decoration: underline;
}

.Evaluaion_PatientReport_Question_Div {
  display: block;
  margin-top: 15px;
  font-family: "Lato", sans-serif;
  color: #fff;
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;
}

.Evaluaion_PatientReport_Answer1_Div {
  padding-left: 30px;
}

.Evaluaion_PatientReport_Answer1_Option {
  font-size: 18px;
  line-height: 20px;
  vertical-align: text-bottom;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.Evaluaion_PatientReport_Answer2_Option_Head {
  font-size: 18px;
  line-height: 20px;
  vertical-align: text-bottom;
  text-align: center;
  font-family: "Lato", sans-serif;
  color: #fff;
}

.Evaluaion_PatientReport_Answer2_Option_Name {
  padding-top: 0px;
  margin-top: 0px;
  line-height: 34px;
  vertical-align: text-top;
  font-family: "Lato", sans-serif;
  color: #fff;
  border-bottom: 1px solid #fff;
  font-size: 16px;
}

.Evaluaion_PatientReport_Answer2_Option {
  text-align: center;
}

.Evaluaion_PatientReport_Answer2_Option_Radio {
  color: white !important;
  padding: 5px !important;
  border-bottom: 1px solid #fff !important ;
}

#Evaluaion_PatientReport_Next_Button_Div {
  display: block;
  margin-top: 10%;
  margin-right: 5%;
  position: absolute;
  bottom: 10px;
  right: 10px;
  
}

#Evaluaion_PatientReport_Back_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  left: 70px;
}

#Evaluaion_PatientReport_Next_Button {
  display: block;
  width: auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: "Lato", sans-serif;
  text-transform: none;
}

#Evaluaion_PatientReport_Next_New_Button_Div {
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom: 10px;
  left: 70px;
  border: 3px solid red;
}

.red-emphasis {
  font-family: Lato, sans-serif;
  color: #7b1414;
  font-weight: 700;
}
