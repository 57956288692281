.LearnMore-heading-1
{
    color:white;
    /* padding-left: 50px; */
    font-size: 24px;
    font-family: Lato, sans-serif;
    margin-top: 10px;

}

.LearnMore-heading-1-span
{
    font-weight: 600;
}

#LearnMore-row-1
{
    padding-left: 70px;
}


.LearnMoreGeneral-btn-1 {
    /* width: 150px; */
    margin-top:20px !important;
    font-family: Lato, sans-serif;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    min-width: 150px !important;
    max-width: auto !important;
}