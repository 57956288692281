#XRMMS_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMMS_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMMS_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 26px;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMMS_Text_Div,
#XRMMS_Inner_Text_Container,
#XRMMS_Inner_Image_text {
  font-size: 16px;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMMS_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

#XRMMS_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}
