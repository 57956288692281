#noi-treatment-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:0px;
    left:800px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px;
}

#treatment-a-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:-4000px;
    left:800px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
}

#treatment-b-wrapper
{
    width:900px;
    height:860px;
    position: relative;
    top:-0;
    left:0px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
    
}

#treatment-c-wrapper
{
    width:900px;
    height:1100px;
    position: relative;
    top:-0;
    left:0px;
    z-index: 1;
    padding-top:40px;
    padding-bottom:20px; 
}