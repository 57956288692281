#Evaluaion_CurrentEvaluation_Heading1_Div {
  font-family: Lato,sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 50px;
  line-height: 40px;
  width: 910px;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 80px;
}

#Download_Single_Summaries_Div
{
  display: inline-block;
}

.Download_Single_Summaries_Div
{
  color: white;
  margin-right: 40px;
  display: inline-block;
  font-size: 24px;
  vertical-align: top;
}

.Download_Both_Summaries_Div
{
  color: white;
  margin-bottom: 40px;
  font-size: 24px;
}

.Summaries_Download_Buttons {
  font-family: Lato, sans-serif;
  font-size: 20px !important;
  text-transform: none !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  /* min-width: 150px !important; */
  width: 200px !important;
  font-weight: bold !important;
  margin-top: 20px !important;
}