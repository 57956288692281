.Evaluation_IncompleteEvaluation_Button_Div
{
    display: inline-block;
    margin-left: 15px;
    margin-right: 15px;
    margin-top:60px;
    width:calc(50% - 30px)
}

.Evaluation_IncompleteEvaluation_Button
{
    display: block !important;
    width:100% !important;
    min-width: 150px !important;
    padding: 15px 15px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
    font-size: 16px !important;
    line-height: 23px !important;  
}