#KDAA_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#KDAA_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#KDAA_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#KDAA_Text_Div,
#KDAA_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#KDAA_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

#KDAA_Image_Heading {
  color: #fff;
  text-align: center;
}
#KDAA_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
  width: 95%;
}

#KDAA_Inner_Image_Container {
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 48%;
}

#KDAA_Inner_Image_Container img {
  width: 100%;
}
#KDAA_Next_Button_Div {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

#KDAA_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #KDAA_Heading1_Div {
    font-size: 3vw;
  }

  #KDAA_Text_Div,
  #KDAA_Image_Heading {
    font-size: 2vw;
  }
  #KDAA_Next_Button {
    font-size: 1.9vw;
  }
}
