#Medent-Form-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
    line-height: normal;
}

* {
    font-family: Lato, sans-serif;
}

#Medent-Form-box_1 {
    height: 85vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#Medent-Form-heading {
    color: white;
    padding-left: 18px;
    font-size: 30px;
}

#Medent-Form-header1 , #Medent-Form-header2 , #Medent-Form-header3 , #Medent-Form-header4 {
    margin-bottom: 10px;
}

#Medent-Form-div_1 , #Medent-Form-div_2 , #Medent-Form-div_3 , #Medent-Form-div_4 {
    color: white;
    font-size: 20px;
    padding-left: 20px;
    margin-top: 25px;
}

.Medent-Form-Same {
    margin-left: 30px !important;
}

#Medent-Form-handler1 {
    margin-left: 70px;
    margin-top: 100px;
}

#Medent-Form-handler2 {
    margin-left: 70px;
}

#Medent-Form-handler3 {
    margin-left: 40px;
}

#Medent-Form-input-field1 , #Medent-Form-input-field2 , #Medent-Form-input-field3 {
    width: 120px;
    font-family: Lato, sans-serif;
}

#Medent-Form-input-field2 , #Medent-Form-input-field3 {
    margin-left: 20px;
}

#Medent-Form-last2 {
    margin-left: 154px !important;
}

#Medent-Form-last3 {
    margin-left: 43px !important;
}

#Evaluaion_RequiredReminder_Next_Button_Div {
    height: 15vh;
}

.Medent-Form-btn-layer1 {
    flex-grow: 1;
}

.Medent-Form-btn-layer2 {
  flex-grow: 1;
  display: flex;
  justify-content: flex-end;
}

#Evaluaion_MedentForm_Next_Button {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
  }

#Evaluaion_MedentForm_Next_Button_1 {
    display: block;
    width: auto;
    min-width: 200px;
    padding: 10px 20px;
    float: left;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
}

  @media screen and ( max-width: 800px ) {
    #Evaluaion_RequiredReminder_Next_Button , #Evaluaion_RequiredReminder_Next_Button_1 {
        min-width: 200px;
        font-size: 20px;
    }
}

@media screen and ( max-width : 500px ) {
    #Evaluaion_RequiredReminder_Next_Button , #Evaluaion_RequiredReminder_Next_Button_1 {
        min-width: 100px;
        font-size: 24px;
    }
}

#Medent-Form-Wrapper {
    margin: 0px 50px 0px 50px !important;
}