
#Evaluation_SinglePDF_Content_Wrapper {
    height: calc(100vh - 80px);
    /* margin: auto; */
    display: flex;
    flex-direction: column;
    /* width: 100vw; */
    justify-content: center;
    align-items: center;
    text-align: left;
    padding-top: 80px;
}

#Evaluaion_PDF_Summary_Image
{
  text-align: center;
}


.Download_Single_Summaries_Div_Button {
    margin: auto !important;
    /* display: block !important; */
    width: -webkit-max-content !important;
    width: max-content !important;
    max-width: calc(100% - 50px) !important;
    min-width: 200px !important;
    padding: 15px 25px !important;
    /* float: right; */
    font-size: 28px !important;
    line-height: 32px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: "Lato",sans-serif !important;
    text-transform: none !important;
    margin-bottom: 20px !important;
    display: block !important;
  }

  #Evaluaion_SinglePDF_Download_Button_Div
  {
    text-align: center;
  }

#Evaluaion_PDF_Summary_Head
{
    color: white;
    /* display: inline-flex; */
    font-size: 26px;
    font-weight: 200px;
    transform: translateY(0px);
    margin-top: 30px;
    text-align: center;
}