#Evaluation_Main_Div
{
    /* background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    height:100vh;
    width: 100vw; */
    box-sizing: unset !important;
    line-height: 1;
}

#Evaluation_Main_Div_Top_Name
{
    position: absolute;
    top:0px;
    right:20px;
    width:118px;
    min-height:14px;
    color:#333333;
    font-family: 'Lato', sans-serif;
    font-size: 20px;
    line-height: 20px;
    font-weight: 700;
    padding: 12px;
    background: #fff;
    border: 1px solid #000;
    z-index: 4;
    text-align: center;
}

@media only screen and (max-width:1630px)  and (max-height: 950px)
{
    #Evaluation_Main_Div_Top_Name
    {
        right:0px;
    }
}