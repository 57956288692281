
#Report_Feedback_Welcome_Box1
{
    margin-top: 20px;
    height: calc(90vh - 20px);
}

#Report_Feedback_Heading_1
{
    background: white;
    color:#3941C1;
    font-size: 2rem;
    font-weight: bold;
    width: fit-content;
    padding:10px 50px;
    padding-right: 100px;
    margin-bottom: 20px;

}

#Report_Feedback_Heading_2
{
    color: white;
    font-size: 2rem;
    padding-left: 50px;
    margin-bottom: 20px;
}

#Report_Feedback_Div_2
{
    font-size: 25px;
    line-height: 40px;
    margin-top: 0px;
    margin-bottom: 30px;
    font-family: Lato, sans-serif;
    color: #fff;
    font-weight: 400;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.Report_Feedback_Buttons
{
    font-family: Lato, sans-serif;
    font-size: 20px !important;
    text-transform: none !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    /* min-width: 150px !important; */
    width: 350px !important;
    font-weight: bold !important;
    margin-top: 20px !important;
}

#Report_Feedback_Chat_Wrapper
{
    width: 300px;
    height: 400px;
    max-height: 80vh;
    position: absolute;
    right:0px;
    outline: black;
    border:1px solid black;

}

#Report_Feedback_Chat_Head
{
    background: #b4ec51;
    color: black;
    height: 40px;
    width: 100%;
    text-align: center;
    justify-content: center;
    line-height: 40px;
    font-weight: bold;
}

#Report_Feedback_Chat_Bottom
{
    position: absolute;
    bottom: 0px;
    background: grey;
    height: 40px;
    width: 100%;
    padding: 10px;
}

#Report_Feedback_Chat_Type
{
    background: rgb(255,255,255,0.4);
    border-radius: 20px;
    padding: 10px;
    color: rgb(255,255,255,0.9);
    width:calc(100% - 100px);
    height: calc(100% - 20px);
    display: inline-block;
    vertical-align: middle;
    margin-right: 20px;
}

#Report_Feedback_Chat_Send
{
    display: inline-block;
    background: #b4ec51;
    border-radius: 50%;
    vertical-align: middle;
    padding: 7px 7px;

}
      