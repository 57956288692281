#HTIJSNAD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#HTIJSNAD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#HTIJSNAD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#HTIJSNAD_Text_Div,
#HTIJSNAD_Image_Heading {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#HTIJSNAD_Inner_Wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  margin: 5px;
}
#HTIJSNAD_Image_Pair_Wrapper {
  display: flex;
  flex-direction: row;
}

#HTIJSNAD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}
/* #HTIJSNAD_Inner_Wrapper,
#HTIJSNAD_Inner_text,
#HTIJSNAD_Inner_Image_Container {
  border: 1px solid red;
} */
#HTIJSNAD_Image_Heading {
  color: #fff;
  text-align: center;
  display: inline-block;
  width: 50%;
  vertical-align: middle;
}
#HTIJSNAD_Image_container {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  flex-direction: row;
  align-items: stretch;
}

#HTIJSNAD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

#HTIJSNAD_Inner_Image_Container img {
  width: 100%;
  min-width: 125px;
  height: 30vh;
  object-fit: contain;
}

.HTIJSNAD_Inner_text {
  color: #fff;
  font-size: 1.2vw;
  text-align: center;
  padding: 5px 0;
  align-self: stretch;
}

#HTIJSNAD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #HTIJSNAD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    max-width: 750px;
  }
}

#HTIJSNAD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #HTIJSNAD_Heading1_Div {
    font-size: 2.8vw;
  }

  #HTIJSNAD_Text_Div,
  #HTIJSNAD_Image_Heading {
    font-size: 1.9vw;
  }
  #HTIJSNAD_Next_Button {
    font-size: 1.9vw;
  }

  #HTIJSNAD_Inner_text {
    font-size: 1.8vw;
  }
}
