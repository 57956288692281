#Evaluation_PDF_MAIN_DIV
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
}

#Evaluation_PDF_Content_Wrapper
{
    /* style={{height:'100vh',margin:'auto',display:'flex',justifyContent:'center',alignItems:'center'}} */
  height:calc(100vh - 80px);
  /* margin: auto; */
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: left;
  padding-top: 80px;

}

.report-canvas-div
{
    /* margin:auto; */
  height:861px;
  max-height: 80vh;
  overflow: auto;
  /* max-width: 612px; */
  width:calc(100vw - 500px);
  max-width:calc(100vw - 500px);
  margin-left: 120px;
}

.report-navbar-wrapper
{
 background: black;
 width:595px;
 margin-left: 120px !important;


}

.report-navbar-wrapper .column 
{
  margin:0px;
  padding: 0px;
  flex-grow:1;
}

#Evaluaion_pdf_Heading1_Div
{
  font-family: 'Lato', sans-serif;
  color: #fff;
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
  font-size: 38px;
  line-height: 40px;
  text-align: left;
  margin-bottom: 10px;
}


#Evaluaion_pdf_Container
{
  width: calc(100% - 150px);
  height: 100%;
  margin-left:150px;
}

#Evaluaion_pdf_child1
{
  width: 70%;
  display: inline-block;
  height: 100%;
}

#Evaluaion_pdf_child2
{
  width: calc(30% - 20px);
  display: inline-block;
  position: relative;
  height: 100%;
  padding-left: 10px;
  padding-right: 10px;
  vertical-align: top;
}

.Evaluation_PDF_Button_Div
{
  position: relative;
  left:50%;
  transform: translateX(-50%);
  max-width:calc(100% - 40px);
  width: auto;
}

@media only screen and (min-width:1300px)
{
  .Evaluation_PDF_Button_Div
  {
    max-width:300px;
  }
}

#Evaluaion_PDF_Download_Button_Div
{
  top:100px;
}

#Evaluaion_PDF_RCP_Button_Div
{
  bottom:200px;
}

#Evaluaion_PDF_Back_Button_Div
{
  bottom:10px;
  position: absolute;
  /* left:50%;
  transform: translateX(-50%) */
}

#Evaluaion_PDF_Back_Home_Button_Div
{
  bottom:10px;
}

#Evaluaion_PDF_Button
{
  display: block;
  width:max-content;
  max-width: 100%;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}
#Evaluation_Print_Button{
  display: block;
  width:max-content;
  max-width: 100%;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  margin-top: 10px;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}
#Evaluaion_pdf_iframe_wrapper
{
  height: calc(100% - 100px);
  max-width: 1000px;
}