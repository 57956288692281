.zoom-pan figure:hover  img{

    opacity: 0;

    /* width:10% */
    /* width: 400px;
    height: 100px;*/
  }
  .zoom-pan figure:hover
  {
    transform: scale(1); 
    background-size: 250% 250%;

  }
  
  .zoom-pan img {
   
    display: block;    
    pointer-events: none;
    max-width: 100%;
    max-height:320px;
  }
  
  .zoom-pan {
    
    display: flex;
    justify-content: center;
    align-items: center;
  }