#Evaluaion_PatientProfile_Main_Div
{
  background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
  min-height: 100vh;
  position: relative;
  overflow: hidden;
}

#Evaluaion_PatientProfile_Content_Wrapper
{
  margin-left:10%;
  width:90%;
  /* min-width: 400px; */
  max-width: 100vw;
  min-height:100vh;
  
  display: flex;
  justify-content: center;
  flex-direction: row;
  align-items: center;
  
  /* justify-content: center;
  align-items: center; */
}


#Evaluaion_PatientProfile_Content1_Wrapper
{
  /* float:left; */
  width:45%;
  margin-right: 20px;
  /* min-width: 400px;
  max-width: 700px; */
  /* height:100vh; */
  display: inline-block;
  /* justify-content: center;
  flex-direction: column;
  justify-content: center; */
  
  /* justify-content: center;
  align-items: center; */
}

#Evaluaion_PatientProfile_Heading1_Div
{
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: inline-block;
}

#Evaluaion_PatientProfile_Profile_Div
{
    font-weight: 400;
    display: block;
    margin-bottom: 20px;
    height:77px;
}
#Evaluaion_PatientProfile_Profile_Avatar_Div
{
    display: inline-block;
}

.Evaluaion_PatientProfile_Profile_Avatar
{
    width:75px !important;
    height:75px !important;
}

#Evaluaion_PatientProfile_Profile_Text_Div
{
    width:116px;
    min-height:65px;
    border: 2px solid #fff;
    color: #fff;
    font-family: 'Lato', sans-serif;
    display: inline-block;
    vertical-align: top;
    margin-left:15px;
    padding:10px 10px 0px;
    text-align: center;
}



#Evaluaion_PatientProfile_Heading2_Div
{
  margin-bottom: 15px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Evaluaion_PatientProfile_Box1_Div
{
    width:calc(100% - 22px);
    padding:10px 10px;
    border-radius: 5px;
    border:1px solid #fff;
    height:330px;
}

.Evaluaion_PatientProfile_Box1_Content1
{
    width:20%;
    background:#BBBBBB;
    height:100px;
    display: inline-block;
    align-items: center;
    align-content: ce5nter;
    text-align: center;
    justify-content: center;
     
}
.Evaluaion_PatientProfile_Box1_Content1_Image_Wrapper
{
  margin: auto;
  justify-content: center;
  align-items: center;
  display: flex;
  height: 100%;
}

.Evaluaion_PatientProfile_Box1_Content1_Image
{
  display: block;
  width: 50px;
}

.Evaluaion_PatientProfile_Box1_Content1_Image_Custom
{
  width: auto;
  max-width: 100px;
  max-height: calc(100% - 25px);
  height: auto;
}

.Evaluaion_PatientProfile_Box1_Content2
{
    width:calc(80% - 20px);
    height:100px;
    /* line-height: 100px; */
    display: inline-block;
    padding-left:10px;
    padding-right:5px; 
    justify-content: center;
    text-align: center;
    align-items: center;
    vertical-align: top;
}

.Evaluaion_PatientProfile_Box_Button
{
  display: block;
  width:100%;
  height:57px;
  min-width: 300px;
  padding: 15px 25px;
  font-size: 32px !important;
  line-height: 32px !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  font-family: 'Lato', sans-serif !important;
  font-weight: bold !important;
  text-transform: none !important;
}

.Evaluaion_PatientProfile_Disable_Box
{

}

.Evaluaion_PatientProfile_Box_DisbaleText3
{
  /* width:100%;
  line-height: 100%;
  height:100%; */
  position: relative;
  display: block;
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 40px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_PatientProfile_Box_DisbaleButton
{
  opacity: 0.7 !important;
  height: 50px !important;
  margin-bottom: 10px !important;
}


.Evaluaion_PatientProfile_Box_DisbaleText
{
  /* width:100%;
  line-height: 100%;
  height:100%; */
  display: block;
  background-color: hsla(0, 0%, 100%, 0.23);
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: bold;
  opacity: 0.5;
  font-size: 32px;
  line-height: 50px;
  font-family: 'Lato', sans-serif;
  color: #fff;
}

.Evaluaion_Forms_Box_DisbaleText2
{
  /* width:100%;
  height:100%; */
  width:120px;
  background-color: #81bd17;
  text-align: center;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  font-size: 20px;
  line-height: 14px;
  font-family: 'Lato', sans-serif;
  color: #fff;
  padding: 11px;
  margin-top:10px;
}


#Evaluaion_PatientProfile_Content2_Wrapper
{
  /* float: left; */
  display: inline-block;
  /* justify-content: center;
  flex-direction: column;
  justify-content: center;
  height:100vh; */
  width:calc(55% - 20px);
  /* margin-left: 5%; */
  margin-top: 150px;
  
  /* justify-content: center;
  align-items: center; */
}

.Evaluaion_PatientProfile_Image_Left_Div
{
  display: inline-block;
  vertical-align: top;
  margin-top:140px;
  text-align: center;
  transform: translateX(10px);
}

.Evaluaion_PatientProfile_Image_Right_Div
{
  display: inline-block;
  vertical-align: top;
  margin-top:140px;
  text-align: center;
  transform: translateX(30px);
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down
{
  display:block;
  margin-top:53px;
}


.Evaluaion_PatientProfile_Image_Left_Inner_Up
{
  display:block;
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down
{
  display:block;
  margin-top:53px;
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1
{
  display: inline-block;
  vertical-align: top;
  margin-right: 5px;
  transform: translateY(-20px);
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content2
{
  display:inline-block;
  vertical-align: top;
  transform: translateY(-30px);
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down_Content1
{
  transform: translateY(50);
  display: inline-block;
  vertical-align: top;
  margin-left: 5px;

}

.Evaluaion_PatientProfile_Image_Right_Inner_Up
{
  display: block;
}

  .Evaluaion_PatientProfile_Image_Right_Inner_Up_Content2
  {
    display:inline-block;
    vertical-align: top;
  }


.textbox-height
{
  height: 60px !important;
  font-size:20px !important;
  color:white !important;
  border-radius:0px !important;
}

.Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1 fieldset {
  border-radius: 0;
}

.Evaluaion_PatientProfile_Image_Right_Inner_Down_Content1 fieldset {
  border-radius: 0;
}

#Evaluaion_PatientProfile_Image_Bone_Div
{
  width:260px;
  display: inline-block;
  height: calc(100vh - 160px);
}


#Evaluaion_PatientProfile_Image_Bone
{
  max-width: 100%;
  max-height: 100%;
}

.patient-demographics-select-box > .MuiMenu-paper
  {
    background: rgba(0,0,0,0.8) !important;
    border-radius: 0px !important;
    margin-top:0px;
  }

  .patient-demographics-select-box > .MuiMenu-paper > ul
  {
    border:1px solid white;
    width:calc(100% - 2px);
    /* margin:auto; */
  }


/* Responsive css */

@media only screen and (max-height:660px)
{
  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 150px;
  }
  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 150px;
  }
}

@media only screen and (max-width:1417px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    width:40%
  }
  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% - 20px);
  }
}


@media only screen and (max-width:1350px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:0px;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% - 0px);
  }
  
}

@media only screen and (max-width:1260px)
{
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:-10px;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width:calc(60% + 10px);
  }
}

@media only screen and (max-width:1260px)
{
  #Evaluaion_PatientProfile_Content_Wrapper
  {
    width:93%;
    margin-left:7%;
  }
  
}

@media only screen and (max-width:1050px)
{
  #Evaluaion_PatientProfile_Image_Bone_Div
  {
    width:250px
  }

  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 140px;
  }

  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 140px;
  }

}

@media only screen and (max-width:950px)
{
  #Evaluaion_PatientProfile_Content_Wrapper
  {
    width:95%;
    margin-left:5%;
  }
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right: 1%;
  }

  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    width: 59%;
  }
  
}

@media only screen and (max-width:877px)
{
  #Evaluaion_PatientProfile_Image_Bone_Div
  {
    width:220px
  }

  #Evaluaion_PatientProfile_Content_Wrapper
  {
    white-space: nowrap;
    margin-left:4%;
    width: 96%;
  }

  .Evaluaion_PatientProfile_Image_Left_Div
  {
    margin-top: 120px;
  }

  .Evaluaion_PatientProfile_Image_Right_Div
  {
    margin-top: 120px;
  }


  #Evaluaion_PatientProfile_Content2_Wrapper
  {
    /* transform: translateX(30px); */
    width:calc(51%)
  }
  #Evaluaion_PatientProfile_Content1_Wrapper
  {
    margin-right:10px;
    width: calc(42% + 20px);
    white-space: pre-wrap;
  }

  .Evaluaion_PatientProfile_Image_Left_Inner_Down_Content1
  {
    margin-right:5px
  }
  
}

