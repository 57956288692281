.Joint-Rcp-Image-Header-Hr
{
    
}

.Joint-Rcp-Image-Inner-Header
{
    font-size: 30px;
    margin: 40px;
    font-weight: bold;

}
.Joint-Rcp-Image-Box-Wrapper
{
    margin:auto;
    width: fit-content;
    border: 1px solid black;
    padding: 20px 40px;
}


.Joint-Rcp-Image-Compartment-Box-Wrapper
{
    display: inline-block;
    width:160px;
    text-align: center;
}

.Joint-Rcp-Image-Compartment-Head
{
    font-size: 24px;
    margin-bottom: 20px;
    font-weight: 600;
}

.Joint-Rcp-Image-Compartment-Box
{
    width:100%;
    height:160px;
    border: 1px solid black;
    font-size: 20px;
    font-weight: 600;
}

.Joint-Rcp-Image-Score-Box-Wrapper
{
    display: inline-block;
    width:160px;
    margin-left: 20px;
    text-align: center;
}


.Joint-Rcp-Image-Score-Box
{
    width: 100%;
    height: 160px;
    border-radius: 50%;
    font-size: 20px;
    font-weight: 600;
    border: 1px solid black;
}

.Centered
{
    vertical-align: middle;
    display: inline-block;
}

.Centerer
{
    display: inline-block;
    height: 100%;
    vertical-align: middle;
}


.Joint-Rcp-Image-Compartment-Hr
{
    width: calc(100% + 80px);
    transform: translateX(-40px);
    background-color: black;
    height: 1px;
    margin:40px 0px 40px;
}

.Joint-Rcp-Image-Compartment-Path
{
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 600;
}

.margin-top-20
{
    margin-top: 40px;
}

.Joint-Rcp-Image-OI-List-Wrapper
{
    margin-top:20px
}

.Joint-Rcp-Image-OI-List-Left
{
    display: inline-block;
    width: 50%;
    vertical-align: top;

}

.Joint-Rcp-Image-OI-List-Right
{
    display: inline-block;
    width: 50%;
    vertical-align: top;

}

.Joint-Rcp-Image-OI-List-Item
{
    display: block;
    width: 100%;
    margin: 30px 0px;
}

.Joint-Rcp-Image-OI-List-Box
{
    width:20px;
    height: 20px;
    border:2px solid black;
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.Joint-Rcp-Image-OI-List-Text
{
    display: inline-block;
    width: calc(100% - 34px);
    font-size: 24px;
    font-weight: 600;
}

.image-compartment-label-wrapper
{
    position: absolute;
    height: 23px;
    width: 125px;
    background:blue;
    text-align: center;
    justify-content: center;
    padding-top: 3px;
}

.image-compartment-label-wrapper-1 /* right label 1*/ /*kneecap*/
{
    bottom:271px !important;
    left:102px !important;
}

.image-compartment-label-wrapper-2 /* right label 2*/ /*Lateral*/
{
    bottom:168px !important;
    left:104px !important;
    height: 24px !important;
}


.image-compartment-label-wrapper-3 /* right label 3*/ /*Medial*/
{
    bottom:182px !important;
    left:571px !important;
    height: 24px !important;
}


.image-compartment-label-wrapper-4 /* left label 1*/
{
    bottom:271px !important;
    left:565px !important;
}

.image-compartment-label-wrapper-5    /* left label 2*/
{
    bottom:182px;
    left:97px;
    height: 24px;
}

.image-compartment-label-wrapper-6 /* left label 3*/
{
    bottom:168px;
    left:566px;
    height: 24px;
}

.image-compartment-label
{
    font-size: 15px;
}