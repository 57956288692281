#GetImage_Report_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    margin: auto;
    position: relative;
    overflow: hidden;
    z-index: 2;
    
}

.ImageReportMainDiv
{
    width:800px;
    margin:auto;
    padding:10px;
    padding-top:40px;
    padding-left:20px;
    padding-right:20px;
    position: relative;
    /* background: #53a0fd; */
}


.GetImage_Report_Box_Text1
{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #000000;
    font-family: 'Lato',sans-serif;
}

.GetImage_Report_Box_Text2
{
    font-size: 40px;
    line-height: 48px;
    font-weight: 700;
    color: #000000;
    font-family: 'Lato',sans-serif;
}



.GetImage_Report_Box_Priority
{
    font-size: 32px;
    line-height: 48px;
    font-weight: 400;
    color: #fff;
    font-family: 'Lato',sans-serif;
}

.GetImage_Report_Box_Priority_Box
{
    border:1px solid #fff;
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    color: #fff;
    font-family: 'Lato',sans-serif;
    padding: 10px 20px;
    
    /* display:block */
}

.GetImage_Report_Box_Text3
{
    font-size: 20px;
    line-height: 30px;
    color: #000000;
    font-family: 'Lato',sans-serif;
}

.bold
{
    font-weight: bold;
}


.GetImage_Report_Box_Text4
{   
    height:100px;
    width:121px;
    /* background: #3023ae; */
    /* padding-top: 5px; */
    transform: rotate(-45deg) translateY(-27px) translateX(80px);
    font-size: 18px;
    line-height: 30px;
    color: #000000;
    font-family: 'Lato',sans-serif;
    word-wrap: nowrap;
}

.GetImage_Report_Box_Text5
{
    
    text-align: center;
    font-family: Lato, sans-serif;
    color: #000000;
    height: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
    font-size: 20px;
    font-weight: 400;
}

.GetImage_Report_Box_Selected_Box
{
    display: flex;
    height: 100%;
    justify-content: center;
    align-items: center;
}



.GetImage_Report_Box_Button_Div
{
  
  width:calc(100% - 10px);
  padding-left:5px;
  height:40px;
  margin: auto;
  position: absolute;
  top:7px;
}

.GetImage_Report_Box_Button
{
    display: block !important;
    width:100% !important;
    height:100% !important;
    margin: auto !important;
    /* padding: 15px 0px; */
    font-size: 18px !important;
    line-height: 18px !important;
    border-radius: 25px !important;
    background-color: #b4ec51 !important;
    font-family: 'Lato', sans-serif !important;
    font-weight: bold !important;
    text-transform: none !important;
}

.GetImage_Report_Box_Search_Box
{
   position:absolute;
   border-radius:10px; 
   bottom:10px;
   left:10px;
   padding:10px 10px 7px;
   background:#B4EC51;
}

.GetImage_Report_Box_Note_Box
{
    position:absolute;
    border-radius:5px; 
    bottom:0px;
    right:0px;
    padding:10px 10px 2px;
    background:#B4EC51;
 }

 #GetImage_Report_Next_Button_Div
{
  display: block;
  margin-top: 10%;
  position: absolute;
  bottom:10px;
  right:10px;
}

#GetImage_Report_Next_Button
{
  display: block;
  width:auto;
  min-width: 200px;
  padding: 15px 25px;
  float: right;
  font-size: 20px;
  line-height: 20px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: 'Lato', sans-serif;
  text-transform:none;
}

.xray-report-card-header
{
    width:calc(100% - 25px);
    /* margin:auto; */
    min-height:58px;
    background-image:linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    color: white;
    font-size: 30px;
    padding-left:20px;
    font-weight: bold;
    margin-bottom: 20px;
    line-height: 50px;    
}

.report-image-patient-info-div
{
    display: block;
    height:300px
}

.report-image-patient-info-box
{
    display:block;
    margin:10px;
    
}

.report-image-patient-info-left
{
   float: left;
   /* max-width: 300px; */
   /* background: #b4ec51  ; */
}

.report-image-patient-info-right
{
   float: right;
}

.report-image-patient-info-right-inner-text
{
    width:180px;
    padding-left: 20px;
    display:inline-block;
}

.pdf-page-number-header
{
    position: absolute;
    right:25px;
    transform : translateY(-25px);
    margin-bottom: 10px;
    font-weight: bold;
}

.margin-right-10
{
    margin-right: 10px;
}

.GetImage_Report_AI_BG
{
    background-repeat: no-repeat !important;
    background-size: contain !important;
    color: black;
}

.GetImage_Report_PREDICTION_SPAN
{
    font-size: 13px;
    font-weight: bold;
    text-align: center;
    color: black;


}