



.test_span{
    color: #FCB23C;
    font-weight: bold;
    font-size:3rem;
    margin-left: 10px;
}

