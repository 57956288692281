#JSNLC_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.complete_div{
    width: 150px;
    font-size: 1.5rem;
     height: 50px;
      display: flex;
    background-color: #B7D555;
     align-self: center;
    align-items:center;
    color:white
}
#JSNLC_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSNLC_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 10px;
  display: block;
  font-weight: bold;
}

#JSNLC_Text_Div,
#JSNLC_Inner_Text_Container,
#JSNLC_Inner_Image_text,
#JSNLC_box_Container > div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#JSNLC_Inner_Text_Container {
  text-align: center;
}

#JSNLC_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#JSNLC_Inner_Image_text div {
  width: 100%;
}

#JSNLC_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#JSNLC_Grid_Container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  justify-content: center;
}

#JSNLC_Row_Container {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  justify-content: space-around;
  margin: 5px 0;
}

#JSNLC_box_Container {
  margin: 5px;
  background-color: #d3d3d3;
  width: 100%;
  cursor: pointer;
  height: 10.47vw;

}

#JSNLC_box_Container > div {
  height: 100%;
  background-color: #fa9e2d;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
}

#JSNLC_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#JSNLC_Image_Wrapper img {
  width: 100%;
}

#JSNLC_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #JSNLC_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#JSNLC_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #JSNLC_Heading1_Div {
    font-size: 2.8vw;
  }

  #JSNLC_Text_Div,
  #JSNLC_Inner_Text_Container,
  #JSNLC_Inner_Image_text,
  #JSNLC_box_Container > div {
    font-size: 1.9vw;
  }
  #JSNLC_Next_Button {
    font-size: 1.9vw;
  }
}
