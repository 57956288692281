#XRMALOD_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}

#XRMALOD_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 0rem 0rem 0rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#XRMALOD_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  margin-bottom: 2px;
  display: block;
  font-weight: bold;
}

#XRMALOD_Text_Div,
#XRMALOD_Inner_Text_Container,
#XRMALOD_Inner_Image_text {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
}

#XRMALOD_Inner_Text_Container {
  text-align: center;
}

#XRMALOD_Inner_Image_text {
  display: flex;
  flex-direction: row;
  align-self: stretch;
  text-align: center;
  padding: 5px;
}

#XRMALOD_Inner_Image_text div {
  width: 100%;
}

#XRMALOD_Image_Wrapper {
  margin-top: 15px;
  border: 3px solid white;
  padding: 5px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  /* justify-content: center; */
}

#XRMALOD_Inner_Image_Container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 10px;
}

#XRMALOD_Image_Wrapper img {
  width: 100%;
}

#XRMALOD_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media screen and (max-width: 935px) {
  #XRMALOD_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
    /* max-width: 650px; */
  }
}

#XRMALOD_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #XRMALOD_Heading1_Div {
    font-size: 2.8vw;
  }

  #XRMALOD_Text_Div,
  #XRMALOD_Inner_Text_Container,
  #XRMALOD_Inner_Image_text {
    font-size: 1.9vw;
  }
  #XRMALOD_Next_Button {
    font-size: 1.9vw;
  }
}
