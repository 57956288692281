#Tutorials_SBS_Welcome_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;

  position: relative;
  overflow: hidden;
}

#Tutorials_SBS_Welcome_Image_div {
  float: left;
  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;
  height: 100vh;
  width: 300px;
  max-width: 45%;
}

#Tutorials_SBS_Welcome_Image_Bone {
  /* max-height:calc(100vh - 40px);
  min-height: 100px;
  width:auto;
  height:auto; */
  width: 300px;
  margin-top: 50px;
}

#Tutorials_SBS_Welcome_Text_Wrapper {
  float: left;
  margin-left: 15%;
  width: 70%;
  min-width: 400px;
  max-width: 700px;
  height: 100vh;
  line-height: 100vh;

  display: flex;
  justify-content: center;
  flex-direction: column;
  justify-content: center;

  /* justify-content: center;
  align-items: center; */
}

#Tutorials_SBS_Welcome_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  font-size: 75px;
  line-height: 75px;
  margin-bottom: 10px;
  display: block;
}

#Tutorials_SBS_Welcome_Neon_Line {
  width: 100px;
  height: 3px;
  margin-top: 35px;
  margin-bottom: 50px;
  background-color: #b4ec51;
  box-sizing: border-box;
}

#Tutorials_SBS_Welcome_Heading2_Div {
  margin-bottom: 15px;
  font-size: 34px;
  line-height: 48px;
  font-weight: 700;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
  /* vertical-align: middle; 
  display: table-row; */
}

#Tutorials_SBS_Welcome_Text_Div {
  font-size: 30px;
  line-height: 40px;
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: Lato, sans-serif;
  color: #fff;
  font-weight: 400;
  box-sizing: border-box;

  /* vertical-align: middle; 
  display: table-row;  */
}
#Tutorials_SBS_Welcome_Next_Button_Div {
  display: block;
  margin-top: 20px;
}

@media only screen and (min-height: 700px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 60px;
  }
}

@media only screen and (min-height: 750px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 80px;
  }
}

@media only screen and (min-height: 800px) {
  #Tutorials_SBS_Welcome_Next_Button_Div {
    margin-top: 100px;
  }
}

#Tutorials_SBS_Welcome_Next_Button {
  display: block;
  width: auto;
  min-width: 300px;
  padding: 15px 25px;
  float: left;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}
