/* same as welcome.css */

#Evaluaion_XrayMatching_Intro_Main_Div
{
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;
    max-height: 100vh;
    position: relative;
    overflow: hidden;
    z-index: 2;
    box-sizing: unset;
}