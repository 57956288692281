#LATMALCOTKITX_Main_Div {
  background-image: linear-gradient(
    119deg,
    #3023ae,
    #53a0fd 47%,
    #6eb6cc 81%,
    #b4ec51
  );
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
}
.gradient_div {
  height: 50px;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 5px;
  /* background: linear-gradient(0.25turn, #3C78D8, #296fe0); */
}
.test_span {
  color: #fcb23c;
  font-weight: bold;
  font-size: 2rem;
  margin-left: 10px;
}
#LATMALCOTKITX_Content_Wrapper {
  margin: auto;
  min-width: 700px;
  width: 58%;
  /* max-width: 830px; */
  height: 100vh;
  padding: 0rem 1rem 0rem 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

#LATMALCOTKITX_Heading1_Div {
  font-family: Lato, sans-serif;
  color: #fff;
  font-size: 2vw;
  display: block;
  font-weight: bold;
}

#LATMALCOTKITX_Text_Div {
  font-size: 1.3vw;
  font-family: Lato, sans-serif;
  color: #fff;
  box-sizing: border-box;
}

#LATMALCOTKITX_Buttons_wrapper {
  padding: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
}
#KDAA_Image_Heading {
  color: #fff;
  text-align: center;
  width: 100%;
  margin-bottom: 10px;
}

#LATMALCOTKITX_Pair_Buttons_wrapper {
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  flex-wrap: wrap;
  flex-grow: 1;
}

#LATMALCOTKITX_Pair_Buttons_wrapper Button {
  margin: 8px 0;
}

#LATMALCOTKITX_Button {
  min-width: 220px;
  padding: 16px 6px;
  font-size: 18px;
  line-height: 17px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
  display: block;
  width: auto;
}

#LATMALCOTKITX_Image_Wrapper {
  border: 3px solid white;
  padding: 15px;
}

#LATMALCOTKITX_Image_Container {
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: flex-start;
}
#LATMALCOTKITX_Image_Container img {
  width: 100%;
  height: 50vh;
  object-fit: contain;
}

#LATMALCOTKITX_Next_Button_Div {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}

@media only screen and (max-width: 760px) {
  #LATMALCOTKITX_Content_Wrapper {
    padding: 0rem 1rem 0rem 1rem;
  }
}

#LATMALCOTKITX_Next_Button {
  display: block;
  width: auto;
  min-width: 220px;
  padding: 16px 6px;
  font-size: 1.3vw;
  line-height: 1.4vw;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and (max-width: 1100px) {
  #LATMALCOTKITX_Heading1_Div {
    font-size: 2.8vw;
  }

  #LATMALCOTKITX_Text_Div {
    font-size: 1.9vw;
  }
  #LATMALCOTKITX_Next_Button {
    font-size: 1.9vw;
  }
}

.tutorial-Complete-Box {
  /* height: 100px; */
  /* width: 100px; */
  background-color: #b4ec51;
  color: white;
  font-size: 24px;
  padding: 10px;
  font-weight: 540;
  vertical-align: middle;
  /* display: inline-block; */
}

.tutorial-Complete-Box-tick {
  display: inline-block;
  vertical-align: middle;
  padding-right: 10px;
}
