/* Screen: 31 Css */

#upload-type-main-container {
    background-image: linear-gradient(
      119deg,
      #3023ae,
      #53a0fd 47%,
      #6eb6cc 81%,
      #b4ec51
    );
    min-height: 100vh;
    position: relative;
    overflow: hidden;
}

#upload-type-box-1 {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#upload-type-box-2 {
    height: 10vh;
    display: flex;
    align-items: center;
}

#upload-type-heading {
    color: white;
    font-family: Lato, sans-serif;
    font-size: 35px;
    margin-bottom: 10px;
}

#upload-type-btn-container-1 , #upload-type-btn-container-2 {
    margin-top: 20px;
}

#upload-type-btn-1 , #upload-type-btn-2 ,#upload-type-btn-3 , #upload-type-btn-4 {
    padding: 10px 20px;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
    text-transform: none;
    width: 300px;
}

#upload-type-btn-1 , #upload-type-btn-3 , #upload-type-btn-4 {
    line-height: 50px;
}

#upload-type-btn-back {
    padding: 10px 20px;
    font-size: 20px;
    line-height: 25px;
    border-radius: 25px;
    background-color: #b4ec51;
    font-family: Lato, sans-serif;
    margin: 0px 10px 0px 10px;
    text-transform: none;
    width: 200px;
    margin-left: 50px;
}