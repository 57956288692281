
#Evaluaion_NewEvaluation_Main_Div {
    background-image: linear-gradient(119deg, #3023ae, #53a0fd 47%, #6eb6cc 81%, #b4ec51);
    min-height: 100vh;   
    position: relative;
    overflow: hidden;
}

.upper-container {
    height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.heading {
    width: 60%;
    color: white;
    font-family: Lato,sans-serif;
    padding-bottom: 40px;
}

.listing {
    width: 60%;
    color: white;
    font-size: 30px;
    font-family: Lato,sans-serif;
}

.listing li
{
    padding-bottom:20px;
}

.btn-box-parent {
    height: 10vh;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
}

.btn-container {
    height: 10vh;
    display: flex;
    flex-direction: row;
    margin-left: 50px;
}

.btn-layer1 {
    flex-grow: 1;
    position: relative;
}

.btn-layer2 {
    flex-grow: 1;
}

#Evaluaion_RequiredReminder_Next_Button_Div2 {
  display: block;
  position: absolute;
  bottom:15px;
  left:5px;
}

#Evaluaion_RequiredReminder_Next_Button_Div1 {
    display: block;
    position: absolute;
    bottom:15px;
    right:5px;
}

/* Re-use from newEvaluation.css */
#Evaluaion_RequiredReminder_Next_Button {
  display: block;
  width:auto;
  min-width: 300px;
  padding: 15px 25px;
  float: right;
  font-size: 28px;
  line-height: 32px;
  border-radius: 25px;
  background-color: #b4ec51;
  font-family: Lato, sans-serif;
}

@media screen and ( max-width: 800px ) {
    #Evaluaion_RequiredReminder_Next_Button {
        min-width: 200px;
    }
}

@media screen and ( max-width : 500px ) {
    #Evaluaion_RequiredReminder_Next_Button {
        min-width: 100px;
    }
}