.xrayImage-pdf-image-head
{
    font-weight: 600;
    font-size: 20px;
}

.pdf-xrays-images-wrapper
{
    width:900px;
    height:1100px;
    background-color:white;
    position:absolute;
    top:-1600px;
    left:2000px;
    padding-top: 40px;
    padding-bottom: 20px;
}