* {
  font-family: Lato, sans-serif;
}

#RCPNOI-form-box-1 {
  display: flex;
  flex-direction: row;
}

.RCPNOI-heading-1-wrapper {
  margin-top: 30px;
}

.RCPNOI-heading-1 {
  display: inline-block;
  color: white;
  font-size: 20px;
  text-decoration: underline;
  vertical-align: baseline;
}

.RCPNOI-heading-1-button-wrapper {
  display: inline-block;
  margin-left: 40px;
  vertical-align: middle;
}

.RCPNOI-heading-1-button-2-wrapper {
  display: inline-block;
  margin-right: 10px;
  vertical-align: middle;
  cursor: pointer;
}

.RCPNOI-heading-1-button {
  font-family: Lato, sans-serif !important;
  font-size: 20px !important;
  text-transform: none !important;
  border-radius: 25px !important;
  background-color: #b4ec51 !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
}

.RCPNOI-heading-2 {
  margin-top: 30px;
  color: white;
  font-size: 18px;
  font-weight: bold;
}

/* changethere */
#RCPNOI-heading {
  font-family: Lato, sans-serif;
  color: #fff;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: 32px;
  line-height: 1.5;
}

.RCPNOI-form-field {
  font-family: Lato, sans-serif;
  color: #fff; 
  font-size: 25px;
  line-height: 1.5;
}

/* Styling for checkbox */

.RCPNOI-styled-checkbox {
  position: absolute;
  opacity: 0;
}
.RCPNOI-styled-checkbox + label {
  position: relative;
  cursor: pointer;
  padding: 0;
}
.RCPNOI-styled-checkbox + label:before {
  content: "";
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: transparent;
  border: 1px solid white;
}

.RCPNOI-styled-checkbox:checked + label:before {
  background: darkblue;
}

.RCPNOI-styled-checkbox:disabled + label {
  color: #b8b8b8;
  cursor: auto;
}
.RCPNOI-styled-checkbox:disabled + label:before {
  box-shadow: none;
  background: #ddd;
}
.RCPNOI-styled-checkbox:checked + label:after {
  content: "";
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white,
    4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.RCPNOI-unstyled {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.RCPNOI-unstyled li {
  margin: 10px 0;
  /* border: 1px solid white; */
}

.RCPNOI-centered {
  width: 300px;
  margin: auto;
}

@media screen and (max-width: 800px) {
  .RCPNOI-form-field {
    font-size: 18px;
  }

  #RCPNOI-heading {
    margin-top: 50px;
    padding-left: 10px;
  }

  .RCPNOI-unstyled li {
    padding-left: 10px;
  }
}

.RCPNOI-square-box {
  width: 20px;
  height: 20px;
  border: 1px solid white;
  display: inline-block;
}

.RCPNOI-text-field {
  display: inline-block;
  line-height: 20px;
  vertical-align: top;
  margin-left: 10px;
  color: white;
  border-bottom: 1px solid white;
  font-size: 22px;
}

#RKOCI-Image {
  height: 200px;
  width: 200px;
  margin-top: 20px;
  margin-left: 20px;
}

@media screen and (max-width: 800px) {
  #RKOCI-Image {
    width: 200px;
    height: 200px;
    margin-top: 110px;
  }

  #RKOCI-Image {
    font-size: 16px;
  }
}
#RCPNOI-container-1 {
  width: 99%;
}
#RCPNOI-form-box-1 {
}
#RCPNOI-form {
  width: -webkit-fill-available;
}

#JointNoi3-inner-box-1 {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-left: 20px;
}
